import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { placeOrder } from "../../../Services/Https/orders";
import { verifyReCAPTCHA } from "../../../Services/Https/recaptcha";
import { IPlaceOrder, IPlaceOrderProduct } from "../../../Model/Order";
import { IPaymentRequestData } from "../../../Model/NestpayApi";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import ReCAPTCHA from "react-google-recaptcha";
import "../../../Styles/OrderInformation.css";

export interface ISingleCartProductProps {
  productID: number;
  productName: string;
  productPrice: number;
  productImage: string;
  productAmount: number;
  productColor: string;
  productAvailableNow: boolean;
}

const SingleCartProduct = ({
  productID,
  productName,
  productPrice,
  productImage,
  productAmount,
  productColor,
  productAvailableNow,
}: ISingleCartProductProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const totalProductPrice = productPrice * productAmount;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth > 785 ? ( // HORIZONTAL
    <TableRow>
      <TableCell>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "15px",
            marginBottom: "15px",
            marginRight: "15px",
          }}
        >
          <Grid
            item
            onClick={() => {
              window.open(
                `https://jewelryshopdoro.com/products/${productID}`,
                "_blank"
              );
            }}
          >
            <img
              style={{
                width: "130px",
                height: "150px",
                marginRight: "8px",
                objectFit: "cover",
              }}
              src={`https://jewelryshopdoro.com/uploads/${productImage}`}
              alt="Korpa Slika"
            ></img>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
              onClick={() => {
                window.open(
                  `https://jewelryshopdoro.com/products/${productID}`,
                  "_blank"
                );
              }}
            >
              {productName}
            </Typography>
            {productAvailableNow ? (
              <>
                <Typography
                  sx={{ fontSize: "16px", color: "#428210", marginTop: "40px" }}
                >
                  Dostupno odmah
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#428210" }}>
                  *Šaljemo u roku od 24 sata
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{ fontSize: "16px", color: "#F88D2B", marginTop: "40px" }}
                >
                  Nije dostupno odmah
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#F88D2B" }}>
                  *za izradu potrebno 7-10 dana
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#79590E", marginRight: "15px" }}
        >{`${productPrice} rsd`}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#79590E", marginRight: "15px" }}
        >{`${productAmount}`}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#CF9B2E", marginRight: "15px" }}
        >
          {`${totalProductPrice.toFixed(2)} rsd`}
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    // VERTICAL
    <TableRow>
      <TableCell>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: screenWidth > 500 ? "row" : "column",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              onClick={() => {
                window.open(
                  `https://jewelryshopdoro.com/products/${productID}`,
                  "_blank"
                );
              }}
            >
              <img
                style={{
                  width: "130px",
                  height: "150px",
                  marginRight: screenWidth > 500 ? "20px" : "0px",
                  objectFit: "cover",
                }}
                src={`https://jewelryshopdoro.com/uploads/${productImage}`}
                alt="Korpa Slika"
              ></img>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: screenWidth > 500 ? "left" : "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: screenWidth > 500 ? "18px" : "24px",
                  color: "#79590E",
                }}
                onClick={() => {
                  window.open(
                    `https://jewelryshopdoro.com/products/${productID}`,
                    "_blank"
                  );
                }}
              >
                {productName}
              </Typography>
              {productAvailableNow ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#428210",
                      marginTop: screenWidth > 500 ? "40px" : "25px",
                    }}
                  >
                    Dostupno odmah
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#428210" }}>
                    *Šaljemo u roku od 24 sata
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#F88D2B",
                      marginTop: screenWidth > 500 ? "40px" : "25px",
                    }}
                  >
                    Nije dostupno odmah
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#F88D2B" }}>
                    *za izradu potrebno 7-10 dana
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Cena
            </Typography>
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
            >{`${productPrice} rsd`}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Količina
            </Typography>
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
            >{`${productAmount}`}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Ukupno
            </Typography>
            <Typography sx={{ fontSize: "18px", color: "#CF9B2E" }}>
              {`${totalProductPrice.toFixed(2)} rsd`}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const OrderInformation = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isBusy, setIsBusy] = useState(false);

  const navigate = useNavigate();
  const cartProductsString = sessionStorage.getItem("products");
  const customerNote = sessionStorage.getItem("customerNote");
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);

  const [transactionDialogOpen, setTransactionDialogOpen] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("products") === null) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    // Handle resize event
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handlePaymentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value);
  };

  const handleDeliveryTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeliveryType(event.target.value);
  };

  const handleTransactionDialogOpen = () => {
    setTransactionDialogOpen(true);
  };

  const handleTransactionDialogClose = () => {
    setTransactionDialogOpen(false);
  };

  const submitFormData = async (paymentRequestData: IPaymentRequestData) => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://bib.eway2pay.com/fim/est3Dgate";

    for (const [key, value] of Object.entries(paymentRequestData)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = value;

      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const handleTermsCheckboxChange = () => {
    setTermsAccepted((prev) => !prev);
  };

  const handleTermsDialogOpen = () => {
    setTermsDialogOpen(true);
  };

  const handleTermsDialogClose = () => {
    setTermsDialogOpen(false);
  };

  const handleTermsDialogAccept = () => {
    setTermsDialogOpen(false);
    setTermsAccepted(true);
  };

  const handleSubmitOrderInformation = async () => {
    // reCAPTCHA validation
    if (!recaptchaRef.current) {
      enqueueSnackbar("Niste prošli reCAPTCHA proveru", {
        variant: "error",
      });
      return;
    }
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    if (!token) {
      enqueueSnackbar("Niste prošli reCAPTCHA proveru", {
        variant: "error",
      });
      return;
    }

    // Do verification with your backend using the token
    const reCAPTCHAVerificationSuccessful = await verifyReCAPTCHA(token);

    if (!reCAPTCHAVerificationSuccessful) {
      enqueueSnackbar("Niste prošli reCAPTCHA proveru", {
        variant: "error",
      });
      return;
    }

    setIsBusy(true);
    // Input validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      !name ||
      !surname ||
      !telephone ||
      !address ||
      !city ||
      !municipality ||
      !postalCode
    ) {
      setIsBusy(false);
      enqueueSnackbar("Morate popuniti sva označena polja", {
        variant: "error",
      });
      return;
    }
    if (email && !emailRegex.test(email)) {
      setIsBusy(false);
      enqueueSnackbar("Morate napisati validnu email adresu", {
        variant: "error",
      });
      return;
    }
    // Email is required if paying with the credit card
    if (!email && paymentType === "with_card") {
      setIsBusy(false);
      enqueueSnackbar("Email je obavezno polje ukoliko plaćate karticom", {
        variant: "error",
      });
      return;
    }
    if (!cartProductsString) {
      setIsBusy(false);
      enqueueSnackbar("Ne možete napraviti porudžbinu sa 0 proizvoda", {
        variant: "error",
      });
      return;
    }

    if (isNaN(+postalCode)) {
      setIsBusy(false);
      enqueueSnackbar("Postanski kod mora biti broj", { variant: "error" });
      return;
    }

    if (!termsAccepted) {
      setIsBusy(false);
      enqueueSnackbar("Molimo Vas prihvatite uslove korišćenja", {
        variant: "error",
      });
      return;
    }

    if (paymentType === "") {
      setIsBusy(false);
      enqueueSnackbar("Molimo Vas odaberite način plaćanja", {
        variant: "error",
      });
      return;
    }

    if (deliveryType === "") {
      setIsBusy(false);
      enqueueSnackbar("Molimo Vas odaberite način isporuke", {
        variant: "error",
      });
      return;
    }

    // Mapping field names to match the RESTful API
    const cartProducts = JSON.parse(cartProductsString).map(
      (tmpProduct: any) => {
        const newProduct: IPlaceOrderProduct = {
          product_id: parseInt(tmpProduct.id),
          amount: tmpProduct.amount,
          color: tmpProduct.color,
        };
        return newProduct;
      }
    );

    // Send order to the server
    const orderInformation: IPlaceOrder = {
      customer_name: name,
      customer_surname: surname,
      email: email ? email : null,
      telephone_number: telephone,
      adress: `${address}, ${city}`,
      municipality: municipality,
      postal_code: postalCode,
      payment_type: paymentType === "with_card" ? "card" : "cash",
      delivery: deliveryType === "store" ? "store" : "shipping",
      note: customerNote ? customerNote : null,
      products: cartProducts,
    };

    try {
      const result = await placeOrder(orderInformation);

      sessionStorage.setItem(
        "orderInformation",
        JSON.stringify(orderInformation)
      );

      if (paymentType === "with_card") {
        // sessionStorage.removeItem("orderInformation");
        // sessionStorage.removeItem("customerNote");
        await submitFormData(result.paymentRequestData);
      } else {
        enqueueSnackbar("Porudžbina uspešno kreirana", {
          variant: "default",
        });

        navigate("/order-final", {
          state: {
            openPermition: true,
          },
        });
      }
    } catch (err) {
      enqueueSnackbar("Greška prilikom kreiranja porudžbine", {
        variant: "error",
      });
      setIsBusy(false);
      return;
    }
  };

  if (cartProductsString) {
    const cartProducts = JSON.parse(cartProductsString);
    let totalOrderPrice = 0.0;
    for (let singleCartProduct of cartProducts) {
      totalOrderPrice += singleCartProduct.price * singleCartProduct.amount;
    }

    return (
      <Grid
        container
        sx={{ display: "flex", flexDirection: "column", padding: "27px" }}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LdgZhInAAAAACFPo3lBFERmu0XEkmqVSeEpMvCw"
        />
        {isBusy ? (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0%",
              left: "0%",
              backgroundColor: "rgb(255, 244, 227)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              zIndex: "11111",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "36px",
                color: "#79590E",
              }}
            >
              Obrada zahteva
            </Typography>
            <CircularProgress sx={{ color: "#79590E" }} />
          </div>
        ) : null}
        <ScrollToTop />
        <Grid item sx={{ marginTop: "20px" }}>
          <Typography sx={{ fontSize: "14px", color: "#79590E" }}>
            Početna → Poručivanje
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "20px", textAlign: "center" }}>
          <Typography sx={{ fontSize: "36px", color: "#79590E" }}>
            Poručivanje
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "65px" }}>
          <Grid item>
            <TableContainer>
              <Table>
                {screenWidth > 785 ? (
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Proizvod
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Cena
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Količina
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Ukupno
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : null}
                <TableBody>
                  {cartProducts.map((singleCartProduct: any, index: number) => {
                    return (
                      <SingleCartProduct
                        key={index}
                        productID={singleCartProduct.id}
                        productName={singleCartProduct.product_name}
                        productPrice={singleCartProduct.price}
                        productImage={singleCartProduct.main_image}
                        productAmount={singleCartProduct.amount}
                        productColor={singleCartProduct.color}
                        productAvailableNow={singleCartProduct.availableNow}
                      />
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={screenWidth > 785 ? 4 : 1}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "#79590E",
                          textAlign: "center",
                        }}
                      >
                        {`Ukupna cena porudžbine: ${totalOrderPrice.toFixed(
                          2
                        )} rsd`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: "#79590E",
                          textAlign: "center",
                        }}
                      >
                        (PDV uračunat u cenu i nema skrivenih troškova)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: "65px" }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: screenWidth > 1100 ? "row" : "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "100px",
            }}
          >
            {/* // Left section */}
            <Grid
              item
              sx={{ marginRight: screenWidth > 1100 ? "200px" : "0px" }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "55px",
                  width: screenWidth > 550 ? "500px" : "280px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#79590E",
                    marginBottom: "15px",
                  }}
                >
                  Podaci o kupcu
                </Typography>

                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#79590E",
                    marginBottom: "40px",
                    textAlign: "center",
                  }}
                >
                  Unesite Vaše podatke ispravno kako bi isporuka bila uspešna
                </Typography>

                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: screenWidth > 550 ? "row" : "column",
                    marginBottom: "20px",
                    justifyContent: "space-between",
                    // width: "500px",
                  }}
                >
                  <TextField
                    onChange={(e) => setName(e.target.value)}
                    sx={{
                      fontSize: "24px",
                      color: "#79590E",
                      width: screenWidth > 550 ? "48%" : "100%",
                      marginBottom: screenWidth > 550 ? "0px" : "20px",
                    }}
                    label="Ime"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <TextField
                    onChange={(e) => setSurname(e.target.value)}
                    sx={{
                      fontSize: "24px",
                      color: "#79590E",
                      width: screenWidth > 550 ? "48%" : "100%",
                    }}
                    label="Prezime"
                    required
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    fontSize: "24px",
                    color: "#79590E",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  label="Email adresa"
                  inputProps={{
                    maxLength: 50,
                  }}
                />

                <TextField
                  onChange={(e) => setTelephone(e.target.value)}
                  sx={{
                    fontSize: "24px",
                    color: "#79590E",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  label="Broj telefona"
                  required
                  inputProps={{
                    maxLength: 15,
                  }}
                />

                <TextField
                  onChange={(e) => setAddress(e.target.value)}
                  sx={{
                    fontSize: "24px",
                    color: "#79590E",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  label="Ulica i broj"
                  required
                  inputProps={{
                    maxLength: 50,
                  }}
                />

                <TextField
                  onChange={(e) => setCity(e.target.value)}
                  sx={{
                    fontSize: "24px",
                    color: "#79590E",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  label="Grad"
                  required
                  inputProps={{
                    maxLength: 40,
                  }}
                />

                <TextField
                  onChange={(e) => setMunicipality(e.target.value)}
                  sx={{
                    fontSize: "24px",
                    color: "#79590E",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  label="Opština"
                  required
                  inputProps={{
                    maxLength: 50,
                  }}
                />

                <TextField
                  onChange={(e) => setPostalCode(e.target.value)}
                  sx={{
                    fontSize: "24px",
                    color: "#79590E",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                  label="Poštanski broj"
                  required
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </Grid>
            </Grid>
            {/* // Right section */}
            <Grid item>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: screenWidth > 550 ? undefined : "center",
                  marginTop: "55px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#79590E",
                    marginBottom: "30px",
                  }}
                >
                  Način plaćanja
                </Typography>
                <RadioGroup
                  sx={{ marginBottom: "60px" }}
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                >
                  <FormControlLabel
                    value="on_delivery"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                        Pouzećem
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="with_card"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                        Platnom karticom
                      </Typography>
                    }
                  />
                  {paymentType === "with_card" ? (
                    <Typography
                      sx={{
                        marginTop: "10px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      color="secondary"
                      onClick={handleTransactionDialogOpen}
                    >
                      Zaštita poverljivih podataka o transakciji
                    </Typography>
                  ) : null}
                </RadioGroup>
                <Typography
                  sx={{
                    fontSize: "30px",
                    color: "#79590E",
                    marginBottom: "30px",
                  }}
                >
                  Način dostave
                </Typography>
                <RadioGroup
                  value={deliveryType}
                  onChange={handleDeliveryTypeChange}
                >
                  <FormControlLabel
                    value="shipping"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                        Dostava na kućnu adresu
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="store"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                        Preuzimanje u radnji u Novom Sadu
                      </Typography>
                    }
                  />
                </RadioGroup>
                <FormControlLabel
                  sx={{ paddingTop: "20px" }}
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={handleTermsCheckboxChange}
                    />
                  }
                  label={
                    <Button
                      color="secondary"
                      variant="text"
                      disableRipple
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        padding: 0,
                        margin: 0,
                        "&:hover": { boxShadow: "none" },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleTermsDialogOpen();
                      }}
                    >
                      Pročitao/la sam i prihvatam uslove korišćenja
                    </Button>
                  }
                />
                <Button
                  variant="text"
                  sx={{
                    marginTop: "40px",
                    backgroundColor: "#FFF4E3",
                    padding: "25px",
                    width: screenWidth > 550 ? "320px" : "280px",
                    textTransform: "none",
                  }}
                  onClick={() => handleSubmitOrderInformation()}
                >
                  <Typography sx={{ fontSize: "60", color: "#79590E" }}>
                    Potvrda i plaćanje
                  </Typography>
                </Button>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  minHeight="80px"
                  paddingTop="20px"
                  spacing={2}
                >
                  <Grid item>
                    <Typography sx={{ fontSize: "15px", color: "#79590E" }}>
                      Slanje Post Express-om, Rok isporuke: 1-3 Radna dana,
                      Poštarina: 350din
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src="/post-express.png"
                      alt="Postexpress"
                      style={{ width: screenWidth > 550 ? "140px" : "100px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={termsDialogOpen} onClose={handleTermsDialogClose}>
          <DialogTitle>Uslovi korišćenja</DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              1. Prihvatanje Uslova korišćenja
            </Typography>
            <Typography>
              Korišćenjem naše web prodavnice, prihvatate ove Uslove korišćenja.
              Ako se ne slažete sa ovim Uslovima korišćenja, molimo vas da ne
              koristite našu web prodavnicu.
            </Typography>
            <Typography variant="h6">2. Osnovni podaci o firmi</Typography>
            <Typography>
              Pun naziv pravnog subjekta: DAROTEKA DORO <br />
              Adresa: Laze Telečkog 1, Novi Sad <br />
              Delatnost i šifra delatnosti: 3212 - Proizvodnja nakita i srodnih
              predmeta <br /> Matični broj: 52404860 <br /> Poreski broj:
              101705556 <br /> Kontakt telefon: 0605500750 <br /> Kontakt
              e-mail: silvershopdoro@gmail.com
            </Typography>
            <Typography variant="h6">3. Izjava o konverziji</Typography>
            <Typography>
              Sva plaćanja će biti izvršena isključivo u lokalnoj valuti
              Republike Srbije - dinar (RSD). Cene proizvoda prikazane na našem
              sajtu takođe su izražene u dinarima. Molimo vas da imate u vidu da
              se iznos koji će biti zadužen na vašoj platnoj kartici dobija
              konverzijom cene iz dinara u vašu lokalnu valutu po kursu koji
              koriste kartičarske organizacije. Kao rezultat ove konverzije,
              postoji mogućnost manje razlike u odnosu na originalnu cenu
              navedenu na našem sajtu.
            </Typography>
            <Typography variant="h6">
              4. Kontakt podaci - korisnički servis
            </Typography>
            <Typography>
              Kontakt telefon: 0605500750 <br />
              Kontakt e-mail: silvershopdoro@gmail.com
            </Typography>
            <Typography variant="h6">
              5. Dostava robe i eventualna ograničenja
            </Typography>
            <Typography>
              Dostava robe vrši se putem kurirske službe Post Express. Trošak
              dostave iznosi 350 dinara. <br /> Molimo vas da imate u vidu
              sledeće informacije o dostavi: <br /> - Rok dostave: Dostava se
              obavlja u roku od 1-3 radna dana od trenutka potvrde i obrade vaše
              porudžbine. <br /> - Ograničenja težine: Molimo vas da imate u
              vidu da je ograničenje težine paketa ispod 500 grama. Za pakete
              teže od navedene granice, molimo vas da nas kontaktirate kako
              bismo vam pružili dodatne informacije o alternativnim opcijama
              dostave.
            </Typography>
            <Typography variant="h6">7. Politika reklamacija</Typography>
            <Typography>
              1. Podnošenje reklamacije
              <br />
              - Reklamacije se mogu podneti isključivo za proizvode ili usluge
              kupljene putem naše web prodavnice.
              <br />
              - Molimo vas da podnesete reklamaciju u roku od 14 dana od dana
              isporuke proizvoda ili pružanja usluge.
              <br />
              <br />
              2. Uslovi prihvatanja reklamacija
              <br />
              - Reklamacije će se uzeti u razmatranje samo ako je proizvod ili
              usluga neispravna, oštećena ili ne odgovara opisu koji je pružen
              na našem sajtu.
              <br />
              - Za prihvatanje reklamacije potrebno je priložiti dokaz o
              kupovini (račun, potvrdu o transakciji, itd.).
              <br />
              <br />
              3. Postupak reklamacije
              <br />
              - Da biste podneli reklamaciju, molimo vas da nas kontaktirate
              putem naših dostupnih kanala za korisničku podršku (telefon,
              e-mail).
              <br />
              - Naš tim će vam pružiti detaljne instrukcije o daljem postupku,
              uključujući način vraćanja proizvoda ili dogovor o zameni.
              <br />
              - U nekim slučajevima može biti potrebno vratiti proizvod pre nego
              što bude odobrena zamena ili povraćaj novca.
              <br />
              <br />
              4. Rešavanje reklamacija
              <br />
              - Nakon što primimo vašu reklamaciju, pažljivo ćemo je pregledati
              i doneti odluku o prihvatanju ili odbijanju reklamacije u skladu
              sa relevantnim zakonima i propisima.
              <br />
              - Ako je reklamacija prihvaćena, ponudićemo vam mogućnosti zamene
              proizvoda, povraćaja novca ili drugih adekvatnih rešenja.
              <br />
              - Ukoliko reklamacija bude odbijena, obavestićemo vas o razlozima
              za odbijanje i pružiti vam objašnjenje.
              <br />
              <br />
              5. Vaša odgovornost
              <br />
              - Molimo vas da proizvode ili usluge koristite u skladu sa
              uputstvima pružaoca usluge kako biste izbegli moguće probleme.
              <br />
              - Odgovorni ste za pravilno rukovanje proizvodima, pravilno
              održavanje i sprečavanje oštećenja.
              <br />
              <br />
            </Typography>
            <Typography variant="h6">
              6. Zaštita privatnosti korisnika
            </Typography>
            <Typography>
              U ime Doro Jewelry Shop obavezujemo se da ćemo čuvati privatnost
              svih naših kupaca. Prikupljamo samo neophodne osnovne podatke o
              kupcima/korisnicima i podatke neophodne za poslovanje i
              informisanje korisnika u skladu sa dobrim poslovnim običajima i u
              cilju pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora,
              uključujući mogućnost odluke da li žele ili ne žele da budu
              uključeni na mailing listu koja se koristi za marketinške
              kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju i
              dostupni su samo zaposlenima kojima su ti podaci neophodni za
              obavljanje posla. Svi zaposleni Doro Jewelry Shop (i poslovni
              partneri) odgovorni su za poštovanje načela zaštite privatnosti.'
            </Typography>
            <Typography variant="h6">
              7. Zaštita poverljivih podataka o transakciji
            </Typography>
            <Typography>
              Prilikom unošenja podataka o platnoj kartici, poverljive
              informacije se prenose putem javne mreže u zaštićenoj
              (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao
              trenutno najsavremenije kriptografske tehnologije. Sigurnost
              podataka prilikom kupovine garantuje procesor platnih kartica,
              Banca Intesa ad Beograd, pa se tako kompletni proces naplate
              obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj
              kartici nisu dostupni našem sistemu.
            </Typography>
            <Typography variant="h6">8. Povraćaj sredstava</Typography>
            <Typography>
              U slučaju vraćanja robe i povraćaja sredstava kupcu koji je
              prethodno platio nekom od platnih kartica, delimično ili u
              celosti, a bez obzira na razlog vraćanja, Doro Jewelry Shop je u
              obavezi da povraćaj vrši isključivo putem VISA, EC/MC, Maestro,
              Amex i Dina metoda plaćanja, što znači da će banka na zahtev
              prodavca obaviti povraćaj sredstava na račun korisnika kartice.
            </Typography>
            <Typography variant="h6">9. Izjava o PDV-u</Typography>
            <Typography>
              PDV uračunat u cenu i nema skrivenih troškova.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTermsDialogClose} color="secondary">
              Zatvori
            </Button>
            <Button onClick={handleTermsDialogAccept} color="secondary">
              Prihvati
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={transactionDialogOpen}
          onClose={handleTransactionDialogClose}
        >
          <DialogTitle>Zaštita poverljivih podataka o transakciji</DialogTitle>
          <DialogContent>
            <Typography>
              Prilikom unošenja podataka o platnoj kartici, poverljive
              informacija se prenose putem javne mreže u zaštićenoj
              (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao
              trenutno najsavremenije kriptografske tehnologije. Sigurnost
              podataka prilikom kupovine, garantuje procesor platnih kartica,
              Banca Intesa ad Beograd, pa se tako kompletni proces naplate
              obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj
              kartici nisu dostupni našem sistemu.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleTransactionDialogClose} color="secondary">
              Zatvori
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  } else {
    return <h1>Korpa je prazna</h1>;
  }
};

export default OrderInformation;
