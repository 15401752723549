import { AxiosResponse } from "axios";
import {
  material,
  IProducts,
  IProductSingle,
  IProductSingleUpdate,
} from "../../Model/Product";
import api from "../../api";
export const getAllProducts = async (
  available_pink: boolean | null,
  available_white: boolean | null,
  available_yellow: boolean | null,
  order: string | null,
  orderBy: string | null,
  page: number,
  perPage: number,
  discount_flag: boolean | null,
  briliant: boolean | null,
  search: string | null,
  material: material | null,
  favorite_flag: boolean | null,
  category_key?: string,
  subcategory_key?: string
): Promise<IProducts> => {
  const response = await api.get(`/product`, {
    params: {
      order,
      orderBy,
      page,
      perPage,
      discount_flag,
      briliant,
      category_key,
      subcategory_key,
      search,
      material,
      available_pink,
      available_yellow,
      available_white,
      favorite_flag,
    },
  });
  return response.data;
};

export const getSingleProduct = async (id: string): Promise<IProductSingle> => {
  const response = await api.get(`/product/${id}`);
  return response.data;
};

export const deleteProduct = async (id: string): Promise<{ msg: string }> => {
  const response = await api.delete(`admin/product/${id}`);
  return response.data;
};

export const createProduct = async (formData: FormData): Promise<number> => {
  const response = await api.post(`/admin/product`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  const { id } = response.data;
  return id;
};

export const updateProduct = async (
  id: string,
  productData: IProductSingleUpdate
): Promise<AxiosResponse> => {
  const response = await api.patch(`/admin/product/${id}`, productData);

  return response;
};

// export const getAllProductImages = async (): Promise<IAboutImages> => {
//   const response = await api.get(`about-image`);
//   return response.data;
// };

export const deleteSingleProductImage = async (
  id: string // image id
): Promise<{ msg: string }> => {
  const response = await api.delete(`/admin/product-image/${id}`);
  return response.data;
};

export const updateMainPreviewImage = async (
  id: string, // product id
  formData: FormData
): Promise<{ msg: string }> => {
  const response = await api.patch(`/admin/product-image/${id}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

export const addNewProductImages = async (
  id: string, // product id
  formData: FormData
): Promise<{ image_name: string }> => {
  const response = await api.post(`/admin/product-image/${id}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};
