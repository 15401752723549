import { Grid, Typography, Chip } from "@mui/material";
import { material } from "../../../Model/Product";
import { useState } from "react";
import "../../../Styles/ClientSingleProduct.css";
import DiscountIcon from "@mui/icons-material/Discount";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface ProductProps {
  handleClick: () => void;
  imageUrl: string;
  previewUrl: string;
  name: string;
  material: material;
  price: number;
  discount: boolean;
  discountPrice: number;
  pageName?: string;
}

export const ClientSingleProduct = ({
  handleClick,
  imageUrl,
  name,
  price,
  previewUrl,
  material,
  discount,
  discountPrice,
  pageName = "",
}: ProductProps) => {
  const [_error, setError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleError = () => {
    setError(true);
  };

  const translateMaterial = (materialString: material) => {
    switch (materialString) {
      case "gold":
        return "zlato";
      case "silver":
        return "srebro";
      default:
        return "";
    }
  };

  return (
    <Grid
      container
      direction="column"
      className={"product-list-container" + pageName}
    >
      <Grid item>
        <div
          style={{ position: "relative", cursor: "pointer" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={"image-list-product" + pageName}
        >
          <LazyLoadImage
            effect="blur"
            onClick={handleClick}
            alt="Izlozen proizvod"
            src={
              _error
                ? "/no-image.jpg"
                : `https://jewelryshopdoro.com/uploads/${imageUrl}`
            }
            onError={handleError}
            style={{
              opacity: isHovered ? 0 : 1,
              transition: "opacity 0.3s ease-in-out",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            className={"image-list-product" + pageName}
          />
          <LazyLoadImage
            effect="blur"
            onClick={handleClick}
            alt="Preview proizvod"
            src={
              _error
                ? "/no-image.jpg"
                : `https://jewelryshopdoro.com/uploads/${previewUrl}`
            }
            onError={handleError}
            style={{
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            className={"image-list-product" + pageName}
          />
          {discount ? (
            <Chip
              sx={{
                padding: "5px",
                position: "absolute",
                top: "10px",
                right: "10px",
                // backgroundColor: "#c92228",
                backgroundColor: "#cd9c32",
                "& .MuiChip-label": {
                  color: "#FEFEFE",
                  fontWeight: "bold",
                },
              }}
              label={
                discountPrice
                  ? "-" +
                    Math.round((1 - discountPrice / price) * 100).toString() +
                    "%"
                  : "- 100%"
              }
              icon={
                <DiscountIcon
                  style={{
                    fontSize: "20px",
                    color: "#FEFEFE",
                  }}
                />
              }
            />
          ) : null}
        </div>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          className={"product-list-info-container" + pageName}
        >
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography className={"product-list-info" + pageName}>
                  {name} - {translateMaterial(material)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  className={"product-list-info" + pageName}
                  sx={
                    discount
                      ? {
                          textDecoration: "line-through",
                        }
                      : { color: "#79590e" }
                  }
                >
                  {price} rsd
                </Typography>
              </Grid>
              {discount ? (
                <Grid item alignSelf="flex-end">
                  <Typography
                    className={"product-list-info" + pageName}
                    sx={{ color: "#CF9B2E !important" }}
                  >
                    {discountPrice} rsd
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientSingleProduct;
