import { getAllProducts, getSingleProduct } from "../Services/Https/products";
import { useQuery } from "react-query";
import { material } from "../Model/Product";
export const useAllProducts = (
  available_pink: boolean | null,
  available_white: boolean | null,
  available_yellow: boolean | null,
  order: string | null,
  orderBy: string | null,
  page: number,
  perPage: number,
  discount_flag: boolean | null,
  briliant: boolean | null,
  search: string | null,
  material: material | null,
  favorite_flag: boolean | null,
  category_key?: string,
  subcategory_key?: string
) => {
  return useQuery(
    [
      "products",
      order,
      orderBy,
      page,
      perPage,
      discount_flag,
      briliant,
      category_key,
      subcategory_key,
      search,
      material,
      available_pink,
      available_white,
      available_yellow,
      favorite_flag,
    ],
    () =>
      getAllProducts(
        available_pink,
        available_white,
        available_yellow,
        order,
        orderBy,
        page,
        perPage,
        discount_flag,
        briliant,
        search,
        material,
        favorite_flag,
        category_key,
        subcategory_key
      ),
    { keepPreviousData: true }
  );
};

export const useSingleProduct = (id: string) => {
  return useQuery(["product"], () => getSingleProduct(id));
};
