import api from "../../api";
import { ICategories, ICategory, ICreateCategory } from "../../Model/Category";

export const getCategories = async (): Promise<ICategories> => {
  const response = await api.get(`/category`, {});
  return response.data;
};

export const getCategory = async (id: string): Promise<ICategory> => {
  const response = await api.get(`/admin/category/${id}`);
  return response.data;
};

export const deleteCategory = async (id: string) => {
  const response = await api.delete(`/admin/category/${id}`);
  return response.data;
};

export const createCategory = async (
  category: ICreateCategory
): Promise<{ id: number }> => {
  const response = await api.post(`/admin/category`, category);
  return response.data;
};

export const editCategory = async ({
  category_name,
  category_id,
}: {
  category_name: string;
  category_id?: string;
}): Promise<{ msg: string }> => {
  if (category_id) {
    const response = await api.patch(`/admin/category/${category_id}`, {
      category_name,
    });
    return response.data;
  }
  return { msg: "Neuspesna izmena kategorije" };
};
