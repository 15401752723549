import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkAuth } from "../../../Services/Https/auth";
import { CircularProgress } from "@mui/material";

const withAuth = (Component: React.ComponentType<any>, path:string) => {
  const WrappedComponent: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);
    const [loading, setLoading] = useState(true); // new loading state
    const [hasCORS, setHasCORS] = useState(false); // new state for CORS error

    useEffect(() => {
      const fetchAuth = async () => {
        try {
          const response = await checkAuth();
          setAuth(response.status === 200);
        } catch (error: unknown) {
          if ((error as Error).message === "Network Error") {
            setHasCORS(true); // set hasCORS state if there's a CORS error
          } else {
            setAuth(false);
          }
        } finally {
          setLoading(false); // always set loading to false when done
        }
      };
      fetchAuth();
    }, []);

    useEffect(() => {
      if (loading) {
        return () => {}; // no cleanup needed
      }

      if (hasCORS) {
        return () => {}; // no cleanup needed
      }

      if (!auth) {
        navigate(path)
        return () => {}; // cleanup function to cancel navigation
      }

      // no cleanup needed
      return () => {};
    }, [loading, hasCORS, auth, navigate]);

    return auth? <Component {...props} /> : <CircularProgress />; // render wrapped component if authenticated
  };

  WrappedComponent.displayName = `withAuth(${
    Component.displayName || Component.name || "Component"
  })`;

  return <WrappedComponent />;
};
export default withAuth;
