import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ISingleCartProductProps {
  productID: number;
  productName: string;
  productPrice: number;
  productImage: string;
  productAmount: number;
}

const SingleCartProduct = ({
  productID,
  productName,
  productPrice,
  productImage,
  productAmount,
}: ISingleCartProductProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: screenWidth > 350 ? "row" : "column",
          margin: "15px",
          alignItems: screenWidth > 350 ? null : "center",
        }}
        onClick={() => {
          navigate(`/products/${productID}`);
        }}
      >
        <Grid item>
          <img
            style={{
              width: screenWidth > 430 ? "100px" : "60px",
              height: screenWidth > 430 ? "120px" : "80px",
              marginRight: "8px",
              objectFit: "cover",
            }}
            src={`https://jewelryshopdoro.com/uploads/${productImage}`}
            alt="Korpa Slika"
          ></img>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: screenWidth > 350 ? null : "center",
            textAlign: screenWidth > 350 ? null : "center",
          }}
        >
          <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
            {productName}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#79590E",
              marginTop: screenWidth > 430 ? "40px" : "20px",
            }}
          >{`${productPrice} rsd  x  ${productAmount}  =  ${(
            productPrice * productAmount
          ).toFixed(2)} rsd`}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

interface CartPopupProps {
  handleOpenCart: () => void; // Function prop type
}

const CartPopup: React.FC<CartPopupProps> = ({ handleOpenCart }) => {
  const navigate = useNavigate();
  const cartProductsString = sessionStorage.getItem("products");
  let cartProducts;
  const [cartWidth, setCartWidth] = useState(
    window.innerWidth < 430 ? "100%" : "430px"
  );

  const handleResize = () => {
    let newWidth = window.innerWidth < 430 ? "100%" : "430px";
    setCartWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (cartProductsString != null) cartProducts = JSON.parse(cartProductsString);
  else cartProducts = [];

  if (cartProducts.length > 0) {
    let totalOrderPrice = 0.0;
    for (let singleCartProduct of cartProducts) {
      totalOrderPrice += singleCartProduct.price * singleCartProduct.amount;
    }

    return (
      <Grid
        container
        sx={{
          width: `${cartWidth}`,
          backgroundColor: "white",
          position: "absolute",
          top: "100%",
          right: "0px",
          boxShadow: "-2px 2px 4px 2px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Grid
          container
          sx={{ overflowY: "auto", overflowX: "hidden", height: "300px" }}
        >
          {cartProducts.map((singleCartProduct: any, index: number) => {
            return (
              <SingleCartProduct
                key={index}
                productName={singleCartProduct.product_name}
                productPrice={singleCartProduct.price}
                productImage={singleCartProduct.main_image}
                productAmount={singleCartProduct.amount}
                productID={singleCartProduct.id}
              />
            );
          })}
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "15px",
          }}
        >
          {/* This is orange <hr /> */}
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                height: "1px",
                width: "100%",
                backgroundColor: "#CF9B2E",
              }}
            ></div>
          </Grid>
          <Grid item>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <span style={{ color: "#79590E", fontSize: "20px" }}>
                  Ukupno:
                </span>
              </Grid>
              <Grid item>
                <span style={{ color: "#CF9B2E", fontSize: "20px" }}>
                  {`${totalOrderPrice.toFixed(2)} rsd`}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "40px",
                marginBottom: "20px",
              }}
            >
              <Grid
                item
                sx={{
                  backgroundColor: "#CF9B2E",
                  width: "50%",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  variant="text"
                  onClick={() => {
                    navigate("/cart");
                    handleOpenCart();
                  }}
                >
                  <Typography sx={{ color: "#FFF4E3", fontSize: "20px" }}>
                    Vaša korpa
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  backgroundColor: "#FFF4E3",
                  width: "50%",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  variant="text"
                  onClick={() => {
                    navigate("/order-info");
                    handleOpenCart();
                  }}
                >
                  <Typography sx={{ color: "#CD9C32", fontSize: "20px" }}>
                    Potvrdi
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div
        style={{
          width: `${cartWidth}`,
          height: "490px",
          backgroundColor: "white",
          position: "absolute",
          top: "100%",
          right: "0px",
          boxShadow: "-2px 2px 4px 2px rgba(0, 0, 0, 0.3)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Typography sx={{ fontSize: "24px", color: "#79590E" }}>
          Vaša korpa je trenutno prazna.
        </Typography>
        <Container
          sx={{
            backgroundColor: "#FFF4E3",
            width: "290px",
            height: "95px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "140px",
          }}
        >
          <Button
            sx={{ textTransform: "none" }}
            variant="text"
            onClick={() => {
              // TODO
              handleOpenCart();
            }}
          >
            <Typography sx={{ fontSize: "24px", color: "#79590E" }}>
              Nastavite kupovinu
            </Typography>
          </Button>
        </Container>
      </div>
    );
  }
};

export default CartPopup;
