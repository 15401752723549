import { Link } from "react-router-dom";
import { Container, Typography } from "@mui/material";
function PageNotFound() {
  return (
    <>
      <Container sx={{ textAlign: "center", marginBottom: "50px" }}>
        <Typography sx={{ fontSize: "70px" }}>
          404 - Stranica nije pronađena
        </Typography>
        <br />
        <img src="/404.gif" alt="" style={{ width: "100%" }} />
        <br />
        <Link to="/">
          <Typography sx={{ fontSize: "40px" }}>Vrati se na početnu</Typography>
        </Link>
      </Container>
    </>
  );
}

export default PageNotFound;
