import {
  Grid,
  Paper,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LockOutlined } from "@mui/icons-material";

import { login } from "../../../Services/Https/auth";
import color from "../../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../../../Services/Https/auth";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
const Login = () => {
  const paperStyle = {
    padding: 20,
    height: "50vh",
    width: "min(80vw, 400px)",
    margin: "15vh auto",
  };
  const avatarStyle = { backgroundColor: "#CF9B2E", marginInline: "auto" };
  const btnstyle = { margin: "8px 0" };
  const textFieldStyle = { margin: "8px 0" };
  const [showPassword, setShowPassword] = useState(false);
  const [auth, setAuth] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const response = await checkAuth();
        setAuth(response.status === 200);
      } catch (error) {
        setAuth(false);
      }
    };
    fetchAuth();
  }, []);

  useEffect(() => {
    if (auth) {
      navigate("/admin");
    }
  }, [auth, navigate]);

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setIsLoggingIn(true);
    try {
      const response = await login(username, password);
      if (response) {
        navigate("/admin");
      }
    } catch (error) {
      enqueueSnackbar("Neuspešna prijava", { variant: "error" });
    }
    setIsLoggingIn(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid>
      <Paper elevation={0} style={paperStyle}>
        <Grid>
          <Avatar style={avatarStyle}>
            <LockOutlined />
          </Avatar>
          <h2 style={{ color: color.btn_dark }}>Prijava na sistem</h2>
        </Grid>
        <form onSubmit={handleLogin}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            style={textFieldStyle}
            required
            value={username}
            onChange={handleChangeUsername}
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            style={textFieldStyle}
            required
            value={password}
            onChange={handleChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? (
                      <Visibility style={{ color: color.btn_dark }} />
                    ) : (
                      <VisibilityOff style={{ color: color.btn_dark }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            disabled={isLoggingIn}
            sx={{ backgroundColor: color.primary, color: color.btn_dark }}
          >
            Prijavi se
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default Login;
