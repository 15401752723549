import { useAllAboutImages } from "../../../Hooks/useAboutImages";
import { useState } from "react";
import color from "../../../Constants/colors";
import { Grid, CircularProgress, Typography } from "@mui/material";
import "../../../Styles/About.css";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import { LazyLoadImage } from "react-lazy-load-image-component";

const About = () => {
  const { data } = useAllAboutImages();

  const handleClickContact = () => {
    const element = document.getElementById("footer");
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const offset = 0.1;
      window.scrollTo({
        top: absoluteElementTop + offset,
        behavior: "smooth",
      });
    }
  };

  const [_error, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };

  if (!data) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <ScrollToTop />
        <CircularProgress sx={{ color: color.accent }} />
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="column"
      justifyItems="center"
      alignItems="center"
    >
      <ScrollToTop />
      <Grid item alignSelf="flex-start" className="aboute-route-container">
        <Typography className="about-route-text">
          Početna → Vaše želje
        </Typography>
      </Grid>
      <Grid item>
        <Typography className="about-title">Ispunjavamo vaše želje</Typography>
      </Grid>
      <Grid item width="90%">
        <Typography className="about-slogan">
          Jewelry Shop Doro je mesto gde se snovi pretvaraju u stvarnost. <br />
          Svaki komad nakita ima svoju priču i nosi emotivnu vrednost, čineći ga
          idealnim poklonom za voljene osobe i posebne prilike. <br />
          Naša misija je da vam pružimo ne samo izuzetan nakit već i nezaboravno
          iskustvo koje će vas inspitisati i oduševiti. <br />
          Svaki komad izrađujemo sa strašću i ljubavlju, uz mogućnost
          prilagođavanja prema vašim željama i specifičnim zahtevima. <br />
          Neka vam naš nakit donese radost i eleganciju u svakodnevni život.
        </Typography>
      </Grid>
      <Grid item>
        <Typography className="about-handmade">
          Ručna izrada nakita od zlata i srebra.
        </Typography>
      </Grid>
      <Grid
        item
        width="100%"
        sx={{ backgroundColor: "#FFF4E3" }}
        className="about-colored-container"
      >
        <Grid container direction="column" alignItems="center">
          <Grid
            item
            className="about-custom-wishes-container"
            alignItems="center"
          >
            <Typography
              className="about-custom-wishes-text"
              sx={{ color: "#79590E !important" }}
            >
              Ovde možete videti želje koje smo ostvarili.
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                className="about-custom-wishes-text"
                sx={{ color: "#79590E !important" }}
              >
                Ukoliko i vi imate unikatne ideje
              </Typography>
              <Typography
                sx={{ color: "#CF9B2E !important", cursor: "pointer" }}
                onClick={handleClickContact}
                className="about-custom-wishes-text"
              >
                &nbsp;kontaktirajte nas&nbsp;
              </Typography>
              <Typography
                className="about-custom-wishes-text"
                sx={{ color: "#79590E !important" }}
              >
                !
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="about-bar-divider" />
          <Grid item className="about-images-container">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
            >
              {data.images.map((image) => (
                <Grid item className="about-image-item" key={image.id}>
                  <LazyLoadImage
                    effect="blur"
                    src={
                      _error
                        ? "/no-image.jpg"
                        : `https://jewelryshopdoro.com/uploads/${image.image_name}`
                    }
                    alt="wish"
                    style={{ objectFit: "cover" }}
                    height="100%"
                    width="100%"
                    onError={handleError}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default About;
