import ProductUpdate from "../../../Components/Admin/ProductUpdate";
import { IProductSingle } from "../../../Model/Product";
import { useSingleProduct } from "../../../Hooks/useProducts";
import { useParams } from "react-router-dom";

const ProductUpdatePage = () => {
  const { id } = useParams();
  const { data } = useSingleProduct(id ?? "");
  if (data && id) {
    const product: IProductSingle = { ...data };

    return <ProductUpdate initialProduct={product} id={id} />;
  } else {
    return <h1>{`Ne postoji proizvod koja ima id #${id}`}</h1>;
  }
};

export default ProductUpdatePage;
