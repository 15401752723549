import { useParams } from "react-router-dom";
import { useSingleProduct } from "../../../Hooks/useProducts";
import { deleteProduct } from "../../../Services/Https/products";
import {
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  DialogContentText,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  ArrowForwardIos,
  ArrowBackIosNew,
  Circle,
  Edit,
  Delete,
  TagOutlined,
} from "@mui/icons-material";
import color from "../../../Constants/colors";
import { material } from "../../../Model/Product";
import "../../../Styles/ProductAdmin.css";
import { useState } from "react";
import { formatDate } from "../../../Services/Functions/formatDate";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
interface ImageSliderProps {
  images: string[];
}
const translateMaterial = (materialString: material) => {
  switch (materialString) {
    case "gold":
      return "zlato";
    case "silver":
      return "srebro";
    default:
      return "/";
  }
};
const ProductAdmin = () => {
  const { id } = useParams();
  const { data } = useSingleProduct(id ?? "");
  const [selectedValue, setSelectedValue] = useState("white");
  const [open, setOpen] = useState(false);

  // let productImages: string[];
  // if (data != null) {
  //   productImages = [data?.main_image, data?.preview_image, ...data.images];
  // } else {
  //   productImages = [];
  // }

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ImageSlider = ({ images }: ImageSliderProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [error, setError] = useState(false);

    const handleError = () => {
      setError(true);
    };

    const handleNextClick = () => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    };

    const handlePrevClick = () => {
      const newIndex = currentImageIndex - 1;
      if (newIndex < 0) {
        setCurrentImageIndex(images.length - 1);
      } else {
        setCurrentImageIndex(newIndex);
      }
    };

    return (
      <Grid
        container
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        width={"fit-content"}
      >
        <Grid item position={"relative"}>
          <img
            className="image"
            src={
              error
                ? "/no-image.jpg"
                : `https://jewelryshopdoro.com/uploads/${images[currentImageIndex]}`
            }
            onError={handleError}
            alt="slider"
          />
          <ArrowBackIosNew className="arrow left" onClick={handlePrevClick} />
          <ArrowForwardIos className="arrow right" onClick={handleNextClick} />
        </Grid>
        <Grid item display={"flex"}>
          {images.map((_, index) => (
            <Circle
              key={index}
              className="indicator"
              style={{
                color:
                  index === currentImageIndex
                    ? color.btn_dark
                    : color.primary_medium,
              }}
              onClick={() => setCurrentImageIndex(index)}
            />
          ))}
        </Grid>
      </Grid>
    );
  };

  if (!data) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const isAvailable = () => {
    switch (selectedValue) {
      case "white":
        return data.available_white;
      case "pink":
        return data.available_pink;
      case "yellow":
        return data.available_yellow;
      default:
        return false;
    }
  };
  if (!id) {
    return <h1> Invalid application state </h1>;
  }
  const handleDelete = async () => {
    try {
      await deleteProduct(id);
      enqueueSnackbar("Proizvod je uspesno obrisan", { variant: "success" });
      navigate("/admin/products");
    } catch (error) {
      enqueueSnackbar("Obradite aktivne porudzbine pre brisanja proizvoda", {
        variant: "warning",
      });
    }
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pažnja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description:">
            Da li ste sigurni da želite da obrišete proizvod {data.product_name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: color.btn_dark, color: color.white }}
            onClick={() => handleDelete()}
            autoFocus
          >
            Potvrdi
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: color.primary, color: color.btn_dark }}
            onClick={handleClose}
          >
            Odustani
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <h1>Pregled Proizvoda #{id}</h1>
      {renderDialog()}

      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        padding={10}
        marginLeft={5}
        spacing={10}
      >
        <Grid item>
          <Grid container direction="column">
            {data != null &&
            data.main_image != null &&
            data.preview_image != null &&
            data.images != null ? (
              <Grid item>
                <ImageSlider
                  images={[data.main_image, data.preview_image, ...data.images]}
                />
              </Grid>
            ) : (
              <Grid item>
                <ImageSlider images={[]} />
                <p>Proizvod nema slike</p>
              </Grid>
            )}
            <Grid item>
              {isAvailable() ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography className="available">
                      Dostupno odmah
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="available">
                      *Saljemo u roku od 24 sata*
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography className="unavailable">
                      Nije dostupno odmah
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="unavailable">
                      *Za izradu potrebno 7-10 dana*
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "24px",
                      lineHeight: "30px",

                      color: color.btn_dark,
                    }}
                  >
                    {data.product_name} - {translateMaterial(data.material)}
                    {data.briliant ? " sa brilijantima" : ""}
                  </Typography>
                </Grid>
                <Grid item>
                  {data.isEdit ? (
                    <IconButton
                      onClick={() => {
                        navigate(`/admin/products/${id}/edit`);
                      }}
                    >
                      <Edit sx={{ color: color.btn_dark }} />
                    </IconButton>
                  ) : (
                    <Tooltip title="Proizvod se nalazi u aktivnoj porudžbini i nije ga moguće izmeniti">
                      <IconButton>
                        <Edit sx={{ color: "gray" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    <Delete sx={{ color: color.btn_dark }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "25px",
                }}
              >
                {data.description}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Kategorija: {data.category_name}</Typography>
              <Typography>
                Potkategorija:{" "}
                {data.subcategory_name ? data.subcategory_name : "/"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Tagovi:</Typography>
              {data.tags ? (
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  sx={{
                    width: "400px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {data.tags.map((tag, index) => (
                    <Grid item key={index}>
                      <Chip
                        sx={{ margin: "5px" }}
                        label={tag}
                        icon={<TagOutlined />}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div>/</div>
              )}
            </Grid>
            <Grid item>
              <Typography>
                Datum kreiranja: {formatDate(data.created_at)}
              </Typography>
            </Grid>

            {data.favorite ? (
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "24px",
                    lineHeight: "30px",
                    color: color.btn_dark,
                  }}
                >
                  Istaknuto na glavnoj stranici
                </Typography>
              </Grid>
            ) : null}

            {data.popular ? (
              <Grid item>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: "24px",
                    lineHeight: "30px",
                    color: color.btn_dark,
                  }}
                >
                  Popularan proizvod
                </Typography>
              </Grid>
            ) : null}

            <Grid item marginTop="66px">
              {data.discount_flag ? (
                <Grid container direction="row" alignItems="center">
                  <Typography className="sale">{data.price} RSD</Typography>
                  <Typography marginLeft="2px" className="product-text">
                    {data.discount_price} RSD
                  </Typography>
                </Grid>
              ) : (
                <Typography className="product-text">
                  {data.price} RSD
                </Typography>
              )}
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={selectedValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="white"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Bela"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="yellow"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Žuta"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    style={{ color: color.btn_dark }}
                    value="pink"
                    control={<Radio style={{ color: color.btn_dark }} />}
                    label="Roza"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductAdmin;
