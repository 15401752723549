import {
  addNewAboutImages,
  deleteSingleAboutImage,
} from "../../../Services/Https/about-images";
import { useAllAboutImages } from "../../../Hooks/useAboutImages";
import color from "../../../Constants/colors";
import React from "react";
import { useRef } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  CircularProgress,
  Container,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Delete, AddAPhoto } from "@mui/icons-material";

interface SingleFeaturedImageProps {
  name: string;
  id: number;
}

const SingleFeaturedImage = ({ name, id }: SingleFeaturedImageProps) => {
  const { refetch } = useAllAboutImages();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteSingleImage = async (delete_id: number) => {
    try {
      await deleteSingleAboutImage(`${delete_id}`);
      enqueueSnackbar("Uspešno brisanje slike", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Neuspešno brisanje slike", { variant: "error" });
    }
    refetch();
  };

  return (
    <Grid sx={{ position: "relative", margin: "30px" }}>
      <img
        style={{
          width: "300px",
          height: "200px",
          objectFit: "cover",
          borderRadius: "5%",
        }}
        src={`https://jewelryshopdoro.com/uploads/${name}`}
        alt="Product"
      />
      <Tooltip title="Ukloni sliku" arrow placement="bottom">
        <IconButton
          onClick={() => {
            handleDeleteSingleImage(id);
          }}
          sx={{
            position: "absolute",
            right: 5,
            bottom: 5,
            color: "red",
            opacity: "0.5",
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const FeaturedImages = () => {
  const { data, refetch } = useAllAboutImages();
  const { enqueueSnackbar } = useSnackbar();
  let selectedFile: File | null = null;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      selectedFile = file;
      await handleSubmitImage();
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleAddNewImageClick = () => {
    // Trigger the click event on the hidden file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmitImage = async () => {
    // TODO
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("images", selectedFile);

    try {
      await addNewAboutImages(formData);
      enqueueSnackbar("Uspešno dodavanje slike", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Neuspešno dodavanje slike", { variant: "error" });
    }
    refetch();
  };

  if (data && data.images) {
    const images = data.images;

    return (
      <>
        <h1>Prezentacione slike</h1>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: 1080,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {images.map((singleImage, index) => {
            return (
              <Grid item>
                <SingleFeaturedImage
                  name={singleImage.image_name}
                  id={singleImage.id}
                  key={index}
                />
              </Grid>
            );
          })}
          <Grid item>
            <Container
              sx={{
                width: "300px",
                height: "200px",
                margin: "30px",
                border: "4px solid",
                borderRadius: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderColor: color.primary_medium,
                backgroundColor: color.primary,
              }}
            >
              <Tooltip title="Dodaj novu sliku" arrow placement="bottom">
                <IconButton
                  onClick={() => {
                    handleAddNewImageClick();
                  }}
                  sx={{
                    color: color.btn_dark,
                  }}
                >
                  <AddAPhoto sx={{ fontSize: "60px" }} />
                </IconButton>
              </Tooltip>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageSelect}
                style={{ display: "none" }}
              />
            </Container>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
};

export default FeaturedImages;
