import { useQuery } from "react-query";
import { getCategories, getCategory } from "../Services/Https/categories";

export const useCategories = () => {
  return useQuery(["categories"], () => getCategories(), {
    keepPreviousData: true,
  });
};

export const useCategory = (id: string) => {
  return useQuery(["category"], () => getCategory(id), {
    keepPreviousData: true,
  });
};
