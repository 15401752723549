import { Grid } from "@mui/material";

import "../../Styles/TitleValue.css";

const TitleValue = ({ title, value }: { title: string; value: string }) => {
  return (
    <>
      <Grid item md={6} xs={12} className="questionnaire-metadata-item-title">
        <h2 style={{ margin: 0 }}>{title}</h2>
      </Grid>
      <Grid item md={6} xs={12} className="questionnaire-metadata-item-value">
        <p style={{ margin: 0 }}>{value}</p>
      </Grid>
    </>
  );
};

export default TitleValue;
