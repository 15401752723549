import {
  CircularProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Button,
  Collapse,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useSingleOrder } from "../../../Hooks/useOrders";
import "../../../Styles/ProcessedOrder.css";
import color from "../../../Constants/colors";
import { formatDate } from "../../../Services/Functions/formatDate";
import { useNavigate, useParams } from "react-router";
import CustomTableCell from "./CustomTableCell";
import { useSnackbar } from "notistack";
import { acceptOrder, declineOrder } from "../../../Services/Https/orders";

const SingleActiveOrder = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams() || "";
  const data = useSingleOrder(id ?? "");
  const [declineOpened, setDeclineOpened] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };
  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleOrderAccepted = async () => {
    try {
      await acceptOrder(`${id}`);
      enqueueSnackbar("Porudžbina uspešno prihvaćena", {
        variant: "success",
      });
      navigate("../active-orders");
    } catch (error) {
      enqueueSnackbar("Neuspešno prihvatanje porudžbine", {
        variant: "error",
      });
    }
    setDialogOpened(false);
  };

  const handleOrderDeclined = async () => {
    try {
      await declineOrder(`${id}`, declineReason);
      enqueueSnackbar("Porudžbina uspešno odbijena", {
        variant: "success",
      });
      navigate("../active-orders");
    } catch (error) {
      enqueueSnackbar("Neuspešno odbijanje porudžbine", {
        variant: "error",
      });
    }
    setDialogOpened(false);
  };

  const renderDialog = () => {
    const acceptDecisionText = isOrderAccepted ? "prihvatite" : "odbijete";

    return (
      <Dialog
        open={dialogOpened}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pažnja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description:">
            {`Da li ste sigurni da želite da ${acceptDecisionText} porudžbinu?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: color.btn_dark, color: color.white }}
            onClick={() => {
              if (isOrderAccepted) {
                handleOrderAccepted();
              } else {
                handleOrderDeclined();
              }
            }}
            autoFocus
          >
            Potvrdi
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: color.primary, color: color.btn_dark }}
            onClick={handleDialogClose}
          >
            Odustani
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const translateMaterial = (materialString: string) => {
    switch (materialString) {
      case "gold":
        return "zlato";
      case "silver":
        return "srebro";
      default:
        return "/";
    }
  };

  const translateColor = (colorString: string) => {
    switch (colorString) {
      case "white":
        return "bela";
      case "yellow":
        return "žuta";
      case "pink":
        return "roza";
      default:
        return "/";
    }
  };

  const translatePaymentType = (paymentTypeString: string) => {
    switch (paymentTypeString) {
      case "cash":
        return "Pouzećem";
      case "card":
        return "Karticom";
      default:
        return "/";
    }
  };

  const translateDeliveryType = (deliveryTypeString: string) => {
    switch (deliveryTypeString) {
      case "store":
        return "U prodavnici";
      case "shipping":
        return "Poštom";
      default:
        return "/";
    }
  };

  if (data && data.data) {
    const singleOrder = data.data;
    let totalOrderPrice = 0;
    const totalPricePerProduct = singleOrder.products.map((singleProduct) => {
      const validProductPrice = singleProduct.product_discount_flag
        ? singleProduct.product_discount_price
        : singleProduct.product_price;
      const singleProductTotalPrice = validProductPrice * singleProduct.amount;
      totalOrderPrice += singleProductTotalPrice;
      return singleProductTotalPrice;
    });
    return (
      <>
        {renderDialog()}
        <h1>{`Pregled aktivne porudžbine #${id}`}</h1>
        <h2>Podaci o mušteriji</h2>
        <TableContainer>
          <Table stickyHeader aria-label="sticky-label">
            <TableHead>
              <TableRow>
                <CustomTableCell text="Ime Mušterije" />
                <CustomTableCell text="Prezime Mušterije" />
                <CustomTableCell text="Broj Telefona" />
                <CustomTableCell text="Email" />
                <CustomTableCell text="Adresa" />
                <CustomTableCell text="Opština" />
                <CustomTableCell text="Poštanski kod" />
                <CustomTableCell text="Način plaćanja" />
                <CustomTableCell text="Isporuka" />
                <CustomTableCell text="Vreme Naručivanja" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:hover": { backgroundColor: color.primary },
                }}
              >
                <TableCell>{singleOrder.customer_name}</TableCell>
                <TableCell>{singleOrder.customer_surname}</TableCell>
                <TableCell>{singleOrder.telephone_number}</TableCell>
                <TableCell>
                  {singleOrder.email ? singleOrder.email : "/"}
                </TableCell>
                <TableCell>{singleOrder.adress}</TableCell>
                <TableCell>{singleOrder.municipality}</TableCell>
                <TableCell>{singleOrder.postal_code}</TableCell>
                <TableCell>
                  {translatePaymentType(singleOrder.payment_type)}
                </TableCell>
                <TableCell>
                  {translateDeliveryType(singleOrder.delivery)}
                </TableCell>
                <TableCell>{formatDate(singleOrder.created_at)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h2>Podaci o poručenim proizvodima</h2>
        <TableContainer>
          <Table stickyHeader aria-label="sticky-label">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: color.primary_medium,
                }}
              >
                <CustomTableCell text="Ime Proizvoda" />
                <CustomTableCell text="Kategorija" />
                <CustomTableCell text="Potkategorija" />
                <CustomTableCell text="Cena" />
                <CustomTableCell text="Materijal" />
                <CustomTableCell text="Brilijant" />
                <CustomTableCell text="Tražena Boja" />
                <CustomTableCell text="Tražena Količina" />
                <CustomTableCell text="Ukupna cena za proizvod" />
              </TableRow>
            </TableHead>
            <TableBody>
              {singleOrder.products.map((singleProduct, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": { backgroundColor: color.primary },
                    }}
                  >
                    <TableCell>{singleProduct.product_name}</TableCell>
                    <TableCell>{singleProduct.category_name}</TableCell>
                    <TableCell>
                      {singleProduct.subcategory_name ?? "/"}
                    </TableCell>
                    <TableCell>{`${
                      singleProduct.product_discount_flag
                        ? singleProduct.product_discount_price.toFixed(2)
                        : singleProduct.product_price.toFixed(2)
                    } rsd`}</TableCell>
                    <TableCell>
                      {translateMaterial(singleProduct.product_material)}
                    </TableCell>
                    <TableCell>
                      {singleProduct.product_brilliant ? "Da" : "Ne"}
                    </TableCell>
                    <TableCell>
                      {translateColor(singleProduct.ordered_color)}
                    </TableCell>
                    <TableCell>{singleProduct.amount}</TableCell>
                    <TableCell>{`${totalPricePerProduct[index].toFixed(
                      2
                    )} rsd`}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell
                  colSpan={11}
                  sx={{
                    backgroundColor: color.primary_medium,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {`Ukupna cena porudžbine: ${totalOrderPrice.toFixed(2)} rsd`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h2>Napomena mušterije</h2>
        <TextareaAutosize
          style={{
            width: "100%",
            resize: "none",
            fontSize: "18px",
            cursor: "default",
          }}
          placeholder="Napomena Mušterije"
          value={singleOrder.note}
          minRows={10}
          readOnly
        />
        <h2>Akcije</h2>
        <Button
          variant="contained"
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginRight: "30px",
          }}
          onClick={() => {
            setIsOrderAccepted(true);
            handleDialogOpen();
          }}
        >
          Prihvati porudžbinu
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: color.primary, color: color.btn_dark }}
          onClick={() => setDeclineOpened(!declineOpened)}
        >
          Odbij porudžbinu
        </Button>
        <Collapse in={declineOpened} timeout="auto" unmountOnExit>
          <h3>Napišite razlog odbijanja porudžbine</h3>
          <TextareaAutosize
            style={{ width: "100%", resize: "none", fontSize: "18px" }}
            placeholder="Napišite vaš razlog ovde"
            minRows={10}
            maxLength={500}
            onChange={(event) => setDeclineReason(event.target.value)}
          />
          <br />
          <Button
            variant="contained"
            sx={{ backgroundColor: color.primary, color: color.btn_dark }}
            onClick={() => {
              setIsOrderAccepted(false);
              handleDialogOpen();
            }}
          >
            Odbij porudžbinu
          </Button>
        </Collapse>
        {/* This div acts as a bottom margin */}
        <div style={{ width: "100%", height: "100px" }} />
      </>
    );
  } else {
    return <CircularProgress />;
  }
};

export default SingleActiveOrder;
