import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useCategories } from "../../../Hooks/useCategories";
import "../../../Styles/Navbar.css";
import { ISubcategory } from "../../../Model/Category";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useScreenSize from "../../../Hooks/Misc/useScreenSize";
import { useSearchContext } from "../../SearchContext/SearchContext";

enum HoveredMain {
  GOLD = 1,
  SILVER = 2,
  BRILIANT = 3,
  CUSTOM = 4,
  ALL = 5,
}

const convertNameToEnum = (name: string) => {
  switch (name) {
    case "Zlato":
      return HoveredMain.GOLD;
    case "Srebro":
      return HoveredMain.SILVER;
    case "Brilijanti i drago kamenje":
      return HoveredMain.BRILIANT;
    case "Nakit po vašoj želji":
      return HoveredMain.CUSTOM;
    case "Svi proizvodi":
      return HoveredMain.ALL;
    default:
      return null;
  }
};
const subMenuParents = ["Zlato", "Srebro", "Brilijanti i drago kamenje"];

interface MainMenuProps {
  handleMenuOpen: () => void; // Function prop type
  isMenuOpen: boolean;
}

const MainMenu: React.FC<MainMenuProps> = ({ handleMenuOpen, isMenuOpen }) => {
  const [openMain, setOpenMain] = useState(isMenuOpen);
  const [hoveredMain, setHoveredMain] = useState<HoveredMain | null>(null);
  const [hoveredSub, setHoveredSub] = useState<string | null>(null);
  const [hoveredSubSub, setHoveredSubSub] = useState<string | null>(null);
  const [hoveredSubId, setHoveredSubId] = useState<number>(0);
  const { setSearch } = useSearchContext();

  const navigate = useNavigate();
  const { data } = useCategories();
  const phone = useScreenSize();

  useEffect(() => {
    if (!isMenuOpen) {
      setHoveredMain(null);
      setHoveredSub(null);
      setHoveredMain(null);
      setOpenMain(false);
    }
  }, [isMenuOpen]);

  const handleExpandMain = (parent: HoveredMain | null) => {
    if (hoveredMain === parent) {
      setHoveredMain(null);
      setHoveredSub(null);
      setHoveredSubSub(null);
    } else {
      setHoveredMain(parent);
    }
  };

  const handleExpandSub = (name: string | null, id: number) => {
    if (hoveredSub === name) {
      setHoveredSub(null);
    } else {
      setHoveredSub(name);
      setHoveredSubId(id);
    }
  };
  const handleOpenMenu = () => {
    const button = document.querySelector(".menu-icon");
    button?.classList.add("jump-and-flip");
    setTimeout(() => {
      button?.classList.remove("jump-and-flip");
    }, 1000);
    if (openMain) {
      setHoveredMain(null);
      setHoveredSub(null);
      setHoveredMain(null);
      setOpenMain(false);
    } else {
      setOpenMain(true);
    }
    handleMenuOpen();
  };

  const mappedCategories: Record<string, ISubcategory[]> = data
    ? data.categories.reduce(
        (map: Record<string, ISubcategory[]>, category) => {
          map[category.category_name] = category.subcategories;
          return map;
        },
        {}
      )
    : {};

  const handleMouseLeaveMain = () => {
    setHoveredMain(null);
    setHoveredSubSub(null);
  };

  const handleMouseLeaveSub = () => {
    setHoveredSubSub(null);
  };

  const handleMouseClickMain = (routeType: HoveredMain | null) => {
    let material = null;
    let briliant = null;
    switch (routeType) {
      case HoveredMain.ALL:
        navigate("products", {
          state: {
            material: null,
            briliant: null,
            category_id: null,
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        setSearch("");
        break;
      case HoveredMain.CUSTOM:
        navigate("about");
        setSearch("");
        break;
      case HoveredMain.SILVER:
        material = "silver";
        navigate("products", {
          state: {
            material: material,
            briliant: null,
            category_id: null,
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      case HoveredMain.GOLD:
        material = "gold";
        navigate("products", {
          state: {
            material: material,
            briliant: null,
            category_id: null,
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      case HoveredMain.BRILIANT:
        briliant = true;
        material = "gold";
        navigate("products", {
          state: {
            material: material,
            briliant: briliant,
            category_id: null,
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      default:
        break;
    }
    handleMenuOpen();
  };

  const handleMouseClickSub = (hovered: HoveredMain, id: number) => {
    let material = null;
    let briliant = null;
    switch (hovered) {
      case HoveredMain.SILVER:
        material = "silver";
        navigate("products", {
          state: {
            material: material,
            briliant: null,
            category_id: [id],
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      case HoveredMain.GOLD:
        material = "gold";
        navigate("products", {
          state: {
            material: material,
            briliant: null,
            category_id: [id],
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      case HoveredMain.BRILIANT:
        briliant = "true";
        material = "gold";
        navigate("products", {
          state: {
            material: material,
            briliant: briliant,
            category_id: [id],
            subcategory_id: null,
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      default:
        break;
    }
    handleMenuOpen();
  };

  const handleMouseClickSubSub = (
    hovered: HoveredMain | null,
    category: number,
    subcategory: number | undefined
  ) => {
    let material = null;
    let briliant = null;
    switch (hovered) {
      case HoveredMain.SILVER:
        material = "silver";
        navigate("products", {
          state: {
            material: material,
            briliant: briliant,
            category_id: [category],
            subcategory_id: [subcategory],
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      case HoveredMain.GOLD:
        material = "gold";
        navigate("products", {
          state: {
            material: material,
            briliant: briliant,
            category_id: [category],
            subcategory_id: [subcategory],
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      case HoveredMain.BRILIANT:
        briliant = "true";
        material = "gold";
        navigate("products", {
          state: {
            material: material,
            briliant: briliant,
            category_id: [category],
            subcategory_id: [subcategory],
            order: "desc",
            orderBy: "popular",
          },
        });
        sessionStorage.setItem("page", (0).toString());
        window.dispatchEvent(new Event("pageReset"));
        setSearch("");
        break;
      default:
        break;
    }
    handleMenuOpen();
  };

  const renderSubSubMenu = () => {
    if (hoveredSub) {
      return (
        <div className="menu-container sub-sub-menu-container">
          <Grid container direction="column">
            {mappedCategories[hoveredSub].map((subcategory, index) => (
              <Grid
                item
                key={index}
                className="menu-item-container"
                onMouseEnter={() =>
                  setHoveredSubSub(subcategory.subcategory_name)
                }
                onMouseLeave={() => {
                  setHoveredSubSub(null);
                }}
                onClick={() => {
                  handleMouseClickSubSub(
                    hoveredMain,
                    hoveredSubId,
                    subcategory.subcategory_id
                  );
                }}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    hoveredSubSub === subcategory.subcategory_name
                      ? "#FFF4E3"
                      : "#fefefe",
                }}
              >
                <Typography className="menu-item">
                  {subcategory.subcategory_name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  };
  const renderSubMenu = () => {
    if (
      hoveredMain === HoveredMain.GOLD ||
      hoveredMain === HoveredMain.SILVER ||
      hoveredMain === HoveredMain.BRILIANT
    ) {
      return (
        <div className="menu-container sub-menu-container">
          <Grid container direction="column">
            {data
              ? data.categories.map((category, index) => (
                  <Grid
                    item
                    key={index}
                    className="menu-item-container"
                    onMouseEnter={
                      phone
                        ? () => {}
                        : () => {
                            setHoveredSub(category.category_name);
                            setHoveredSubId(category.id);
                          }
                    }
                    onMouseLeave={phone ? () => {} : handleMouseLeaveSub}
                    onClick={
                      phone
                        ? () => {}
                        : () => {
                            handleMouseClickSub(hoveredMain, category.id);
                          }
                    }
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        hoveredSub === category.category_name
                          ? "#FFF4E3"
                          : "#fefefe",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        onClick={
                          phone
                            ? () =>
                                handleMouseClickSub(hoveredMain, category.id)
                            : () => {}
                        }
                      >
                        <Typography className="menu-item">
                          {category.category_name}
                        </Typography>
                      </Grid>
                      {phone ? (
                        <Grid item>
                          <IconButton
                            onClick={() =>
                              handleExpandSub(
                                category.category_name,
                                category.id
                              )
                            }
                          >
                            {hoveredSub === category.category_name ? (
                              <ArrowBackIosIcon className="read-more-icon" />
                            ) : (
                              <ArrowForwardIosIcon className="read-more-icon" />
                            )}
                          </IconButton>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  };
  const renderMainMenu = () => {
    if (openMain) {
      return (
        <div className="menu-container main-menu-container">
          <Grid container direction="column" alignItems="center">
            {subMenuParents.map((parent, index) => (
              <Grid
                item
                key={index}
                onClick={
                  phone
                    ? () => {}
                    : () => handleMouseClickMain(convertNameToEnum(parent))
                }
                className="menu-item-container"
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    hoveredMain === convertNameToEnum(parent)
                      ? "#FFF4E3"
                      : "#fefefe",
                }}
                onMouseEnter={
                  phone
                    ? () => {}
                    : () => setHoveredMain(convertNameToEnum(parent))
                }
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    onClick={
                      phone
                        ? () => handleMouseClickMain(convertNameToEnum(parent))
                        : () => {}
                    }
                  >
                    <Typography className="menu-item">{parent}</Typography>
                  </Grid>
                  {phone ? (
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          handleExpandMain(convertNameToEnum(parent))
                        }
                      >
                        {convertNameToEnum(parent) === hoveredMain ? (
                          <ArrowBackIosIcon className="read-more-icon" />
                        ) : (
                          <ArrowForwardIosIcon className="read-more-icon" />
                        )}
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ))}
            <Grid
              item
              className="menu-item-container"
              onClick={() => {
                handleMouseClickMain(HoveredMain.CUSTOM);
              }}
              onMouseLeave={handleMouseLeaveMain}
              onMouseEnter={() => {
                setHoveredMain(convertNameToEnum("Nakit po vašoj želji"));
                setHoveredSub(null);
                setHoveredSubSub(null);
              }}
              sx={{
                cursor: "pointer",
                backgroundColor:
                  hoveredMain === convertNameToEnum("Nakit po vašoj želji")
                    ? "#FFF4E3"
                    : "#fefefe",
              }}
            >
              <Typography className="menu-item">
                Nakit po vašoj želji
              </Typography>
            </Grid>
            <Grid
              item
              className="menu-item-container"
              onMouseEnter={() => {
                setHoveredMain(convertNameToEnum("Svi proizvodi"));
                setHoveredSub(null);
                setHoveredSubSub(null);
              }}
              onClick={() => {
                handleMouseClickMain(HoveredMain.ALL);
              }}
              sx={{
                cursor: "pointer",
                backgroundColor:
                  hoveredMain === convertNameToEnum("Svi proizvodi")
                    ? "#FFF4E3"
                    : "#fefefe",
              }}
            >
              <Typography className="menu-item">Svi proizvodi</Typography>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleOpenMenu}
          >
            <img src="/menu.png" alt="menu" className="menu-icon" />
          </IconButton>
        </Grid>
        {!phone ? (
          <Grid item>
            <Typography className="menu-icon-text">meni</Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        className="menu-wrapper"
      >
        <Grid item>{renderMainMenu()}</Grid>
        <Grid item>{renderSubMenu()}</Grid>
        <Grid item>{renderSubSubMenu()}</Grid>
      </Grid>
    </>
  );
};

export default MainMenu;
