import CreateCategory from "../../../Components/Admin/CreateCategory";
import { ICreateCategory } from "../../../Model/Category";
import { useCategory } from "../../../Hooks/useCategories";
import { useParams } from "react-router-dom";
const EditCategoryPage = () => {
  const { id } = useParams();
  const { data } = useCategory(id ?? "");

  if (data && id) {
    const category: ICreateCategory = { ...data.category };

    return (
      <CreateCategory
        isEdit={true}
        initialCategory={category}
        id={data.category.id.toString()}
      />
    );
  } else {
    return <h1>{`Ne postoji kategorija koja ima id ${id}`}</h1>;
  }
};
export default EditCategoryPage;
