import { Typography, Container } from "@mui/material";

const HomePage = () => {
  return (
    <>
      <Container sx={{ textAlign: "center", marginBottom: "50px" }}>
        <Typography sx={{ fontSize: "70px" }}>
          503 - Sajt se unapređuje
        </Typography>
        <br />
        <img src="/404.gif" alt="" style={{ width: "100%" }} />
        <br />
        <Typography sx={{ fontSize: "30px" }}>
          Dragi kupci, obaveštavamo vas da naš sajt trenutno prolazi kroz fazu
          unapređivanja kako bismo vam pružili bolju uslugu u budućnosti. Radimo
          naporno kako bismo vam osigurali kvalitetniju platformu te vas molimo
          za strpljenje i zahvaljujemo se na razumevanju. Idalje možete da
          pogledate našu ponudu na instagram stranici
          <b>
            <a href="https://www.instagram.com/jewelry_shop_doro/">
              {` @jewelry_shop_doro `}
            </a>
          </b>
          ili da nas pozovete na broj telefona: <b>0605500750</b>
        </Typography>
      </Container>
    </>
  );
};

export default HomePage;
