import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Category,
  Add,
  Inventory,
  NotificationsActive,
  Diamond,
  CheckCircle,
  Face2,
  Password,
  Home,
  Collections,
  StoreMallDirectory,
  FlipCameraIos,
  AddAPhotoOutlined,
  ListAlt,
  Analytics,
} from "@mui/icons-material";

import { FC, useState } from "react";

interface Props {
  path: string;
  text: string;
  icon: any;
}

export const DashboardMenuItem: FC<Props> = ({ path, text, icon }) => {
  return (
    <ListItem disablePadding component={Link} to={path}>
      <ListItemButton>
        <ListItemIcon sx={{ color: "#CF9B2E" }}>{icon}</ListItemIcon>
        <ListItemText primary={text} sx={{ color: "black" }} />
      </ListItemButton>
    </ListItem>
  );
};

interface CollapseProps {
  text: string;
  icon: any;
  children: any;
}

export const DashboardMenuCollapse: FC<CollapseProps> = ({
  children,
  icon,
  text,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((value) => !value);
  return (
    <ListItem disablePadding>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <ListItemButton onClick={toggleOpen}>
          <ListItemIcon sx={{ color: "#CF9B2E" }}>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItemButton>
        <Collapse in={open} style={{ paddingLeft: "1em" }}>
          {children}
        </Collapse>
      </div>
    </ListItem>
  );
};

export const DashboardMenu = () => {
  return (
    <List>
      <DashboardMenuItem
        path="/admin"
        text="Početna Stranica"
        icon={<Home />}
      />
      <DashboardMenuItem
        path="/"
        text="Prodavnica"
        icon={<StoreMallDirectory />}
      />
      <DashboardMenuItem
        path="https://analytics.google.com/analytics/web/?authuser=3#/p396754701/reports/intelligenthome"
        text="Analitika"
        icon={<Analytics />}
      />
      <hr />
      <DashboardMenuCollapse text="Porudzbine" icon={<ListAlt />}>
        <DashboardMenuItem
          path="/admin/active-orders"
          text="Aktivne Porudzbine"
          icon={<NotificationsActive />}
        />
        <DashboardMenuItem
          path="/admin/processed-orders"
          text="Obradjene Porudzbine"
          icon={<CheckCircle />}
        />
      </DashboardMenuCollapse>
      <DashboardMenuCollapse text="Kategorije" icon={<Category />}>
        <DashboardMenuItem
          path="/admin/categories"
          text="Sve Kategorije"
          icon={<ListAlt />}
        />
        <DashboardMenuItem
          path="/admin/categories/create"
          text="Kreiraj Kategoriju"
          icon={<Add />}
        />
      </DashboardMenuCollapse>
      <DashboardMenuCollapse text="Proizvodi" icon={<Inventory />}>
        <DashboardMenuItem
          path="/admin/products"
          text="Svi Proizvodi"
          icon={<Diamond />}
        />
        <DashboardMenuItem
          path="/admin/products/create"
          text="Kreiraj Proizvod"
          icon={<AddAPhotoOutlined />}
        />
      </DashboardMenuCollapse>
      <DashboardMenuCollapse text="Prezentacione slike" icon={<Collections />}>
        <DashboardMenuItem
          path="/admin/about-image"
          text="Promeni prezentacione slike"
          icon={<FlipCameraIos />}
        />
      </DashboardMenuCollapse>
      <DashboardMenuCollapse text="Nalog" icon={<Face2 />}>
        <DashboardMenuItem
          path="/admin/password"
          text="Promeni Šifru"
          icon={<Password />}
        />
      </DashboardMenuCollapse>
    </List>
  );
};
