const color = {
  primary: "#FFF4E3", // LightGold
  primary_medium: "#D9CFC1", // MediumGold
  primary_dark: "#B3AB9F", // DarkGold
  accent: "#CF9B2E", // Dark UI
  btn_dark: "#79590E",
  secondary: "#000000",
  secondaryDark: "#58595b",
  black: "#000000",
  white: "#FEFEFE",
  red: "#E21D39",
};

export default color;
