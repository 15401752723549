import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import color from "../../../Constants/colors";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import { IPaymentInformation } from "../../../Model/Order";

interface ISingleCartProductProps {
  productID: number;
  productName: string;
  productPrice: number;
  productImage: string;
  productAmount: number;
  productColor: string;
  productAvailableNow: boolean;
}

const SingleCartProduct = ({
  productID,
  productName,
  productPrice,
  productImage,
  productAmount,
  productColor,
  productAvailableNow,
}: ISingleCartProductProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const totalProductPrice = productPrice * productAmount;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth > 785 ? ( // HORIZONTAL
    <TableRow>
      <TableCell>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "15px",
            marginBottom: "15px",
            marginRight: "15px",
          }}
        >
          <Grid
            item
            onClick={() => {
              window.open(
                `https://jewelryshopdoro.com/products/${productID}`,
                "_blank"
              );
            }}
          >
            <img
              style={{
                width: "130px",
                height: "150px",
                marginRight: "8px",
                objectFit: "cover",
              }}
              src={`https://jewelryshopdoro.com/uploads/${productImage}`}
              alt="Korpa Slika"
            ></img>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
              onClick={() => {
                window.open(
                  `https://jewelryshopdoro.com/products/${productID}`,
                  "_blank"
                );
              }}
            >
              {productName}
            </Typography>
            {productAvailableNow ? (
              <>
                <Typography
                  sx={{ fontSize: "16px", color: "#428210", marginTop: "40px" }}
                >
                  Dostupno odmah
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#428210" }}>
                  *Šaljemo u roku od 24 sata
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{ fontSize: "16px", color: "#F88D2B", marginTop: "40px" }}
                >
                  Nije dostupno odmah
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#F88D2B" }}>
                  *za izradu potrebno 7-10 dana
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#79590E", marginRight: "15px" }}
        >{`${productPrice} rsd`}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#79590E", marginRight: "15px" }}
        >{`${productAmount}`}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{ fontSize: "18px", color: "#CF9B2E", marginRight: "15px" }}
        >
          {`${totalProductPrice.toFixed(2)} rsd`}
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    // VERTICAL
    <TableRow>
      <TableCell>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: screenWidth > 500 ? "row" : "column",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              onClick={() => {
                window.open(
                  `https://jewelryshopdoro.com/products/${productID}`,
                  "_blank"
                );
              }}
            >
              <img
                style={{
                  width: "130px",
                  height: "150px",
                  marginRight: screenWidth > 500 ? "20px" : "0px",
                  objectFit: "cover",
                }}
                src={`https://jewelryshopdoro.com/uploads/${productImage}`}
                alt="Korpa Slika"
              ></img>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: screenWidth > 500 ? "left" : "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: screenWidth > 500 ? "18px" : "24px",
                  color: "#79590E",
                }}
                onClick={() => {
                  window.open(
                    `https://jewelryshopdoro.com/products/${productID}`,
                    "_blank"
                  );
                }}
              >
                {productName}
              </Typography>
              {productAvailableNow ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#428210",
                      marginTop: screenWidth > 500 ? "40px" : "25px",
                    }}
                  >
                    Dostupno odmah
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#428210" }}>
                    *Šaljemo u roku od 24 sata
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#F88D2B",
                      marginTop: screenWidth > 500 ? "40px" : "25px",
                    }}
                  >
                    Nije dostupno odmah
                  </Typography>
                  <Typography sx={{ fontSize: "16px", color: "#F88D2B" }}>
                    *za izradu potrebno 7-10 dana
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Cena
            </Typography>
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
            >{`${productPrice} rsd`}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Količina
            </Typography>
            <Typography
              sx={{ fontSize: "18px", color: "#79590E" }}
            >{`${productAmount}`}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography sx={{ fontSize: "18px", color: "#79590E" }}>
              Ukupno
            </Typography>
            <Typography sx={{ fontSize: "18px", color: "#CF9B2E" }}>
              {`${totalProductPrice.toFixed(2)} rsd`}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const PaymentSuccessful = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cartProductsString, setCartProductsString] = useState("");
  const [orderInformationString, setOrderInformationString] = useState("");
  const navigate = useNavigate();
  const [paymentInformation, setPaymentInformation] =
    useState<IPaymentInformation>({});

  useEffect(() => {
    // Load products and order info from session storage
    const newStorageData = sessionStorage.getItem("products");
    if (newStorageData !== null) {
      setCartProductsString(newStorageData);
      sessionStorage.removeItem("products");
    }
    const newOrderInformationString =
      sessionStorage.getItem("orderInformation");
    if (newOrderInformationString !== null) {
      setOrderInformationString(newOrderInformationString);
    }

    // Handle resize event
    window.addEventListener("resize", handleResize);

    const query = new URLSearchParams(window.location.search);
    const paymentInfo = {
      date: query.get("date") || undefined,
      auth_code: query.get("auth_code") || undefined,
      order_id: query.get("order_id") || undefined,
      response: query.get("response") || undefined,
      proc_return_code: query.get("proc_return_code") || undefined,
      md_status: query.get("md_status") || undefined,
    };
    setPaymentInformation(paymentInfo);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  if (cartProductsString && orderInformationString) {
    const cartProducts = JSON.parse(cartProductsString);
    let totalOrderPrice = 0.0;
    for (let singleCartProduct of cartProducts) {
      totalOrderPrice += singleCartProduct.price * singleCartProduct.amount;
    }

    const orderInformation = JSON.parse(orderInformationString);

    // Clear order data
    sessionStorage.removeItem("orderInformation");
    sessionStorage.removeItem("customerNote");

    return (
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "27px",
        }}
      >
        <ScrollToTop />
        <Grid item sx={{ marginTop: "40px", textAlign: "center" }}>
          <Typography sx={{ fontSize: "36px", color: "#79590E" }}>
            Porudžbina uspešno završena!
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: screenWidth > 345 ? "24px" : "20px",
              color: "#79590E",
            }}
          >
            Na e-mail ćete dobiti podatke o pokušaju uplate
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "65px" }}>
          <Grid item>
            <TableContainer>
              <Table>
                {screenWidth > 785 ? (
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Proizvod
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Cena
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Količina
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
                          Ukupno
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                ) : null}
                <TableBody>
                  {cartProducts.map((singleCartProduct: any, index: number) => {
                    return (
                      <SingleCartProduct
                        key={index}
                        productID={singleCartProduct.id}
                        productName={singleCartProduct.product_name}
                        productPrice={singleCartProduct.price}
                        productImage={singleCartProduct.main_image}
                        productAmount={singleCartProduct.amount}
                        productColor={singleCartProduct.color}
                        productAvailableNow={singleCartProduct.availableNow}
                      />
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={screenWidth > 785 ? 4 : 1}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "#79590E",
                          textAlign: "center",
                        }}
                      >
                        {`Ukupna cena porudžbine: ${totalOrderPrice.toFixed(
                          2
                        )} rsd`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: "40px", textAlign: "center" }}>
          <Typography sx={{ fontSize: "24px", color: "#79590E" }}>
            Hvala!
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: "24px", color: "#79590E" }}>
            Jewelry Shop Doro
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "40px", textAlign: "center" }}>
          <Button onClick={() => navigate("/products")}>
            <Typography sx={{ fontSize: "20px", color: "#79590E" }}>
              {"< Nazad na radnju"}
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            sx={{ marginTop: "40px", textAlign: "center" }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Date: {paymentInformation.date ?? "/"}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Auth Code: {paymentInformation.auth_code ?? "/"}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Order ID: {paymentInformation.order_id ?? "/"}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Response: {paymentInformation.response ?? "/"}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Proc Return Code: {paymentInformation.proc_return_code ?? "/"}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                MD Status: {paymentInformation.md_status ?? "/"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Ime: {orderInformation.customer_name}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Prezime: {orderInformation.customer_surname}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Email: {orderInformation.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Adresa: {orderInformation.adress}
              </Typography>
              <Typography
                sx={{
                  fontSize: screenWidth > 345 ? "15px" : "10px",
                  color: "#79590E",
                }}
              >
                Opština: {orderInformation.municipality}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress sx={{ color: color.accent }} />
      </Grid>
    );
  }
};

export default PaymentSuccessful;
