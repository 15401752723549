import api from "../../api";

export const login = async (username: string, password: string) => {
  const response = await api.post(`/admin/auth/login`, {
    username: username,
    password: password,
  });
  return response.data;
};

export const checkAuth = async () => {
  const response = await api.get("/admin/auth/checkAuth");
  return response;
};

export const logout = async () => {
  const response = await api.delete("/admin/auth/logout");
  return response;
};

export const changePw = async (old_password: string, new_password: string) => {
  const response = await api.post("/admin/auth/changepw", {
    old_password,
    new_password,
  });
  return response;
};
