import React, { createContext, useState, useContext, ReactNode } from "react";

interface SearchContextProps {
  search: string;
  setSearch: (search: string) => void;
}

const SearchContext = createContext<SearchContextProps>({
  search: "",
  setSearch: () => {},
});

export const useSearchContext = () => useContext(SearchContext);

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [search, setSearch] = useState("");

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      {children}
    </SearchContext.Provider>
  );
};
