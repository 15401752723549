import {
  Button,
  Grid,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import "../../../Styles/ConfirmRefundDialog.css";

const ConfirmRefundDialog = (
  refundOid: string | null,
  refundPrice: string | null,
  dialogBtnDisabled: boolean,
  handleShowRefundDialog: (
    newRefundOid: string | null,
    newRefundPrice: string | null
  ) => void,
  handleDialogConfirmTextChange: (value: string) => void,
  submitRefund: () => void
) => {
  const expectedConfirmText = "Prihvatam da povratim sredstva";

  return (
    <Grid
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0",
        left: "0",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#000000CA",
        zIndex: "9999",
      }}
    >
      <Grid
        item
        className="refund-dialog-white-container"
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "24px" }}
        >{`Porudžbina # ${refundOid}`}</Typography>
        <Typography
          className="refund-dialog-title"
          sx={{ marginBottom: "40px" }}
        >
          Potvrdite refundiranje
        </Typography>

        <Grid
          item
          className="refund-dialog-bottom-container"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Leva sekcija */}
          <Grid
            item
            className="refund-dialog-left-section"
            sx={{
              display: "flex",
              flexDirection: "column",

              backgroundColor: "#F0F4F8",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Typography
                  className="refund-dialog-left-section-price"
                  sx={{
                    fontWeight: "bold",
                    color: "#D66A6A",
                  }}
                >
                  {/* {refundPrice} */}
                  {"120999.00 rsd"}
                </Typography>
              </Grid>

              <Grid
                item
                className="refund-dialog-left-section-icon-container"
                sx={{
                  backgroundColor: "#FFEEEE",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#D66A6A",
                  }}
                >
                  <CurrencyExchangeIcon className="refund-dialog-left-section-icon" />
                </ListItemIcon>
              </Grid>
            </Grid>
            <Typography sx={{ fontSize: "20px" }}>Iznos za povraćaj</Typography>
          </Grid>
          {/* Desna sekcija */}
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                width: "320px",
                textAlign: "center",
                marginBottom: "10px",
                userSelect: "none",
              }}
            >{`Da bi ste potvrdili, unesite "${expectedConfirmText}" u polje ispod`}</Typography>
            <TextField
              sx={{ width: "320px", marginBottom: "40px" }}
              // value={dialogConfirmText}
              onChange={(e) => handleDialogConfirmTextChange(e.target.value)}
            ></TextField>
            <Button
              disabled={dialogBtnDisabled}
              variant="contained"
              sx={{
                width: "320px",
                backgroundColor: "#79590E",
                color: "#FEFEFE",
                marginBottom: "10px",
              }}
              onClick={submitRefund}
            >
              Potvrdi
            </Button>
            <Button
              variant="contained"
              sx={{ width: "320px", color: "#79590E" }}
              onClick={() => handleShowRefundDialog(null, null)}
            >
              Otkaži
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmRefundDialog;
