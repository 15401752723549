import { Routes, Route } from "react-router-dom";
import ActiveOrdersPage from "./Pages/Admin/ActiveOrders";
import SingleActiveOrderPage from "./Pages/Admin/SingleActiveOrder";
import ProcessedOrdersPage from "./Pages/Admin/ProcessedOrders";
import HomePage from "./Pages/Client/Home";
import Dashboard from "./Pages/Admin/Dashboard/Dashboard";
import LoginPage from "./Pages/Admin/Login";
import AdminHomePage from "./Pages/Admin/AdminHome";
import CategoriesPage from "./Pages/Admin/Categories";
import CategoryPage from "./Pages/Admin/Category";
import CreateCategoryPage from "./Pages/Admin/CreateCategory";
import EditCategoryPage from "./Pages/Admin/EditCategory";
import ProductsAdminPage from "./Pages/Admin/Products";
import ProductAdminPage from "./Pages/Admin/Product";
import ChangePwPage from "./Pages/Admin/ChangePw";
import PageNotFound from "./Components/PageNotFound";
import withAuth from "./Components/Admin/WithAuth";
import MaintenancePage from "./Pages/Client/Maintenance";
import ProductCreate from "./Components/Admin/ProductCreate";
import FeaturedImages from "./Components/Admin/FeaturedImages";
import Navbar from "./Components/Client/Navbar";
import ClientProductsPage from "./Pages/Client/Products";
import { SearchProvider } from "./Components/SearchContext/SearchContext";
import ProductUpdatePage from "./Pages/Admin/ProductUpdate";
import SingleProduct from "./Pages/Client/SingleProduct";
import CartPage from "./Pages/Client/CartPage";
import OrderInformationPage from "./Pages/Client/OrderInformationPage";
import OrderFinalPage from "./Pages/Client/OrderFinalPage";
import AboutPage from "./Pages/Client/AboutPage";
import PaymentSuccessfulPage from "./Pages/Client/PaymentSuccessfulPage";
import PaymentFailedPage from "./Pages/Client/PaymentFailedPage";

const AppRoutes = () => {
  return (
    <SearchProvider>
      <Routes>
        {/* <Route path="/" element={withAuth(Navbar, "/maintenance")}> */}
        <Route path="/" element={<Navbar />}>
          <Route path="" exact element={<HomePage />} />
          <Route path="products" element={<ClientProductsPage />} />
          <Route path="products/:id" element={<SingleProduct />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="order-info" element={<OrderInformationPage />} />
          <Route path="order-final" element={<OrderFinalPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route
            path="payment/payment-successful"
            element={<PaymentSuccessfulPage />}
          />
          <Route
            path="payment/payment-failed"
            element={<PaymentFailedPage />}
          />
        </Route>
        <Route path="/admin" element={withAuth(Dashboard, "/login")}>
          <Route path="" exact element={<AdminHomePage />} />
          <Route path="password" element={<ChangePwPage />} />
          <Route path="active-orders" element={<ActiveOrdersPage />} />
          <Route path="active-orders/:id" element={<SingleActiveOrderPage />} />
          <Route path="processed-orders" element={<ProcessedOrdersPage />} />
          <Route path="categories" element={<CategoriesPage />} />
          <Route path="categories/create" element={<CreateCategoryPage />} />
          <Route path="categories/:id/view" element={<CategoryPage />} />
          <Route path="categories/:id/edit" element={<EditCategoryPage />} />
          <Route path="products" element={<ProductsAdminPage />} />
          <Route path="products/:id" element={<ProductAdminPage />} />
          <Route path="products/create" element={<ProductCreate />} />
          <Route path="products/:id/edit" element={<ProductUpdatePage />} />
          <Route path="about-image" element={<FeaturedImages />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </SearchProvider>
  );
};

export default AppRoutes;
