import { Grid, Typography } from "@mui/material";
import "../../../Styles/CardIcon.css";
import "../../../Styles/Footer.css";

const CardIcons = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          className="sublogos-container"
        >
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <img
                  src="/maestro.png"
                  alt="Maestro"
                  className="card-logo-container"
                />
              </Grid>
              <Grid item>
                <img
                  src="/master.png"
                  alt="Master"
                  className="card-logo-container"
                />
              </Grid>
              <Grid item>
                <img
                  src="/dina.png"
                  alt="Dina"
                  className="card-logo-container"
                />
              </Grid>
              <Grid item>
                <img
                  src="/visa.png"
                  alt="Visa"
                  className="card-logo-container"
                />
              </Grid>
              <Grid item>
                <img
                  src="/american.png"
                  alt="American"
                  className="card-logo-container"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <a
              href="https://www.bancaintesa.rs/"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/intesa.png"
                alt="Intesa"
                className="card-logo-container"
              />
            </a>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={6}>
              <Grid item>
                <a
                  href="http://www.mastercard.com/rs/consumer/credit-cards.html"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/master_check.png"
                    alt="Master Check"
                    className="card-logo-container"
                  />
                </a>
              </Grid>
              <Grid item>
                <a
                  href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/visa_secure.png"
                    alt="Visa secure"
                    className="card-logo-container"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          className="trinity-container"
        >
          <a
            href="https://3nity-solutions.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Grid item>
              <img
                src="/3nity.png"
                alt="Trinity Logo"
                className="trinity-logo"
              />
            </Grid>
          </a>
          <Grid item>
            <Typography className="footer-title">
              Powered by 3nity Solutions
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardIcons;
