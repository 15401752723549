import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Chip,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { CheckCircleOutline, CancelOutlined } from "@mui/icons-material";
import { formatDate } from "../../../Services/Functions/formatDate";
import { ISingleProcessedOrder } from "../../../Model/Order";
import "../../../Styles/ProcessedOrder.css";
import color from "../../../Constants/colors";

const CustomListRow = (
  key: number,
  singleProcessedOrder: ISingleProcessedOrder,
  opened: boolean,
  onClickHandle: () => void,
  handleShowRefundDialog: (
    newRefundOid: string | null,
    newRefundPrice: string | null
  ) => void
): JSX.Element => {
  const translateMaterial = (materialString: string) => {
    switch (materialString) {
      case "gold":
        return "zlato";
      case "silver":
        return "srebro";
      default:
        return "/";
    }
  };

  const translateColor = (colorString: string) => {
    switch (colorString) {
      case "white":
        return "bela";
      case "yellow":
        return "žuta";
      case "pink":
        return "roza";
      default:
        return "/";
    }
  };

  const translatePaymentType = (paymentTypeString: string) => {
    switch (paymentTypeString) {
      case "cash":
        return "Pouzećem";
      case "card":
        return "Karticom";
      default:
        return "/";
    }
  };

  const translateDeliveryType = (deliveryTypeString: string) => {
    switch (deliveryTypeString) {
      case "store":
        return "U prodavnici";
      case "shipping":
        return "Poštom";
      default:
        return "/";
    }
  };

  return (
    <TableBody key={key}>
      <TableRow
        sx={{
          backgroundColor: opened ? color.primary_dark : "white",
          color: opened ? color.primary : "black",
          "&:hover": opened
            ? color.primary_dark
            : { backgroundColor: color.primary },
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={onClickHandle}
      >
        <TableCell>
          {
            <Chip
              label={singleProcessedOrder.accepted ? "Prihvaćena" : "Odbijena"}
              icon={
                singleProcessedOrder.accepted ? (
                  <CheckCircleOutline style={{ color: "green" }} />
                ) : (
                  <CancelOutlined style={{ color: "red" }} />
                )
              }
            />
          }
        </TableCell>
        <TableCell>{`# ${singleProcessedOrder.original_id}`}</TableCell>
        <TableCell>{singleProcessedOrder.customer_name}</TableCell>
        <TableCell>{singleProcessedOrder.customer_surname}</TableCell>
        <TableCell>{singleProcessedOrder.customer_phone_number}</TableCell>
        <TableCell>{singleProcessedOrder.full_adress}</TableCell>
        <TableCell>{formatDate(singleProcessedOrder.ordered_at)}</TableCell>
        <TableCell>{formatDate(singleProcessedOrder.processed_at)}</TableCell>
        <TableCell>
          {translatePaymentType(singleProcessedOrder.payment_type)}
        </TableCell>
        <TableCell>
          {translateDeliveryType(singleProcessedOrder.delivery)}
        </TableCell>
        <TableCell>
          {`${singleProcessedOrder.ordered_products.total_price.toFixed(
            2
          )} rsd`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={11}
          sx={{
            backgroundColor: color.primary,
            padding: "0px",
          }}
        >
          <Collapse in={opened} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: color.primary_medium,
                    }}
                  >
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Ime Proizvoda
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Materijal</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Brilijant</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Boja</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Cena</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Popust</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Cena Na Popustu
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Količina</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Ukupna cena za proizvod
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {singleProcessedOrder.ordered_products.products.map(
                    (singleProduct, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: color.primary,
                          }}
                        >
                          <TableCell>{singleProduct.name}</TableCell>
                          <TableCell>
                            {translateMaterial(singleProduct.material)}
                          </TableCell>
                          <TableCell>
                            {singleProduct.briliant ? "Da" : "Ne"}
                          </TableCell>
                          <TableCell>
                            {translateColor(singleProduct.color)}
                          </TableCell>
                          <TableCell>{`${singleProduct.price.toFixed(
                            2
                          )} rsd`}</TableCell>
                          <TableCell>
                            {singleProduct.discount ? "Da" : "Ne"}
                          </TableCell>
                          <TableCell>
                            {singleProduct.discount_price
                              ? `${singleProduct.discount_price.toFixed(2)} rsd`
                              : "/"}
                          </TableCell>
                          <TableCell>{singleProduct.amount}</TableCell>
                          <TableCell>
                            {`${singleProduct.total_product_price.toFixed(
                              2
                            )} rsd`}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                  <TableRow>
                    <TableCell colSpan={11}>{`Napomena mušterije: ${
                      singleProcessedOrder.note != null
                        ? singleProcessedOrder.note
                        : "Nema napomene"
                    }`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {singleProcessedOrder.payment_type === "card" &&
                        singleProcessedOrder.accepted &&
                        !singleProcessedOrder.refund_status ? (
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#79590E",
                              color: "#FEFEFE",
                              marginLeft: "20px",
                            }}
                            onClick={() =>
                              handleShowRefundDialog(
                                singleProcessedOrder.original_id,
                                `${singleProcessedOrder.ordered_products.total_price.toFixed(
                                  2
                                )} rsd`
                              )
                            }
                          >
                            Refundiraj
                          </Button>
                        ) : (
                          <Typography sx={{ marginLeft: "20px" }}>
                            Ova porudžbina se ne može refundirati
                          </Typography>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* ) : null} */}
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default CustomListRow;
