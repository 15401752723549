import { TableCell } from "@mui/material";
import color from "../../../Constants/colors";

interface CustomTableCellProps {
  text: string;
}

const CustomTableCell = ({ text }: CustomTableCellProps): JSX.Element => {
  return (
    <TableCell
      sx={{
        backgroundColor: color.primary_dark,
        fontWeight: "bold",
      }}
    >
      {text}
    </TableCell>
  );
};

export default CustomTableCell;
