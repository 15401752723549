import { createTheme } from "@mui/material/styles";
import colors from "./colors";

const theme = createTheme({
  typography: {
    fontFamily: ["Merriweather"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0, //For mobile devices (0 < W < 480)
      sm: 481, //iPads and Tablets (480 < W < 768)
      md: 769, //Laptops and small screens (768 < W < 1024)
      lg: 1025, //Large screens and Desktops (1024 < W < 1200)
      xl: 1201, //TV and Extra Large Screens (W > 1200)
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    accent: {
      main: colors.accent,
    },
    error: {
      main: colors.red,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primary,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.accent,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.btn_dark, // default color
          "&.Mui-checked": {
            color: colors.accent, // color when checked
          },
          "&.MuiCheckbox-indeterminate": {
            color: colors.secondary, // color when indeterminate
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.btn_dark, // default color
          "&.Mui-checked": {
            color: colors.accent, // color when checked
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary, // make background to colors.primary
        },
        message: {
          color: colors.btn_dark, // make text to colors.accent
        },
      },
    },
  },
});

export default theme;
