import "../../../Styles/Home.css";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useAllProducts } from "../../../Hooks/useProducts";
import color from "../../../Constants/colors";
import ClientSingleProduct from "../ClientAllProducts/ClientSingleProduct";
import "../../../Styles/ClientProduct.css";
import { Link, useNavigate } from "react-router-dom";
import useScreenSize from "../../../Hooks/Misc/useScreenSize";
import { useSearchContext } from "../../SearchContext/SearchContext";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

const Home = () => {
  const { setSearch } = useSearchContext();
  const navigate = useNavigate();
  const handleProductClick = (id: number) => {
    navigate(`/products/${id}`);
  };
  const phone = useScreenSize();

  const { data } = useAllProducts(
    null,
    null,
    null,
    null,
    null,
    1,
    phone ? 4 : 8,
    null,
    null,
    "",
    null,
    true,
    "",
    ""
  );

  return (
    <>
      <ScrollToTop />
      <Grid container direction="column">
        <Grid item>
          <Grid
            container
            className="main-image"
            direction="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item className="our-story-container">
              <Link to="about" style={{ textDecoration: "none" }}>
                <Typography className="our-story-text">Naša priča</Typography>
              </Link>
            </Grid>
            <Grid item>
              <Grid item className="hand-made-container">
                <Typography className="hand-made">
                  Ručna izrada nakita od zlata i srebra
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction={phone ? "column" : "row"}
                  alignItems="center"
                  spacing={phone ? 3 : 20}
                  className="button-container"
                >
                  <Grid item>
                    <Button
                      className="button-material-1"
                      onClick={() => {
                        navigate("products", {
                          state: {
                            material: "gold",
                            briliant: null,
                            category_id: null,
                            subcategory_id: null,
                            order: "desc",
                            orderBy: "popular",
                          },
                        });
                        sessionStorage.setItem("page", (0).toString());
                        setSearch("");
                      }}
                    >
                      <Typography className="button-text-home">
                        ZLATO
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className="button-material-2"
                      onClick={() => {
                        navigate("products", {
                          state: {
                            material: "silver",
                            briliant: null,
                            category_id: null,
                            subcategory_id: null,
                            order: "desc",
                            orderBy: "popular",
                          },
                        });
                        sessionStorage.setItem("page", (0).toString());
                        setSearch("");
                      }}
                    >
                      <Typography className="button-text-home">
                        SREBRO
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            className="wishes-container"
            direction="column"
          >
            <Grid item>
              <Link to="about" style={{ textDecoration: "none" }}>
                <Typography className="wishes-text">
                  Ispunjavamo vaše želje
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Typography className="wishes-slogan">
                Obeležite posebne trenutke našim nakitom koji se stvara i daruje
                s ljubavlju.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="featured">
          <Grid
            container
            direction={phone ? "column" : "row"}
            className="featured-container"
            justifyContent="space-around"
            alignItems="center"
          >
            {data ? (
              data.products.map((product) => (
                <ClientSingleProduct
                  key={product.id}
                  handleClick={() => handleProductClick(product.id)}
                  imageUrl={product.main_image_name}
                  previewUrl={product.preview_image_name}
                  name={product.product_name}
                  material={product.material}
                  price={product.price}
                  discount={product.discount_flag}
                  discountPrice={product.discount_price}
                  pageName="-home"
                />
              ))
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <CircularProgress sx={{ color: color.accent }} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            className="order-container"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography className="order-text">
                *Prilikom poručivanja nakita imajte u vidu da je za izradu
                potrebno 7 do 10 radnih dana
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="order-text">
                Hvala unapred na strpljenju!
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="order-text">Jewerly Shop Doro</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
