import { useCategories } from "../../../Hooks/useCategories";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
const Categories = () => {
  const { data } = useCategories();
  const navigate = useNavigate();
  if (!data) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  const categories = data.categories;
  return (
    <>
      <h1>Pregled Svih Kategorija</h1>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#CF9B2E" }}>
              <TableCell sx={{ color: "#79590E", fontWeight: "bolder" }}>
                #
              </TableCell>
              <TableCell sx={{ color: "#79590E", fontWeight: "bolder" }}>
                Ime kategorije
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => {
              return (
                <TableRow
                  sx={{ "&:hover": { backgroundColor: "#FFF4E3" } }}
                  key={index}
                  onClick={() => {
                    navigate(`./${category.id}/view`);
                  }}
                >
                  <TableCell>{category.id}</TableCell>
                  <TableCell>{category.category_name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Categories;
