import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../../../Styles/Navbar.css";
import { useLocation } from "react-router-dom";
import MainMenu from "../MainMenu";
import { checkAuth } from "../../../Services/Https/auth";
import Footer from "../Footer";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  InputBase,
  Paper,
  Tooltip,
  Snackbar,
  Slide,
  Backdrop,
  SlideProps,
  Button,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchContext } from "../../SearchContext/SearchContext";
import color from "../../../Constants/colors";
import { CSSTransition } from "react-transition-group";
import useScreenSize from "../../../Hooks/Misc/useScreenSize";
import CartPopup from "../CartPopup";
import CardIcons from "../CardIcons";
import colors from "../../../Constants/colors";
import Informations from "../Informations/Informations";
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create(["margin", "padding"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "padding"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: 0, // Add this line to remove the margin
    padding: 0, // Add this line to remove the padding
  }),
  overflowX: "hidden",
  maxWidth: "100%",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Navbar = () => {
  const navigate = useNavigate();
  const phone = useScreenSize();
  const [openSearch, setOpenSearch] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [auth, setAuth] = useState(false);
  const location = useLocation();
  const { search, setSearch } = useSearchContext();
  // state za cookies Snackbar
  const [cookiesSnackbarOpen, setCookiesSnackbarOpen] = useState(false);

  // tranzicija za dolazak sa donje strane ekrana
  const SlideTransition = (props: JSX.IntrinsicAttributes & SlideProps) => {
    return <Slide {...props} direction="up" />;
  };

  useEffect(() => {
    if (!localStorage.getItem("cookiesSnackbarShown")) {
      setCookiesSnackbarOpen(true);
    }
  }, []);

  const handleCookiesSnackbarClose = () => {
    localStorage.setItem("cookiesSnackbarShown", "true");
    setCookiesSnackbarOpen(false);
  };

  const handleOpenSearch = () => {
    if (!openSearch) {
      //If search is opening close cart and menu
      setOpenCart(false);
      setOpenMenu(false);
    }
    setOpenSearch(!openSearch);
  };

  const handleOpenCart = () => {
    if (!openCart) {
      //If cart is opening close search and menu
      setOpenSearch(false);
      setOpenMenu(false);
    }
    setOpenCart(!openCart);
  };

  const handleOpenMenu = () => {
    if (!openMenu) {
      //If menu is opening close search and cart
      setOpenSearch(false);
      setOpenCart(false);
    }
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const response = await checkAuth();
        setAuth(response.status === 200);
      } catch (error: unknown) {
        setAuth(false);
      }
    };
    fetchAuth();
  }, []);

  const handleClickContact = () => {
    const element = document.getElementById("footer");
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const offset = 0.1;
      window.scrollTo({
        top: absoluteElementTop + offset,
        behavior: "smooth",
      });
    }
  };

  const renderSearchBar = () => {
    return (
      <CSSTransition
        in={openSearch}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="search-bar-container">
          <Grid
            container
            marginBottom={5}
            marginTop={5}
            justifyContent="flex-end"
          >
            <Paper component="form" className="search-bar">
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Pretraži proizvode"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                  sessionStorage.setItem("page", "0");
                  window.dispatchEvent(new Event("pageReset"));
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    if (location.pathname !== "/products") {
                      navigate("/products", {
                        state: {
                          material: null,
                          briliant: null,
                          category_id: null,
                          subcategory_id: null,
                          order: "desc",
                          orderBy: "popular",
                        },
                      });
                    }
                    e.preventDefault();
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => {
                  if (location.pathname !== "/products") {
                    navigate("/products", {
                      state: {
                        material: null,
                        briliant: null,
                        category_id: null,
                        subcategory_id: null,
                        order: "desc",
                        orderBy: "popular",
                      },
                    });
                  }
                }}
              >
                <SearchIcon sx={{ color: color.btn_dark }} />
              </IconButton>
            </Paper>
          </Grid>
        </div>
      </CSSTransition>
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed">
        <Toolbar disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="navbar-container"
          >
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <MainMenu
                    handleMenuOpen={handleOpenMenu}
                    isMenuOpen={openMenu}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center">
                    <Grid item className="line-left" />
                    <Grid
                      item
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("");
                      }}
                    >
                      <Typography className="navbar-title">
                        Jewelry Shop Doro
                      </Typography>
                    </Grid>
                    <Grid item className="line-right" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item className="navbar-icon-container">
                  <IconButton
                    color="inherit"
                    disableRipple
                    onClick={handleOpenSearch}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <img
                          src="/search.png"
                          className="navbar-icon"
                          alt="pretraga"
                        />
                      </Grid>
                      {!phone ? (
                        <Grid item>
                          <Typography className="icon-text">
                            Pretraga
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid item className="navbar-icon-container">
                  <IconButton
                    color="inherit"
                    disableRipple
                    onClick={handleOpenCart}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <img
                          src="/shopping_bag.png"
                          className="navbar-icon"
                          alt="korpa"
                        />
                      </Grid>
                      {!phone ? (
                        <Grid item>
                          <Typography className="icon-text">Korpa</Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </IconButton>
                </Grid>

                <Grid item className="navbar-icon-container">
                  <IconButton
                    color="inherit"
                    disableRipple
                    onClick={handleClickContact}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <img
                          src="/about_us.png"
                          className="navbar-icon"
                          alt="o nama"
                        />
                      </Grid>
                      {!phone ? (
                        <Grid item>
                          <Typography className="icon-text">Kontakt</Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {renderSearchBar()}
        {openCart ? <CartPopup handleOpenCart={handleOpenCart} /> : null}
      </AppBar>
      <Main>
        <DrawerHeader />
        <Outlet />
        <Footer />
        <CardIcons />
        <Informations />
        {auth ? (
          <>
            <Tooltip title="Idi na Admin Panel">
              <IconButton
                sx={{
                  position: "fixed",
                  bottom: "3vh",
                  right: "3vh",
                  zIndex: 1000,
                }}
                onClick={() => {
                  navigate("/admin");
                }}
              >
                <AdminPanelSettingsIcon
                  sx={{ color: "#cd9c32" }}
                  className="admin-float-icon"
                />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
        <Backdrop
          open={cookiesSnackbarOpen}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Snackbar
            open={cookiesSnackbarOpen}
            TransitionComponent={SlideTransition}
            message={
              <Typography align="center">
                U ime Jewelry Shop Doro obavezujemo se da ćemo čuvati privatnost
                svih naših kupaca. Prikupljamo samo neophodne, osnovne podatke o
                kupcima/ korisnicima i podatke neophodne za poslovanje i
                informisanje korisnika u skladu sa dobrim poslovnim običajima i
                u cilju pružanja kvalitetne usluge. Svi podaci o
                korisnicima/kupcima se strogo čuvaju i dostupni su samo
                zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi
                zaposleni Jewelery Shop Doro (i poslovni partneri) odgovorni su
                za poštovanje načela zaštite privatnosti.
              </Typography>
            }
            action={
              <Button
                variant="contained"
                sx={{ color: colors.primary, backgroundColor: colors.btn_dark }}
                size="small"
                onClick={handleCookiesSnackbarClose}
              >
                Prihvatam
              </Button>
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          />
        </Backdrop>
      </Main>
    </Box>
  );
};

export default Navbar;
