import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextareaAutosize,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ISubcategory } from "../../../Model/Category";
import { Delete, Add, TagOutlined } from "@mui/icons-material";
import "../../../Styles/ProductCreate.css";
import { enqueueSnackbar } from "notistack";
import {
  updateProduct,
  updateMainPreviewImage,
  addNewProductImages,
  deleteSingleProductImage,
} from "../../../Services/Https/products";
import { useCategories } from "../../../Hooks/useCategories";
import colors from "../../../Constants/colors";
import { IProductSingle, IProductSingleUpdate } from "../../../Model/Product";

var subcategories: ISubcategory[] = [];

interface ImageFile extends File {
  preview?: string;
}

// var imageGlobal: File | null = null;
// var previewImageGlobal: File | null = null;
// var imagesGlobal: File[] = [];

const MainImageAdd = ({
  initialProduct,
  id,
}: {
  initialProduct: IProductSingle;
  id: string;
}) => {
  // const response = await fetch(imageUrl);
  // const blob = await response.blob();
  // const file = new File([blob], 'image.jpg', { type: blob.type });
  const [image, setImage] = useState<ImageFile | null>(null);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const formData = new FormData();
        formData.append("images", e.target.files[0]);
        formData.append("image_data", JSON.stringify({ image_type: 0 }));
        await updateMainPreviewImage(id, formData);

        const newImage: ImageFile = e.target.files[0];
        setImage(newImage);
        enqueueSnackbar("Uspešno izmenjena glavna slika", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar("Došlo je do greške prilikom izmene glavne slike", {
          variant: "error",
        });
      }
    }
  };

  return (
    <form>
      <Grid container justifyContent="center">
        <Button variant="contained" component="label" sx={{ margin: "5px" }}>
          Izmeni glavnu sliku
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>
        <br />
        <Grid>
          <Grid
            container
            position={"relative"}
            sx={{
              height: "300px",
              width: "500px",
              margin: "5px",
              borderRadius: "10px",
            }}
          >
            <img
              src={
                image
                  ? URL.createObjectURL(image)
                  : `https://jewelryshopdoro.com/uploads/${initialProduct.main_image}`
              }
              style={{
                height: "300px",
                width: "500px",
                borderRadius: "10px",
              }}
              alt="preview"
              className="add-main-image"
            />
          </Grid>
        </Grid>
        <br />
      </Grid>
    </form>
  );
};

const PreviewImageAdd = ({
  initialProduct,
  id,
}: {
  initialProduct: IProductSingle;
  id: string;
}) => {
  const [image, setImage] = useState<ImageFile | null>(null);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const formData = new FormData();
        formData.append("images", e.target.files[0]);
        formData.append("image_data", JSON.stringify({ image_type: 1 }));
        await updateMainPreviewImage(id, formData);

        const newImage: ImageFile = e.target.files[0];
        setImage(newImage);
        enqueueSnackbar("Uspešno izmenjena slika za pregled", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar("Došlo je do greške prilikom izmene slike za pregled", {
          variant: "error",
        });
      }
    }
  };

  return (
    <form>
      <Grid container justifyContent="center">
        <Button variant="contained" component="label" sx={{ margin: "5px" }}>
          Izmeni sliku za pregled
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>
        <br />
        <Grid>
          <Grid
            container
            position={"relative"}
            sx={{
              height: "300px",
              width: "500px",
              margin: "5px",
            }}
          >
            <img
              src={
                image
                  ? URL.createObjectURL(image)
                  : `https://jewelryshopdoro.com/uploads/${initialProduct.preview_image}`
              }
              style={{
                height: "300px",
                width: "500px",
                borderRadius: "10px",
              }}
              alt="preview"
              className="add-main-image"
            />
          </Grid>
        </Grid>
        <br />
      </Grid>
    </form>
  );
};

const ImageAdd = ({
  initialProduct,
  id,
}: {
  initialProduct: IProductSingle;
  id: string;
}) => {
  const [images, setImages] = useState<ImageFile[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const initialImages: ImageFile[] = [];
      for (let img of initialProduct.images) {
        const response = await fetch(
          `https://jewelryshopdoro.com/uploads/${img}`
        );
        const blob = await response.blob();
        const newFile = new File([blob], img, { type: blob.type });
        initialImages.push(
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        );
      }
      setImages(initialImages);
    };
    fetchImages();
  }, [initialProduct.images]);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const formData = new FormData();
        const imageFiles = Array.from(e.target.files);
        for (let img of imageFiles) {
          formData.append("images", img);
        }
        const { image_name } = await addNewProductImages(id, formData);
        enqueueSnackbar("Uspešno dodate nove slike proizvoda", {
          variant: "success",
        });

        const renamedFile = new File([e.target.files[0]], image_name, {
          type: e.target.files[0].type,
        });
        const newImage: ImageFile = Object.assign(renamedFile, {
          preview: URL.createObjectURL(renamedFile),
        });

        // const newImages: ImageFile[] = Array.from(e.target.files).map(
        //   (file: File) =>
        //     Object.assign(file, {
        //       preview: URL.createObjectURL(file),
        //     })
        // );
        setImages([...images, newImage]);
      } catch (err) {
        enqueueSnackbar("Došlo je do greške prilikom dodavanja novih slika", {
          variant: "error",
        });
      }
    }
  };

  const handleImageDelete = async (index: number) => {
    try {
      await deleteSingleProductImage(images[index].name);
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
      enqueueSnackbar("Slika je uspešno obrisana", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Došlo je do greške brisanja slike", {
        variant: "error",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Button variant="contained" component="label" sx={{ margin: "5px" }}>
          Dodaj ostale slike
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>

        <br />
        <Grid
          container
          display={"flex"}
          sx={{
            width: "700px",
            minHeight: "500px",
            margin: "5px",
            border: "5px solid",
            borderColor: colors.primary_medium,
            borderRadius: "10px",
            padding: "5px",
          }}
          justifyContent={"flex-start"}
        >
          {images.length !== 0 ? (
            images.map((image: ImageFile, index: number) => (
              <Grid item key={index}>
                <Grid sx={{ position: "relative" }}>
                  <img
                    style={{
                      width: "160px",
                      height: "140px",
                      margin: "5px",
                      borderRadius: "10px",
                    }}
                    src={image.preview}
                    alt="preview"
                    className="imageProduct"
                  />
                  <Tooltip arrow title="Ukloni sliku" placement="bottom">
                    <IconButton
                      onClick={() => handleImageDelete(index)}
                      sx={{
                        position: "absolute",
                        right: 5,
                        bottom: 5,
                        color: "#FF000080",
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))
          ) : (
            <CircularProgress />
          )}
        </Grid>
        <br />
      </Grid>
    </form>
  );
};

const UpdateProduct = ({
  initialProduct,
  id,
}: {
  initialProduct: IProductSingle;
  id: string;
}) => {
  const [product_name, setName] = useState(initialProduct.product_name);
  const [price, setPrice] = useState(initialProduct.price.toString());
  const [discount_price, setDiscountPrice] = useState(
    initialProduct.discount_price !== null
      ? initialProduct.discount_price.toString()
      : ""
  );
  const [discount_flag, setDiscountFlag] = useState(
    initialProduct.discount_flag
  );
  const [material, setMaterial] = useState<String>(
    initialProduct.material !== null ? initialProduct.material : ""
  );
  const [available_white, setAvailableWhite] = useState(
    initialProduct.available_white
  );
  const [available_yellow, setAvailableYellow] = useState(
    initialProduct.available_yellow
  );
  const [available_pink, setAvailablePink] = useState(
    initialProduct.available_pink
  );
  const [brilliant, setBrilliant] = useState(initialProduct.briliant);
  const [favorite_flag, setFavouriteFlag] = useState(initialProduct.favorite);
  const [popular, setPopular] = useState(initialProduct.popular);
  const [category_key, setCategoryKey] = useState(
    initialProduct.category_id.toString()
  );
  const [subcategory_key, setSubcategoryKey] = useState(
    initialProduct.subcategory_id !== null
      ? initialProduct.subcategory_id.toString()
      : ""
  );
  const [tags, setTags] = useState(
    initialProduct.tags !== null ? initialProduct.tags : []
  );
  const [description, setDescription] = useState(initialProduct.description);

  const [newTag, setNewTag] = useState("");

  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { data: categoriesData } = useCategories();
  const categories = categoriesData?.categories;

  // Set initial subcategories
  const categoryTemp = categories?.find((c) => c.id === parseInt(category_key));
  subcategories = categoryTemp?.subcategories || [];

  const handleUpdateProduct = async () => {
    setIsButtonDisabled(true);

    try {
      if (!product_name || !price || !category_key || !description) {
        enqueueSnackbar("Morate popuniti sva polja", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (isNaN(+price)) {
        enqueueSnackbar("Cena mora biti broj", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (+price <= 0) {
        enqueueSnackbar("Cena mora biti veca od 0 rsd", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (discount_flag) {
        if (isNaN(+discount_price)) {
          enqueueSnackbar("Akcijska cena mora biti broj", { variant: "error" });
          setIsButtonDisabled(false);
          return;
        }

        if (+discount_price <= 0) {
          enqueueSnackbar("Akcijska cena mora biti veca od 0", {
            variant: "error",
          });
          setIsButtonDisabled(false);
          return;
        }

        if (parseFloat(price) < parseFloat(discount_price)) {
          enqueueSnackbar("Akcijska cena mora biti manja od prave cene", {
            variant: "error",
          });
          setIsButtonDisabled(false);
          return;
        }
      }

      const productData: IProductSingleUpdate = {
        product_name: product_name,
        price: parseFloat(price),
        discount_price: discount_flag ? parseFloat(discount_price) : null,
        discount_flag: discount_flag,
        material: material.toString(),
        available_white: available_white,
        available_yellow: available_yellow,
        available_pink: available_pink,
        briliant: brilliant,
        favorite_flag: favorite_flag,
        popular: popular ? 1 : 0,
        category_key: parseInt(category_key),
        subcategory_key:
          subcategory_key !== "" ? parseInt(subcategory_key) : null,
        tags: tags.length > 0 ? JSON.stringify(tags) : null,
        description: description,
      };

      await updateProduct(id, productData);
      enqueueSnackbar("Uspesno ste izmenili proizvod", { variant: "success" });
      navigate(`/admin/products/${id}`); // ovo mozda obrisati
    } catch (err) {
      enqueueSnackbar("Došlo je do greške prilikom izmene proizvoda", {
        variant: "error",
      });
      setIsButtonDisabled(false);
    }
    setIsButtonDisabled(false);
  };

  const handleChangeSubcategory = (event: SelectChangeEvent) => {
    const subcategory = subcategories.find(
      (c) => c.subcategory_id === parseInt(event.target.value)
    );
    setSubcategoryKey(subcategory?.subcategory_id?.toString() || "");
  };

  const handleChange = (event: SelectChangeEvent) => {
    const category = categories?.find(
      (c) => c.id === parseInt(event.target.value)
    );
    setCategoryKey(category!.id.toString());
    setSubcategoryKey("");
    subcategories = category?.subcategories || [];
  };

  const handleAddNewTag = () => {
    if (newTag !== "") {
      setTags((prevTags) => [...prevTags, newTag]);
      setNewTag("");
    } else {
      enqueueSnackbar("Ne možete dodati prazan tag", {
        variant: "error",
      });
    }
  };

  const handleDeleteTag = (tagIndex: number) => {
    setTags((prevTags) => {
      const updatedTags = [...prevTags];
      updatedTags.splice(tagIndex, 1);
      return updatedTags;
    });
  };

  const handleChangeMaterial = (event: SelectChangeEvent) => {
    setMaterial(event.target.value);
  };

  return (
    <Grid item sx={{ width: "500px" }}>
      <Grid container display={"flex"} direction={"column"} flex={1}>
        <Grid container direction={"row"} alignItems={"center"}>
          <TextField
            value={product_name}
            onChange={(e) => setName(e.target.value)}
            sx={{ width: "300px", margin: "10px" }}
            label="Ime proizvoda"
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <TextField
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          sx={{ width: "300px", margin: "10px" }}
          label="Cena"
          inputProps={{
            maxLength: 11,
          }}
        />
        <TextField
          value={discount_price}
          disabled={!discount_flag}
          onChange={(e) => setDiscountPrice(e.target.value)}
          sx={{ width: "300px", margin: "10px" }}
          label="Cena akcije"
          inputProps={{
            maxLength: 11,
          }}
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={discount_flag}
              onChange={(e) => setDiscountFlag(e.target.checked)}
            />
          }
          label="Na akciji"
        />
        <FormControl sx={{ width: "300px", margin: "10px" }}>
          <InputLabel>Materijal</InputLabel>
          <Select
            onChange={handleChangeMaterial}
            value={material.toString()}
            label="Materijal"
          >
            <MenuItem value="gold">Zlato</MenuItem>
            <MenuItem value="silver">Srebro</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={available_white}
              onChange={(e) => setAvailableWhite(e.target.checked)}
            />
          }
          label="Dostupno odmah u beloj boji"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={available_yellow}
              onChange={(e) => setAvailableYellow(e.target.checked)}
            />
          }
          label="Dostupno odmah u žutoj boji"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={available_pink}
              onChange={(e) => setAvailablePink(e.target.checked)}
            />
          }
          label="Dostupno odmah u rozoj boji"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={brilliant}
              onChange={(e) => setBrilliant(e.target.checked)}
            />
          }
          label="Sadrži brilijant"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={favorite_flag}
              onChange={(e) => setFavouriteFlag(e.target.checked)}
            />
          }
          label="Istaknut na glavnoj stranici"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              checked={popular}
              onChange={(e) => {
                setPopular(e.target.checked);
              }}
              sx={{
                color: colors.btn_dark,
                "&:checked": {
                  // ovo kurcu ne radi
                  backgroundColor: colors.btn_dark,
                },
              }}
            />
          }
          label="Popularan proizvod"
        />
        <FormControl sx={{ width: "300px", margin: "10px" }}>
          <InputLabel id="kategorija-label">Kategorija</InputLabel>
          <Select
            labelId="kategorija-label"
            id="kategoriija-select"
            value={category_key}
            label="Kategorija"
            onChange={handleChange}
          >
            {categories?.map((category, index) => {
              return (
                <MenuItem value={category.id} key={index}>
                  {category.category_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "300px", margin: "10px" }}>
          <InputLabel id="potkategorija-label">Potkategorija</InputLabel>
          <Select
            labelId="potkategorija-label"
            id="potkategoriija-select"
            value={subcategory_key}
            label="Potkategorija"
            onChange={handleChangeSubcategory}
          >
            {subcategories.map((subcategory: ISubcategory, index) => {
              return (
                <MenuItem value={subcategory.subcategory_id} key={index}>
                  {subcategory.subcategory_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography sx={{ fontSize: "20px", margin: "10px" }}>
          Tagovi
        </Typography>
        <Grid container sx={{ width: "300px", margin: "10px" }}>
          <Grid item>
            <TextField
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              sx={{ width: "250px" }}
              label="Novi Tag"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Dodaj novi tag" arrow placement="bottom">
              <IconButton
                onClick={() => handleAddNewTag()}
                sx={{
                  color: "black",
                  width: "50px",
                }}
              >
                <Add sx={{ fontSize: "30px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: "300px",
            // minHeight: "400px",
            // border: "1px solid black",
            margin: "10px",
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px",
          }}
        >
          {tags.length !== 0
            ? tags.map((tag: string, index: number) => (
                <Grid item key={index}>
                  <Tooltip title="Ukloni tag" arrow placement="bottom">
                    <Chip
                      onClick={() => {
                        handleDeleteTag(index);
                      }}
                      sx={{ margin: "5px" }}
                      label={tag}
                      icon={<TagOutlined />}
                    />
                  </Tooltip>
                </Grid>
              ))
            : "Nema tagova"}
        </Grid>

        <TextareaAutosize
          style={{
            width: "300px",
            resize: "none",
            margin: "10px",
            fontSize: "18px",
          }}
          placeholder="Opis proizvoda"
          minRows={12}
          maxLength={999}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <Button
          disabled={isButtonDisabled}
          onClick={handleUpdateProduct}
          variant="contained"
          sx={{
            width: "300px",
            margin: "10px",
            backgroundColor: colors.btn_dark,
            color: "white",
          }}
        >
          Izmeni prozivod
        </Button>
      </Grid>
    </Grid>
  );
};

const ProductUpdate = ({
  initialProduct,
  id,
}: {
  initialProduct: IProductSingle;
  id: string;
}) => {
  return (
    <>
      <h1>{`Izmena Proizvoda #${id}`}</h1>
      <Grid display={"flex"} justifyContent={"center"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ maxWidth: "1400px" }}
        >
          <Grid item sx={{ width: "500px" }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems={"center"}
            >
              <MainImageAdd initialProduct={initialProduct} id={id} />
              <hr />
              <PreviewImageAdd initialProduct={initialProduct} id={id} />
              <hr />
              <ImageAdd initialProduct={initialProduct} id={id} />
            </Grid>
          </Grid>
          <Grid item>
            <UpdateProduct initialProduct={initialProduct} id={id} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductUpdate;
