import {
  CircularProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useAllOrders } from "../../../Hooks/useOrders";
import "../../../Styles/ProcessedOrder.css";
import color from "../../../Constants/colors";
import { formatDate } from "../../../Services/Functions/formatDate";
import { useNavigate } from "react-router";
import CustomTableCell from "../SingleActiveOrder/CustomTableCell";

const ActiveOrders = () => {
  const [page, setPage] = useState(0);
  const [perPage, setperPage] = useState(10);
  const navigate = useNavigate();
  let { data } = useAllOrders(page + 1, perPage);

  const handleClick = (id: number) => {
    navigate("./" + id);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeperPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setperPage(+event.target.value);
    setPage(0);
  };

  const translatePaymentType = (paymentTypeString: string) => {
    switch (paymentTypeString) {
      case "cash":
        return "Pouzećem";
      case "card":
        return "Karticom";
      default:
        return "/";
    }
  };

  const translateDeliveryType = (deliveryTypeString: string) => {
    switch (deliveryTypeString) {
      case "store":
        return "U prodavnici";
      case "shipping":
        return "Poštom";
      default:
        return "/";
    }
  };

  if (data && data.orders) {
    const orders = data.orders;
    return (
      <>
        <h1>Pregled svih aktivnih porudžbina</h1>
        <TableContainer>
          <Table stickyHeader aria-label="sticky-label">
            <TableHead>
              <TableRow>
                <CustomTableCell text="Ime Mušterije" />
                <CustomTableCell text="Prezime Mušterije" />
                <CustomTableCell text="Adresa" />
                <CustomTableCell text="Način plaćanja" />
                <CustomTableCell text="Isporuka" />
                <CustomTableCell text="Vreme Naručivanja" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((singleOrder, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={() => handleClick(singleOrder.id)}
                    sx={{
                      "&:hover": {
                        backgroundColor: color.primary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableCell>{singleOrder.customer_name}</TableCell>
                    <TableCell>{singleOrder.customer_surname}</TableCell>
                    <TableCell>{singleOrder.adress}</TableCell>
                    <TableCell>
                      {translatePaymentType(singleOrder.payment_type)}
                    </TableCell>
                    <TableCell>
                      {translateDeliveryType(singleOrder.delivery)}
                    </TableCell>
                    <TableCell>{formatDate(singleOrder.created_at)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 10, 25, 100]}
          component="div"
          count={data.total_orders}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeperPage}
          labelRowsPerPage="Elemenata po stranici"
        />
      </>
    );
  } else {
    return <CircularProgress />;
  }
};

export default ActiveOrders;
