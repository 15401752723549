import { useAllProducts } from "../../../Hooks/useProducts";
import { useCategories } from "../../../Hooks/useCategories";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../../Hooks/Misc/useDebounce";
import { ISubcategory } from "../../../Model/Category";
import color from "../../../Constants/colors";
import React from "react";
import {
  Grid,
  CircularProgress,
  MenuItem,
  Typography,
  Checkbox,
  Menu,
  Button,
  TablePagination,
  Paper,
  InputBase,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import { material } from "../../../Model/Product";
import SingleListProduct from "../SingleListProduct";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import "../../../Styles/ProductsAdmin.css";
type Order = "asc" | "desc" | "";
type OrderBy = "created_at" | "product_name" | "price" | "popular" | "";

const ProductsAdmin = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>("popular");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [discountFlag, setDiscountFlag] = useState<boolean | null>(null);
  const [briliant, setBriliant] = useState<boolean | null>(null);
  const [categoryKeysArray, setCategoryKeysArray] = useState<number[]>([]);
  const [subcategoryKeyArray, setSubcategoryKeysArray] = useState<number[]>([]);
  const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [material, setMaterial] = useState<material>(null);
  const [yellowFlag, setYellowFlag] = useState<boolean | null>(null);
  const [whiteFlag, setWhiteFlag] = useState<boolean | null>(null);
  const [pinkFlag, setPinkFlag] = useState<boolean | null>(null);
  const [favoriteFlag, setFavoriteFlag] = useState<boolean | null>(null);

  const { data } = useAllProducts(
    pinkFlag,
    whiteFlag,
    yellowFlag,
    order,
    orderBy,
    page + 1,
    perPage,
    discountFlag,
    briliant,
    debouncedSearch,
    material,
    favoriteFlag,
    categoryKeysArray.join(","),
    subcategoryKeyArray.join(",")
  );

  const { data: categories } = useCategories();

  //Category dropdown
  const [anchorElCategory, setAnchorElCategory] =
    React.useState<null | HTMLElement>(null);
  const openCategory = Boolean(anchorElCategory);
  const handleClickCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCategory(event.currentTarget);
  };
  const handleCloseCategory = () => {
    setAnchorElCategory(null);
  };

  //Subcategory dropdown
  const [anchorElSubcategory, setanchorElSubcategory] =
    React.useState<null | HTMLElement>(null);
  const openSubcategory = Boolean(anchorElSubcategory);
  const handleClickSubcategory = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setanchorElSubcategory(event.currentTarget);
  };
  const handleCloseSubategory = () => {
    setanchorElSubcategory(null);
  };

  //Colors dropdown
  const [anchorElColors, setanchorElColors] =
    React.useState<null | HTMLElement>(null);
  const openColors = Boolean(anchorElColors);
  const handleClickColors = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElColors(event.currentTarget);
  };
  const handleCloseColors = () => {
    setanchorElColors(null);
  };

  //Materials dropdown
  const [anchorElMaterials, setanchorElMaterials] =
    React.useState<null | HTMLElement>(null);
  const openMaterials = Boolean(anchorElMaterials);
  const handleClickMaterials = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElMaterials(event.currentTarget);
  };
  const handleCloseMaterials = () => {
    setanchorElMaterials(null);
  };

  //Briliant dropdown
  const [anchorElBriliant, setanchorElBriliant] =
    React.useState<null | HTMLElement>(null);
  const openBriliant = Boolean(anchorElBriliant);
  const handleClickBriliant = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElBriliant(event.currentTarget);
  };
  const handleCloseBriliant = () => {
    setanchorElBriliant(null);
  };

  //Sort dropdown
  const [anchorElSort, setanchorElSort] = React.useState<null | HTMLElement>(
    null
  );
  const openSort = Boolean(anchorElSort);
  const handleClickSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElSort(event.currentTarget);
  };
  const handleCloseSort = () => {
    setanchorElSort(null);
  };

  //Pagination
  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeperPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(+event.target.value);
    setPage(0);
  };

  //Remove filters
  const removeFilters = () => {
    setOrder("desc");
    setOrderBy("popular");
    setDiscountFlag(null);
    setBriliant(null);
    setCategoryKeysArray([]);
    setSubcategoryKeysArray([]);
    setSubcategories([]);
    setSearch("");
    setMaterial(null);
    setYellowFlag(null);
    setWhiteFlag(null);
    setPinkFlag(null);
    setFavoriteFlag(null);
  };

  useEffect(() => {
    if (categories) {
      const filteredCategories = categories.categories.filter((category) =>
        categoryKeysArray.includes(category.id)
      );
      const subcategories = filteredCategories.flatMap(
        (category) => category.subcategories
      );

      setSubcategories(subcategories);
    }
  }, [categoryKeysArray, categories]);

  if (!data || !categories) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress sx={{ color: color.accent }} />
      </Grid>
    );
  }

  const category_filters = categories.categories;

  const handleProductClick = (id: number) => {
    navigate(`/admin/products/${id}`);
  };
  const products = data.products;
  //Category checkbox value change
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (isNaN(id)) {
      // value is not a valid number, handle the error as needed
      return;
    }
    setCategoryKeysArray((prevCategoryKeysArray) => {
      const isChecked = prevCategoryKeysArray.includes(id);
      const updatedCategoryKeys = isChecked
        ? prevCategoryKeysArray.filter((categoryId) => categoryId !== id)
        : [...prevCategoryKeysArray, id];
      return updatedCategoryKeys;
    });
  };

  //Subcategory checkbox value change
  const handleSubcategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (isNaN(id)) {
      // value is not a valid number, handle the error as needed
      return;
    }
    setSubcategoryKeysArray((previousSubcategoryArray) => {
      const isChecked = previousSubcategoryArray.includes(id);
      const updatedSubcategoryKeys = isChecked
        ? previousSubcategoryArray.filter(
            (subcategoryId) => subcategoryId !== id
          )
        : [...previousSubcategoryArray, id];
      return updatedSubcategoryKeys;
    });
  };

  //Color checkbox value change
  const handleWhiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setWhiteFlag(true) : setWhiteFlag(null);
  };
  const handleYellowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setYellowFlag(true) : setYellowFlag(null);
  };
  const handlePinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setPinkFlag(true) : setPinkFlag(null);
  };

  //Material checkbox value change
  const handleMaterialChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    switch (id) {
      case 1:
        if (material === "silver") {
          setMaterial(null);
        } else {
          setMaterial("gold");
        }
        break;
      case 2:
        if (material === "silver") {
          setMaterial(null);
        } else {
          setMaterial("silver");
        }
        break;
      case 3:
        setMaterial(null);
        break;
      default:
        break;
    }
  };

  //Briliant checkbox value change
  const handleBriliantChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    switch (id) {
      case 1:
        if (briliant === true) {
          setBriliant(null);
        } else {
          setBriliant(true);
        }
        break;
      case 2:
        if (briliant === false) {
          setBriliant(null);
        } else {
          setBriliant(false);
        }
        break;
      case 3:
        setBriliant(null);
        break;
      default:
        break;
    }
  };

  //Sort value change
  const handleSortChange = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: number
  ) => {
    switch (id) {
      case 1:
        setOrderBy("created_at");
        setOrder("asc");
        break;
      case 2:
        setOrderBy("created_at");
        setOrder("desc");
        break;
      case 3:
        setOrderBy("price");
        setOrder("asc");
        break;
      case 4:
        setOrderBy("price");
        setOrder("desc");
        break;
      case 5:
        setOrderBy("product_name");
        setOrder("asc");
        break;
      case 6:
        setOrderBy("product_name");
        setOrder("desc");
        break;
      case 7:
        setOrderBy("popular");
        setOrder("desc");
        break;
      case 8:
        setOrderBy("popular");
        setOrder("asc");
        break;
      default:
        break;
    }
  };

  //Favorite flag checkbox value change
  const handleFavoriteCHange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setFavoriteFlag(true) : setFavoriteFlag(null);
  };
  //Discount checkbox value change
  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setDiscountFlag(true) : setDiscountFlag(null);
  };
  return (
    <>
      <h1>Pregled Svih Proizvoda</h1>
      <Grid container marginBottom={5} marginTop={5} justifyContent="flex-end">
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Pretraži proizvode"
            inputProps={{ "aria-label": "search" }}
            value={search}
            onChange={(event) => {
              setPage(0);
              setSearch(event.target.value);
            }}
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon sx={{ color: color.btn_dark }} />
          </IconButton>
        </Paper>
      </Grid>
      <Grid container direction="column" justifyContent="center">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: color.primary,
            borderBottom: `2px solid ${color.accent}`,
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "25px",
          }}
        >
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Button
                  id="basic-button"
                  aria-controls={openCategory ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openCategory ? "true" : undefined}
                  onClick={handleClickCategory}
                  style={{
                    color: color.btn_dark,
                    padding: "15px",
                    marginLeft: "10px",
                  }}
                >
                  Kategorije
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElCategory}
                  open={openCategory}
                  onClose={handleCloseCategory}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {category_filters.length ? (
                    category_filters.map((category, index) => (
                      <label htmlFor={`category-checkbox-${index}`}>
                        <MenuItem key={category.id} value={category.id}>
                          <Checkbox
                            id={`category-checkbox-${index}`}
                            checked={categoryKeysArray.includes(category.id)}
                            onChange={(event) => {
                              event.persist();
                              handleCategoryChange(event, category.id);
                            }}
                            style={{ color: color.accent }}
                          />
                          <Typography
                            style={{
                              color: categoryKeysArray.includes(category.id)
                                ? color.accent
                                : color.btn_dark,
                            }}
                          >
                            {category.category_name}
                          </Typography>
                        </MenuItem>
                      </label>
                    ))
                  ) : (
                    <MenuItem style={{ color: color.btn_dark }}>
                      Nema kategorija za prikaz
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
              <Grid item>
                <Button
                  id="basic-button"
                  aria-controls={openSubcategory ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSubcategory ? "true" : undefined}
                  onClick={handleClickSubcategory}
                  style={{
                    color: color.btn_dark,
                    padding: "15px",
                    marginLeft: "10px",
                  }}
                >
                  Potkategorije
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElSubcategory}
                  open={openSubcategory}
                  onClose={handleCloseSubategory}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {subcategories.length ? (
                    subcategories.map((subcategory, index) => (
                      <label
                        htmlFor={`subcategory-checkbox-${index}`}
                        key={index}
                      >
                        <MenuItem
                          key={subcategory.subcategory_id ?? -1}
                          value={subcategory.subcategory_id ?? -1}
                        >
                          <Checkbox
                            id={`subcategory-checkbox-${index}`}
                            checked={subcategoryKeyArray.includes(
                              subcategory.subcategory_id ?? -1
                            )}
                            onChange={(event) => {
                              event.persist();
                              handleSubcategoryChange(
                                event,
                                subcategory.subcategory_id ?? -1
                              );
                            }}
                            style={{ color: color.accent }}
                          />
                          <Typography
                            style={{
                              color: subcategoryKeyArray.includes(
                                subcategory.subcategory_id ?? -1
                              )
                                ? color.accent
                                : color.btn_dark,
                            }}
                          >
                            {subcategory.subcategory_name}
                          </Typography>
                        </MenuItem>
                      </label>
                    ))
                  ) : (
                    <MenuItem style={{ color: color.btn_dark }}>
                      Nema potkategorija za prikaz
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
              <Grid item>
                <Button
                  id="basic-button"
                  aria-controls={openColors ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openColors ? "true" : undefined}
                  onClick={handleClickColors}
                  style={{
                    color: color.btn_dark,
                    padding: "15px",
                    marginLeft: "10px",
                  }}
                >
                  Boje
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElColors}
                  open={openColors}
                  onClose={handleCloseColors}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <label htmlFor="white-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="white-checkbox"
                        checked={whiteFlag ?? false}
                        onChange={(event) => {
                          event.persist();
                          handleWhiteChange(event);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color: whiteFlag ? color.accent : color.btn_dark,
                        }}
                      >
                        Bela
                      </Typography>
                    </MenuItem>
                  </label>
                  <label htmlFor="pink-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="pink-checkbox"
                        checked={pinkFlag ?? false}
                        onChange={(event) => {
                          event.persist();
                          handlePinkChange(event);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color: pinkFlag ? color.accent : color.btn_dark,
                        }}
                      >
                        Roze
                      </Typography>
                    </MenuItem>
                  </label>
                  <label htmlFor="yellow-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="yellow-checkbox"
                        checked={yellowFlag ?? false}
                        onChange={(event) => {
                          event.persist();
                          handleYellowChange(event);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color: yellowFlag ? color.accent : color.btn_dark,
                        }}
                      >
                        Žuta
                      </Typography>
                    </MenuItem>
                  </label>
                </Menu>
              </Grid>
              <Grid item>
                <Button
                  id="basic-button"
                  aria-controls={openMaterials ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMaterials ? "true" : undefined}
                  onClick={handleClickMaterials}
                  style={{
                    color: color.btn_dark,
                    padding: "15px",
                    marginLeft: "10px",
                  }}
                >
                  Materijali
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElMaterials}
                  open={openMaterials}
                  onClose={handleCloseMaterials}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <label htmlFor="gold-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="gold-checkbox"
                        checked={material === "gold"}
                        onChange={(event) => {
                          event.persist();
                          handleMaterialChange(event, 1);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color:
                            material === "gold" ? color.accent : color.btn_dark,
                        }}
                      >
                        Zlato
                      </Typography>
                    </MenuItem>
                  </label>
                  <label htmlFor="silver-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="silver-checkbox"
                        checked={material === "silver"}
                        onChange={(event) => {
                          event.persist();
                          handleMaterialChange(event, 2);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color:
                            material === "silver"
                              ? color.accent
                              : color.btn_dark,
                        }}
                      >
                        Srebro
                      </Typography>
                    </MenuItem>
                  </label>
                  <label htmlFor="all-material-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="all-material-checkbox"
                        checked={!material}
                        onChange={(event) => {
                          event.persist();
                          handleMaterialChange(event, 3);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color: !material ? color.accent : color.btn_dark,
                        }}
                      >
                        Sve
                      </Typography>
                    </MenuItem>
                  </label>
                </Menu>
              </Grid>
              <Grid item>
                <Button
                  id="basic-button"
                  aria-controls={openBriliant ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openBriliant ? "true" : undefined}
                  onClick={handleClickBriliant}
                  style={{
                    color: color.btn_dark,
                    padding: "15px",
                    marginLeft: "10px",
                  }}
                >
                  Brilijant
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElBriliant}
                  open={openBriliant}
                  onClose={handleCloseBriliant}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <label htmlFor="briliant-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="briliant-checkbox"
                        checked={briliant === true}
                        onChange={(event) => {
                          event.persist();
                          handleBriliantChange(event, 1);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color:
                            briliant === true ? color.accent : color.btn_dark,
                        }}
                      >
                        Sa Brilijantom
                      </Typography>
                    </MenuItem>
                  </label>
                  <label htmlFor="without-briliant-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="without-briliant-checkbox"
                        checked={briliant === false}
                        onChange={(event) => {
                          event.persist();
                          handleBriliantChange(event, 2);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color:
                            briliant === false ? color.accent : color.btn_dark,
                        }}
                      >
                        Bez Brilijanta
                      </Typography>
                    </MenuItem>
                  </label>
                  <label htmlFor="all-briliant-checkbox">
                    <MenuItem>
                      <Checkbox
                        id="all-briliant-checkbox"
                        checked={briliant === null}
                        onChange={(event) => {
                          event.persist();
                          handleBriliantChange(event, 3);
                        }}
                        style={{ color: color.accent }}
                      />
                      <Typography
                        style={{
                          color:
                            briliant === null ? color.accent : color.btn_dark,
                        }}
                      >
                        Svi Proizvodi
                      </Typography>
                    </MenuItem>
                  </label>
                </Menu>
              </Grid>
              <Grid item>
                <Tooltip title="Da li su proizvodi na popustu?">
                  <label htmlFor="sale-checkbox">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      style={{ padding: "15px", marginLeft: "10px" }}
                    >
                      <Typography
                        style={{
                          color: discountFlag ? color.accent : color.btn_dark,
                        }}
                      >
                        POPUST
                      </Typography>
                      <Checkbox
                        id="sale-checkbox"
                        checked={discountFlag ? true : false}
                        style={{ color: color.accent }}
                        onChange={(event) => {
                          event.persist();
                          handleDiscountChange(event);
                        }}
                      />
                    </Grid>
                  </label>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Da li su proizvodi istaknuti u izlogu?">
                  <label htmlFor="favorit-checkbox">
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      style={{ padding: "15px", marginLeft: "10px" }}
                    >
                      <Typography
                        style={{
                          color: favoriteFlag ? color.accent : color.btn_dark,
                        }}
                      >
                        ISTAKNUTI
                      </Typography>
                      <Checkbox
                        id="favorit-checkbox"
                        checked={favoriteFlag ? true : false}
                        style={{ color: color.accent }}
                        onChange={(event) => {
                          event.persist();
                          handleFavoriteCHange(event);
                        }}
                      />
                    </Grid>
                  </label>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Tooltip title="Obriši sve filtere">
                  <IconButton onClick={removeFilters}>
                    <RemoveDoneIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Button
                  id="basic-button"
                  aria-controls={openSort ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openSort ? "true" : undefined}
                  onClick={handleClickSort}
                  style={{
                    color: color.btn_dark,
                    padding: "15px",
                    marginRight: "10px",
                  }}
                >
                  <Typography>Sortiraj</Typography>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElSort}
                  open={openSort}
                  onClose={handleCloseSort}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 1);
                    }}
                  >
                    Po datumu uzlazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 2);
                    }}
                  >
                    Po datumu silazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 3);
                    }}
                  >
                    Po ceni uzlazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 4);
                    }}
                  >
                    Po ceni silazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 5);
                    }}
                  >
                    Po imenu uzlazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 6);
                    }}
                  >
                    Po imenu silazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 7);
                    }}
                  >
                    Po popularnosti uzlazno
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={(event) => {
                      event.persist();
                      handleSortChange(event, 8);
                    }}
                  >
                    Po popularnosti silazno
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="productsGrid" padding={15}>
          <Grid container justifyContent={"flex-start"}>
            {products.map((product) => {
              return (
                <SingleListProduct
                  key={product.id}
                  imageUrl={product.main_image_name}
                  name={product.product_name}
                  price={product.price}
                  discountedPrice={product.discount_price}
                  previewUrl={product.preview_image_name}
                  material={product.material}
                  discount={product.discount_flag}
                  handleClick={() => handleProductClick(product.id)}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[1, 10, 25, 100]}
        component="div"
        count={data.total_products}
        rowsPerPage={perPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeperPage}
        labelRowsPerPage="Broj elemenata po stranici"
      />
    </>
  );
};

export default ProductsAdmin;
