import { useNavigate } from "react-router-dom";
import { EventAvailable, EventBusy, PendingActions } from "@mui/icons-material";
import { Grid, Typography, ListItemIcon, Tooltip } from "@mui/material";
import { useDashboardCounts } from "../../../Hooks/useDashboard";

interface IDashboardDataItemProps {
  count: number;
  icon: any;
  text: string;
  tooltipText: string;
  mainColor: string;
  secondaryColor: string;
  path: string;
}

const DashboardDataItem = ({
  count,
  icon,
  text,
  tooltipText,
  mainColor,
  secondaryColor,
  path,
}: IDashboardDataItemProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Tooltip title={tooltipText} arrow placement="bottom">
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "30px",
          width: "400px",
          padding: "30px",
          backgroundColor: "#F0F4F8",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => navigate(path)}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Typography
              sx={{ fontSize: "50px", fontWeight: "bold", color: mainColor }}
            >
              {count !== -1 ? count : "Loading"}
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              backgroundColor: secondaryColor,

              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
            }}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                color: mainColor,
              }}
            >
              {icon}
            </ListItemIcon>
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: "20px" }}>{text}</Typography>
      </Grid>
    </Tooltip>
  );
};

const AdminHome = () => {
  const { data } = useDashboardCounts();
  const navigate = useNavigate();

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>Admin Panel</h1>
      <Grid
        sx={{
          width: "100%",
          marginTop: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <DashboardDataItem
          count={data ? data.active_order_count : -1}
          icon={<PendingActions sx={{ fontSize: "40px" }} />}
          text="Aktivnih porudžbina"
          tooltipText="Pogledaj aktivne porudžbine"
          mainColor="#E9B949"
          secondaryColor="#FCEFC7"
          path="/admin/active-orders"
        />
        <DashboardDataItem
          count={data ? data.accepted_order_count : -1}
          icon={<EventAvailable sx={{ fontSize: "40px" }} />}
          text="Prihvaćenih porudžbina"
          tooltipText="Pogledaj procesuirane porudžbine"
          mainColor="#647ACB"
          secondaryColor="#E0E8F9"
          path="/admin/processed-orders"
        />
        <DashboardDataItem
          count={data ? data.declined_order_count : -1}
          icon={<EventBusy sx={{ fontSize: "40px" }} />}
          text="Odbijenih porudžbina"
          tooltipText="Pogledaj procesuirane porudžbine"
          mainColor="#D66A6A"
          secondaryColor="#FFEEEE"
          path="/admin/processed-orders"
        />
      </Grid>
      <Grid
        item
        sx={{
          marginTop: "50px",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <Tooltip title="Idi na prodavnicu" arrow placement="bottom">
          <img
            src="/doro_logo.png"
            alt="doro logo"
            style={{
              width: "200px",
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default AdminHome;
