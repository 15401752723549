import {
  createCategory,
  editCategory,
} from "../../../Services/Https/categories";
import { useNavigate } from "react-router-dom";
import { ICreateCategory, ISubcategory } from "../../../Model/Category";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Grid, TextField, IconButton, Tooltip, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import {
  createSubcategory,
  editSubcategory,
  deleteSubcategory,
} from "../../../Services/Https/subcategories";
const CreateCategory = ({
  initialCategory,
  isEdit,
  id,
}: {
  initialCategory?: ICreateCategory;
  isEdit: boolean;
  id?: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [category, setCategory] = useState<ICreateCategory>(
    initialCategory ?? {
      category_name: "",
      subcategories: [],
    }
  );
  const [subcategories, setSubcategories] = useState<ISubcategory[]>(
    initialCategory?.subcategories ?? []
  );

  const navigate = useNavigate();
  const handleAddSubcategory = () => {
    const newSubcategory = { subcategory_name: "" };
    setSubcategories((prevSubcategories) => [
      ...prevSubcategories,
      newSubcategory,
    ]);
  };
  const handleRemoveSubcategory = async (
    index: number,
    subcategory_id: number
  ) => {
    if (isEdit && subcategory_id !== -1) {
      try {
        await deleteSubcategory(subcategory_id);
        enqueueSnackbar("Uspesno obrisana potkategorija", {
          variant: "success",
        });
        setSubcategories((prevSubcategories) =>
          prevSubcategories.filter((_, i) => i !== index)
        );
      } catch (error) {
        enqueueSnackbar("Greska pri brisanju potkategorije", {
          variant: "error",
        });
      }
    } else {
      setSubcategories((prevSubcategories) =>
        prevSubcategories.filter((_, i) => i !== index)
      );
    }
  };

  const handleSubcategoryNameChange = (index: number, value: string) => {
    setSubcategories((prevSubcategories) => {
      const newSubcategories = [...prevSubcategories];
      newSubcategories[index] = {
        ...newSubcategories[index],
        subcategory_name: value,
      };
      return newSubcategories;
    });
  };
  const handleSubmit = async () => {
    if (isEdit) {
      try {
        await editCategory({
          category_name: category.category_name,
          category_id: id,
        });
        enqueueSnackbar("Uspesno izmenjeno ime kategorije", {
          variant: "success",
        });
        navigate(`/admin/categories/${id}/view`);
      } catch (error) {
        enqueueSnackbar("Greska pri menjanju imena kategorije", {
          variant: "error",
        });
      }
    } else {
      if (category.category_name.trim() === "") {
        enqueueSnackbar("Ime kategorije ne može biti prazno", {
          variant: "warning",
        });
        return;
      }

      const filteredSubcategories = subcategories.filter(
        (subcategory) => subcategory.subcategory_name.trim() !== ""
      );

      const newCategory: ICreateCategory = {
        category_name: category.category_name.trim(),
        subcategories: filteredSubcategories,
      };
      try {
        const response = await createCategory(newCategory);
        enqueueSnackbar("Uspesno dodata kategorija", { variant: "success" });
        navigate(`/admin/categories/${response.id}/view`);
      } catch (error) {
        enqueueSnackbar("Greska pri dodavanju kategorije", {
          variant: "error",
        });
      }
    }
  };
  const handleEditSubcategory = async (
    subcategory_id: number,
    index: number
  ) => {
    if (subcategory_id === -1) {
      try {
        const response = await createSubcategory({
          subcategory_name: subcategories[index].subcategory_name,
          category_key: id,
        });
        setSubcategories((prevSubcategories) => {
          const newSubcategories = [...prevSubcategories];
          newSubcategories[index] = {
            ...newSubcategories[index],
            subcategory_id: response.id,
          };
          return newSubcategories;
        });
        enqueueSnackbar("Uspesno dodata potkategorija", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Greska pri dodavanju potkategorije", {
          variant: "error",
        });
      }
    } else {
      try {
        await editSubcategory({
          subcategory_name: subcategories[index].subcategory_name,
          subcategory_id: subcategory_id,
        });
        enqueueSnackbar("Uspesno izmenjena potkategorija", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("Greska pri izmeni potkategorije", {
          variant: "error",
        });
      }
    }
  };
  const handleCancel = () => {
    if (id) {
      navigate(`/admin/categories/${id}/view`);
    } else {
      navigate(`/admin`);
    }
  };
  return (
    <div style={{ maxWidth: "60em", marginLeft: "auto", marginRight: "auto" }}>
      <h1>{isEdit ? "Izmeni kategoriju" : "Kreiraj kategoriju"}</h1>
      <Grid container direction="column">
        <Grid container>
          <Grid item md={6} xs={12}>
            <h2>Ime kategorije</h2>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 50 }}
              value={category.category_name}
              onChange={(e) =>
                setCategory({
                  ...category,
                  category_name: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <h2>Potkategorije</h2>
          <Tooltip title="Dodaj novu potkategoriju">
            <IconButton
              sx={{ marginLeft: 2 }}
              disableRipple
              onClick={handleAddSubcategory}
            >
              <AddCircleIcon sx={{ color: "#79590E" }} />
            </IconButton>
          </Tooltip>
        </Grid>
        {subcategories.map((subcategory, index) => {
          return (
            <Grid container padding={3} key={index} alignItems="center">
              <Grid item md={3} xs={6}>
                <h3>{index + 1}. Ime potkategorije: </h3>
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  value={subcategory.subcategory_name}
                  onChange={(e) =>
                    handleSubcategoryNameChange(index, e.target.value)
                  }
                />
              </Grid>
              <Grid item marginLeft={3}>
                <IconButton
                  onClick={() => {
                    handleRemoveSubcategory(
                      index,
                      subcategory.subcategory_id ?? -1
                    );
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              {isEdit ? (
                <Grid item marginLeft={3}>
                  <IconButton
                    onClick={() => {
                      handleEditSubcategory(
                        subcategory.subcategory_id ?? -1,
                        index
                      );
                    }}
                  >
                    <SaveAsIcon sx={{ color: "#79590E" }} />
                  </IconButton>
                </Grid>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        padding={10}
        spacing={15}
      >
        <Grid item>
          <Button
            variant="contained"
            style={{ background: "#79590E", color: "#FEFEFE" }}
            onClick={handleSubmit}
          >
            {isEdit ? "Izmeni ime kategorije" : "Dodaj kategoriju"}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={handleCancel}>
            {isEdit ? "Nazad" : "Odustani"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateCategory;
