import { Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSwipeable } from "react-swipeable";
import "../../../Styles/ImageSwitcher.css";
const ImageSwitcher = ({
  images,
  handleDialogOpen,
}: {
  images: string[];
  handleDialogOpen: (newImage: string) => void;
}) => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    setCurrentImage(images[currentImageIndex]);
  }, [images, currentImageIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextImage(),
    onSwipedRight: () => handlePreviousImage(),
    delta: 10, //Pixels swipped to triger action
    trackMouse: false,
  });

  const [_error, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };

  const handlePreviousImage = () => {
    let newImage = "";
    let newImageIndex = 0;
    if (currentImageIndex === 0) {
      newImageIndex = images.length - 1;
    } else {
      newImageIndex = currentImageIndex - 1;
    }
    newImage = images[newImageIndex];
    setCurrentImage(newImage);
    setCurrentImageIndex(newImageIndex);
  };

  const handleNextImage = () => {
    let newImage = "";
    let newImageIndex = 0;
    if (currentImageIndex === images.length - 1) {
      newImageIndex = 0;
    } else {
      newImageIndex = currentImageIndex + 1;
    }
    newImage = images[newImageIndex];
    setCurrentImage(newImage);
    setCurrentImageIndex(newImageIndex);
  };

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item onClick={() => handleDialogOpen(currentImage)} {...handlers}>
          <Grid
            container
            direction="row"
            className="main-image-product"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "relative",
              cursor: "pointer",
            }}
          >
            <img
              src={
                _error
                  ? "/no-image.jpg"
                  : `https://jewelryshopdoro.com/uploads/${currentImage}`
              }
              alt="Product"
              style={{
                width: "100%", // This will make the image cover the whole grid container
                height: "100%",
                objectFit: "cover", // This will maintain the aspect ratio of the image
                position: "absolute", // This will position the image behind the arrow icons
                zIndex: -1, // This will position the image behind the arrow icons
              }}
              onError={handleError}
            />
            <Grid item>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handlePreviousImage();
                }}
                disableRipple
              >
                <ArrowBackIosIcon className="arrow-back" />
              </IconButton>
            </Grid>
            <Grid item className="arrow-front-container">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleNextImage();
                }}
                disableRipple
              >
                <ArrowForwardIosIcon className="arrow-front" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignSelf="flex-start" className="small-images-row">
          <Grid container direction="row">
            {images.map((image, index) => (
              <Grid
                item
                key={index}
                onClick={() => {
                  setCurrentImage(image);
                }}
              >
                <img
                  className="small-image-product"
                  style={{
                    objectFit: "cover",
                    border:
                      currentImage === image
                        ? "3px solid #CF9B2E"
                        : "0px solid #CF9B2E",
                    cursor: "pointer",
                    filter:
                      currentImage !== image
                        ? "blur(1px) brightness(50%)"
                        : "none",
                  }}
                  alt="small"
                  src={`https://jewelryshopdoro.com/uploads/${image}`}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ImageSwitcher;
