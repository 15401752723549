import { useEffect, useState } from "react";

const useScreenSize = (width: string = "650") => {
  const [isPhone, setIsPhone] = useState(false);

  useEffect(() => {
    const handleResize = (event: MediaQueryListEvent) => {
      setIsPhone(event.matches);
    };

    const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
    setIsPhone(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, [width]);

  return isPhone;
};

export default useScreenSize;
