import {
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAllProcessedOrders } from "../../../Hooks/useOrders";
import { refundOrder } from "../../../Services/Https/payment";
import "../../../Styles/ProcessedOrder.css";
import CustomListRow from "./CustomListRow";
import CustomTableCell from "../SingleActiveOrder/CustomTableCell";
import ConfirmRefundDialog from "./ConfirmRefundDialog";
import { useSnackbar } from "notistack";

const ProcessedOrders = () => {
  const [page, setPage] = useState(0);
  const [perPage, setperPage] = useState(10);
  let { data, refetch } = useAllProcessedOrders(page + 1, perPage);
  const [opened, setOpened] = useState(
    Array(data?.processed_orders?.length || 0).fill(false)
  );
  const [refundDialogShown, setRefundDialogShown] = useState(false);
  const [dialogBtnDisabled, setDialogBtnDisabled] = useState(true);
  const [refundOid, setRefundOid] = useState<string | null>(null);
  const [refundPrice, setRefundPrice] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const expectedConfirmText = "Prihvatam da povratim sredstva";
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (position: number) => {
    setOpened((prevOpened) => {
      const newOpened = [...prevOpened];
      newOpened[position] = !prevOpened[position];
      return newOpened;
    });
  };

  const handleShowRefundDialog = (
    newRefundOid: string | null,
    newRefundPrice: string | null
  ) => {
    setRefundOid(newRefundOid);
    setRefundPrice(newRefundPrice);
    setDialogBtnDisabled(true);
    setRefundDialogShown(!refundDialogShown);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeperPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setperPage(+event.target.value);
    setPage(0);
  };

  const handleDialogConfirmTextChange = (value: string) => {
    // setDialogConfirmText(value);
    if (value === expectedConfirmText) {
      setDialogBtnDisabled(false);
    } else {
      setDialogBtnDisabled(true);
    }
  };

  const submitRefund = async () => {
    setIsBusy(true);
    if (refundOid === null) {
      setIsBusy(false);
      return;
    }
    try {
      handleShowRefundDialog(null, null);
      await refundOrder(refundOid);
      setIsBusy(false);
      refetch();
      enqueueSnackbar("Porudžbina uspešno refundirana", {
        variant: "success",
      });
    } catch (error) {
      setIsBusy(false);
      handleShowRefundDialog(null, null);
      enqueueSnackbar("Greška pri refundiranju porudžbine", {
        variant: "error",
      });
    }
  };

  if (data && data.processed_orders) {
    const processed_orders = data.processed_orders;
    return (
      <>
        {refundDialogShown
          ? ConfirmRefundDialog(
              refundOid,
              refundPrice,
              dialogBtnDisabled,
              handleShowRefundDialog,
              handleDialogConfirmTextChange,
              submitRefund
            )
          : null}
        {isBusy ? (
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0%",
              left: "0%",
              backgroundColor: "rgb(255, 244, 227)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              zIndex: "11111",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "36px",
                color: "#79590E",
              }}
            >
              Obrada zahteva
            </Typography>
            <CircularProgress sx={{ color: "#79590E" }} />
          </div>
        ) : null}
        <h1>Pregled svih obrađenih porudžbina</h1>
        <TableContainer>
          <Table stickyHeader aria-label="sticky-label">
            <TableHead>
              <TableRow>
                <CustomTableCell text={"Prihvaćena"} />
                <CustomTableCell text={"Identifikator"} />
                <CustomTableCell text={"Ime Mušterije"} />
                <CustomTableCell text={"Prezime Mušterije"} />
                <CustomTableCell text={"Broj Telefona"} />
                <CustomTableCell text={"Puna Adresa"} />
                <CustomTableCell text={"Vreme Poručivanja"} />
                <CustomTableCell text={"Vreme Obrade"} />
                <CustomTableCell text={"Način plaćanja"} />
                <CustomTableCell text={"Isporuka"} />
                <CustomTableCell text={"Ukupna cena"} />
              </TableRow>
            </TableHead>
            {processed_orders.map((singleProcessedOrder, index) => {
              const key = index;
              return CustomListRow(
                key,
                singleProcessedOrder,
                opened[index],
                () => handleClick(index),
                handleShowRefundDialog
              );
            })}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 10, 25, 100]}
          component="div"
          count={data.total_orders}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeperPage}
          labelRowsPerPage="Elemenata po stranici"
        />
      </>
    );
  } else {
    return <CircularProgress />;
  }
};

export default ProcessedOrders;
