import {
  IProcessedOrders,
  IOrders,
  ISingleOrder,
  IPlaceOrder,
} from "../../Model/Order";
import api from "../../api";

export const getAllProcessedOrders = async (
  page: number,
  perPage: number
): Promise<IProcessedOrders> => {
  const response = await api.get(`/admin/processed-order`, {
    params: { page, perPage },
  });
  const data = response.data;
  return data;
};

export const getAllOrders = async (
  page: number,
  perPage: number
): Promise<IOrders> => {
  const response = await api.get(`/admin/order`, {
    params: { page, perPage },
  });
  const orders = response.data;
  return orders;
};

export const getSingleOrder = async (id: string): Promise<ISingleOrder> => {
  const response = await api.get(`/admin/order/` + id);
  const order = response.data;
  return order;
};

export const acceptOrder = async (id: string) => {
  const response = await api.post(`/admin/order/` + id, {
    accepted: true,
  });
  return response.data;
};

export const declineOrder = async (id: string, customMessage: string) => {
  const response = await api.post(`/admin/order/` + id, {
    accepted: false,
    customMessage,
  });
  return response.data;
};

export const placeOrder = async (orderInformation: IPlaceOrder) => {
  const response = await api.post("/order", orderInformation);
  return response.data;
};
