import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextareaAutosize,
  Chip,
  Typography,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ISubcategory } from "../../../Model/Category";
import { Delete, Add, TagOutlined } from "@mui/icons-material";
import "../../../Styles/ProductCreate.css";
import { enqueueSnackbar } from "notistack";
import { createProduct } from "../../../Services/Https/products";
import { useCategories } from "../../../Hooks/useCategories";
import colors from "../../../Constants/colors";

var subcategories: ISubcategory[] = [];

interface ImageFile extends File {
  preview?: string;
}

var imageGlobal: File | null = null;
var previewImageGlobal: File | null = null;
var imagesGlobal: File[] = [];

const MainImageAdd = () => {
  const [image, setImage] = useState<ImageFile | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImage: ImageFile = e.target.files[0];
      setImage(newImage);
      imageGlobal = newImage;
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    imageGlobal = null;
  };
  return (
    <form>
      <Grid container justifyContent="center">
        <Button variant="contained" component="label" sx={{ margin: "5px" }}>
          Dodaj glavnu sliku
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>
        <br />
        <Grid>
          {image ? (
            <Grid
              container
              position={"relative"}
              sx={{
                height: "300px",
                width: "500px",
                margin: "5px",
                borderRadius: "10px",
              }}
            >
              <img
                src={URL.createObjectURL(image)}
                style={{
                  height: "300px",
                  width: "500px",
                  borderRadius: "10px",
                }}
                alt="preview"
                className="add-main-image"
              />

              <IconButton
                onClick={() => handleImageDelete()}
                sx={{
                  position: "absolute",
                  right: 5,
                  bottom: 5,
                  fontSize: "200px",
                  color: "black",
                }}
              >
                <Delete sx={{ fontSize: "50px" }} />
              </IconButton>
            </Grid>
          ) : (
            <Container
              sx={{
                height: "300px",
                width: "500px",
                margin: "5px",
                border: "5px solid",
                borderColor: colors.primary_medium,
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                position: "relative",
              }}
            >
              Glavna slika nije odabrana
            </Container>
          )}
        </Grid>
        <br />
      </Grid>
    </form>
  );
};

const PreviewImageAdd = () => {
  const [image, setImage] = useState<ImageFile | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImage: ImageFile = e.target.files[0];
      setImage(newImage);
      previewImageGlobal = newImage;
    }
  };

  const handleImageDelete = () => {
    setImage(null);
    previewImageGlobal = null;
  };
  return (
    <form>
      <Grid container justifyContent="center">
        <Button variant="contained" component="label" sx={{ margin: "5px" }}>
          Dodaj sliku za pregled
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>
        <br />
        <Grid>
          {image ? (
            <Grid
              container
              position={"relative"}
              sx={{
                height: "300px",
                width: "500px",
                margin: "5px",
              }}
            >
              <img
                src={URL.createObjectURL(image)}
                style={{
                  height: "300px",
                  width: "500px",
                  borderRadius: "10px",
                }}
                alt="preview"
                className="add-main-image"
              />

              <IconButton
                onClick={() => handleImageDelete()}
                sx={{
                  position: "absolute",
                  right: 5,
                  bottom: 5,
                  fontSize: "200px",
                  color: "black",
                }}
              >
                <Delete sx={{ fontSize: "50px" }} />
              </IconButton>
            </Grid>
          ) : (
            <Container
              sx={{
                height: "300px",
                width: "500px",
                margin: "5px",
                border: "5px solid",
                borderRadius: "10px",
                borderColor: colors.primary_medium,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                position: "relative",
              }}
            >
              Slika za pregled nije odabrana
            </Container>
          )}
        </Grid>
        <br />
      </Grid>
    </form>
  );
};

const ImageAdd = () => {
  const [images, setImages] = useState<ImageFile[]>([]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImages: ImageFile[] = Array.from(e.target.files).map(
        (file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
      );
      setImages([...images, ...newImages]);
      imagesGlobal = [...images, ...newImages];
    }
  };

  const handleImageDelete = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    imagesGlobal = newImages;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Button variant="contained" component="label" sx={{ margin: "5px" }}>
          Dodaj ostale slike
          <input
            hidden
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </Button>

        <br />
        <Grid
          container
          display={"flex"}
          sx={{
            width: "700px",
            minHeight: "500px",
            margin: "5px",
            border: "5px solid",
            borderColor: colors.primary_medium,
            borderRadius: "10px",
            padding: "5px",
          }}
          justifyContent={"flex-start"}
        >
          {images.length !== 0 ? (
            images.map((image: ImageFile, index: number) => (
              <Grid item key={index}>
                <Grid sx={{ position: "relative" }}>
                  <img
                    style={{
                      width: "160px",
                      height: "140px",
                      margin: "5px",
                      borderRadius: "10px",
                    }}
                    src={image.preview}
                    alt="preview"
                    className="imageProduct"
                  />
                  <Tooltip arrow title="Ukloni sliku" placement="bottom">
                    <IconButton
                      onClick={() => handleImageDelete(index)}
                      sx={{
                        position: "absolute",
                        right: 5,
                        bottom: 5,
                        color: "#FF000080",
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))
          ) : (
            <Container>Ostale slike nisu odabrane</Container>
          )}
        </Grid>
        <br />
      </Grid>
    </form>
  );
};

const AddProduct = () => {
  const [product_name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");
  const [discount_flag, setDiscountFlag] = useState(false);
  const [material, setMaterial] = useState("");
  const [available_white, setAvailableWhite] = useState(false);
  const [available_yellow, setAvailableYellow] = useState(false);
  const [available_pink, setAvailablePink] = useState(false);
  const [brilliant, setBrilliant] = useState(false);
  const [favorite_flag, setFavouriteFlag] = useState(false);
  const [popular, setPopular] = useState(false);
  const [category_key, setCategoryKey] = useState("");
  const [subcategory_key, setSubcategoryKey] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [description, setDescription] = useState("");

  const [newTag, setNewTag] = useState("");

  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { data } = useCategories();
  const categories = data?.categories;

  const handleCreateNewProduct = async () => {
    setIsButtonDisabled(true);

    try {
      if (
        !product_name ||
        !price ||
        material === "" ||
        !category_key ||
        !description
      ) {
        enqueueSnackbar("Morate popuniti sva polja", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (imageGlobal === null) {
        enqueueSnackbar("Morate dodati glavnu sliku", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (previewImageGlobal === null) {
        enqueueSnackbar("Morate dodati sliku za prikaz", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (isNaN(+price)) {
        enqueueSnackbar("Cena mora biti broj", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (+price <= 0) {
        enqueueSnackbar("Cena mora biti veca od 0 rsd", { variant: "error" });
        setIsButtonDisabled(false);
        return;
      }
      if (discount_flag) {
        if (isNaN(+discount_price)) {
          enqueueSnackbar("Akcijska cena mora biti broj", { variant: "error" });
          setIsButtonDisabled(false);
          return;
        }

        if (+discount_price <= 0) {
          enqueueSnackbar("Akcijska cena mora biti veca od 0", {
            variant: "error",
          });
          setIsButtonDisabled(false);
          return;
        }

        if (parseFloat(price) < parseFloat(discount_price)) {
          enqueueSnackbar("Akcijska cena mora biti manja od prave cene", {
            variant: "error",
          });
          setIsButtonDisabled(false);
          return;
        }
      }

      const formData = new FormData();
      formData.append(`images`, imageGlobal);
      formData.append(`images`, previewImageGlobal);
      imagesGlobal.forEach((img) => {
        formData.append(`images`, img);
      });
      const productData = {
        product_name: product_name,
        price: parseFloat(price),
        discount_price: discount_flag ? parseFloat(discount_price) : null,
        discount_flag: discount_flag,
        material: material,
        available_white: available_white,
        available_yellow: available_yellow,
        available_pink: available_pink,
        briliant: brilliant,
        favorite_flag: favorite_flag,
        popular: popular ? 1 : 0,
        category_key: parseInt(category_key),
        subcategory_key:
          subcategory_key !== "" ? parseInt(subcategory_key) : null,
        tags: tags.length > 0 ? JSON.stringify(tags) : null,
        description: description,
      };

      formData.append("product", JSON.stringify(productData));
      const productId = await createProduct(formData);
      enqueueSnackbar("Uspesno ste dodali proizvod", { variant: "success" });
      navigate(`/admin/products/${productId}`);
    } catch (err) {
      enqueueSnackbar("Došlo je do greške prilikom kreiranja proizvoda", {
        variant: "error",
      });
      setIsButtonDisabled(false);
    }
    setIsButtonDisabled(false);
  };

  const handleChangeSubcategory = (event: SelectChangeEvent) => {
    const subcategory = subcategories.find(
      (c) => c.subcategory_id === parseInt(event.target.value)
    );
    setSubcategoryKey(subcategory?.subcategory_id?.toString() || "");
  };

  const handleChange = (event: SelectChangeEvent) => {
    const category = categories?.find(
      (c) => c.id === parseInt(event.target.value)
    );
    setCategoryKey(category!.id.toString());
    setSubcategoryKey("");
    subcategories = category?.subcategories || [];
  };

  const handleAddNewTag = () => {
    if (newTag !== "") {
      setTags((prevTags) => [...prevTags, newTag]);
      setNewTag("");
    } else {
      enqueueSnackbar("Ne možete dodati prazan tag", {
        variant: "error",
      });
    }
  };

  const handleDeleteTag = (tagIndex: number) => {
    setTags((prevTags) => {
      const updatedTags = [...prevTags];
      updatedTags.splice(tagIndex, 1);
      return updatedTags;
    });
  };

  const handleChangeMaterial = (event: SelectChangeEvent) => {
    setMaterial(event.target.value);
  };

  return (
    <Grid item sx={{ width: "500px" }}>
      <Grid container display={"flex"} direction={"column"} flex={1}>
        <Grid container direction={"row"} alignItems={"center"}>
          <TextField
            onChange={(e) => setName(e.target.value)}
            sx={{ width: "300px", margin: "10px" }}
            label="Ime proizvoda"
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <TextField
          onChange={(e) => setPrice(e.target.value)}
          sx={{ width: "300px", margin: "10px" }}
          label="Cena"
          inputProps={{
            maxLength: 11,
          }}
        />
        <TextField
          disabled={!discount_flag}
          onChange={(e) => setDiscountPrice(e.target.value)}
          sx={{ width: "300px", margin: "10px" }}
          label="Cena akcije"
          inputProps={{
            maxLength: 11,
          }}
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox onChange={(e) => setDiscountFlag(e.target.checked)} />
          }
          label="Na akciji"
        />
        <FormControl sx={{ width: "300px", margin: "10px" }}>
          <InputLabel>Materijal</InputLabel>
          <Select
            onChange={handleChangeMaterial}
            value={material}
            label="Materijal"
          >
            <MenuItem value="gold">Zlato</MenuItem>
            <MenuItem value="silver">Srebro</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox onChange={(e) => setAvailableWhite(e.target.checked)} />
          }
          label="Dostupno odmah u beloj boji"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox onChange={(e) => setAvailableYellow(e.target.checked)} />
          }
          label="Dostupno odmah u žutoj boji"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox onChange={(e) => setAvailablePink(e.target.checked)} />
          }
          label="Dostupno odmah u rozoj boji"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox onChange={(e) => setBrilliant(e.target.checked)} />
          }
          label="Sadrži brilijant"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox onChange={(e) => setFavouriteFlag(e.target.checked)} />
          }
          label="Istaknut na glavnoj stranici"
        />
        <FormControlLabel
          sx={{ marginLeft: "3px" }}
          control={
            <Checkbox
              onChange={(e) => {
                setPopular(e.target.checked);
              }}
              sx={{
                color: colors.btn_dark,
                "&:checked": {
                  // ovo kurcu ne radi
                  backgroundColor: colors.btn_dark,
                },
              }}
            />
          }
          label="Popularan proizvod"
        />
        <FormControl sx={{ width: "300px", margin: "10px" }}>
          <InputLabel id="kategorija-label">Kategorija</InputLabel>
          <Select
            labelId="kategorija-label"
            id="kategoriija-select"
            value={category_key}
            label="Kategorija"
            onChange={handleChange}
          >
            {categories?.map((category, index) => {
              return (
                <MenuItem value={category.id} key={index}>
                  {category.category_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "300px", margin: "10px" }}>
          <InputLabel id="potkategorija-label">Potkategorija</InputLabel>
          <Select
            labelId="potkategorija-label"
            id="potkategoriija-select"
            value={subcategory_key}
            label="Potkategorija"
            onChange={handleChangeSubcategory}
          >
            {subcategories.map((subcategory: ISubcategory, index) => {
              return (
                <MenuItem value={subcategory.subcategory_id} key={index}>
                  {subcategory.subcategory_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography sx={{ fontSize: "20px", margin: "10px" }}>
          Tagovi
        </Typography>
        <Grid container sx={{ width: "300px", margin: "10px" }}>
          <Grid item>
            <TextField
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              sx={{ width: "250px" }}
              label="Novi Tag"
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Dodaj novi tag" arrow placement="bottom">
              <IconButton
                onClick={() => handleAddNewTag()}
                sx={{
                  color: "black",
                  width: "50px",
                }}
              >
                <Add sx={{ fontSize: "30px" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: "300px",
            // minHeight: "400px",
            // border: "1px solid black",
            margin: "10px",
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px",
          }}
        >
          {tags.length !== 0
            ? tags.map((tag: string, index: number) => (
                <Grid item key={index}>
                  <Tooltip title="Ukloni tag" arrow placement="bottom">
                    <Chip
                      onClick={() => {
                        handleDeleteTag(index);
                      }}
                      sx={{ margin: "5px" }}
                      label={tag}
                      icon={<TagOutlined />}
                    />
                  </Tooltip>
                </Grid>
              ))
            : "Nema tagova"}
        </Grid>

        <TextareaAutosize
          style={{
            width: "300px",
            resize: "none",
            margin: "10px",
            fontSize: "18px",
          }}
          placeholder="Opis proizvoda"
          minRows={12}
          maxLength={999}
          onChange={(event) => setDescription(event.target.value)}
        />
        <Button
          disabled={isButtonDisabled}
          onClick={handleCreateNewProduct}
          variant="contained"
          sx={{
            width: "300px",
            margin: "10px",
            backgroundColor: colors.btn_dark,
            color: "white",
          }}
        >
          Dodaj prozivod
        </Button>
      </Grid>
    </Grid>
  );
};

const ProductCreate = () => {
  return (
    <>
      <h1>Kreiranje novog proizvoda</h1>
      <Grid display={"flex"} justifyContent={"center"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ maxWidth: "1400px" }}
        >
          <Grid item sx={{ width: "500px" }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems={"center"}
            >
              <MainImageAdd />
              <hr />
              <PreviewImageAdd />
              <hr />
              <ImageAdd />
            </Grid>
          </Grid>
          <Grid item>
            <AddProduct />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductCreate;
