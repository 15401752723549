import { Grid, Typography } from "@mui/material";
import "../../../Styles/ProductsAdmin.css";

import { useState } from "react";
import { material } from "../../../Model/Product";

interface ProductProps {
  handleClick: () => void;
  imageUrl: string;
  previewUrl: string;
  name: string;
  material: material;
  price: number;
  discountedPrice: number;
  discount: boolean;
}

const SingleListProduct = ({
  handleClick,
  imageUrl,
  name,
  price,
  previewUrl,
  material,
  discountedPrice,
  discount,
}: ProductProps) => {
  const [_error, setError] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);

  const handleError = () => {
    setError(true);
  };

  const translateMaterial = (materialString: material) => {
    switch (materialString) {
      case "gold":
        return "zlato";
      case "silver":
        return "srebro";
      default:
        return "";
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLImageElement>) => {
    setCurrentImageUrl(previewUrl);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLImageElement>) => {
    setCurrentImageUrl(imageUrl);
  };

  return (
    <Grid className="card" style={{ cursor: "pointer" }}>
      <Grid item>
        <img
          onClick={handleClick}
          alt="Slika proizvoda"
          src={
            _error
              ? "/no-image.jpg"
              : `https://jewelryshopdoro.com/uploads/${currentImageUrl}`
          }
          onError={handleError}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={"imageProduct"}
        />
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <Typography marginLeft="5px" className="product-text">
            {name} - {translateMaterial(material)}
          </Typography>
        </Grid>
        <Grid item>
          {discount ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              marginLeft="5px"
            >
              <Typography className="sale">{price} RSD</Typography>
              <Typography marginLeft="2px" className="product-text">
                {discountedPrice} RSD
              </Typography>
            </Grid>
          ) : (
            <Typography className="product-text" marginLeft="5px">
              {price} RSD
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleListProduct;
