import { useQuery } from "react-query";
import {
  getAllProcessedOrders,
  getAllOrders,
  getSingleOrder,
} from "../Services/Https/orders";

export const useAllProcessedOrders = (page: number, perPage: number) => {
  return useQuery(
    ["orders", page, perPage],
    () => getAllProcessedOrders(page, perPage),
    { keepPreviousData: true }
  );
};

export const useAllOrders = (page: number, perPage: number) => {
  return useQuery(
    ["orders", page, perPage],
    () => getAllOrders(page, perPage),
    { keepPreviousData: true }
  );
};

export const useSingleOrder = (id: string) => {
  return useQuery(["order", id], () => getSingleOrder(id));
};
