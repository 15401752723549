import { changePw } from "../../../Services/Https/auth";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import color from "../../../Constants/colors";
import { useNavigate } from "react-router-dom";
const ChangePw = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassowrd, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const navigate = useNavigate();
  const handleOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmNewPassword(event.target.value);
  };

  const changePassword = async () => {
    setIsChanging(true);
    if (newPassword !== confirmNewPassowrd) {
      enqueueSnackbar("Nova šifra se ne podudara", { variant: "warning" });
      return;
    }
    try {
      await changePw(oldPassword, newPassword);
      enqueueSnackbar("Šifra uspešno promenjena", { variant: "success" });
      navigate("/admin");
    } catch (error) {
      enqueueSnackbar("Greška pri promeni šifre, proverite staru šifru", {
        variant: "error",
      });
    }
    setIsChanging(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h5" gutterBottom color={color.btn_dark}>
          Promeni Šifru
        </Typography>
        <Box
          component="form"
          sx={{
            width: "100%",
            mt: 1,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="oldPassword"
            label="Stara šifra"
            name="oldPassword"
            autoComplete="old-password"
            type={showPassword ? "text" : "password"}
            autoFocus
            onChange={handleOldPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="Nova Šifra"
            type={showNewPassword ? "text" : "password"}
            id="newPassword"
            autoComplete="new-password"
            onChange={handleNewPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowNewPassword} edge="end">
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Potvrdi Novu Šifru"
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            autoComplete="new-password"
            onChange={handleConfirmNewPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={isChanging}
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            onClick={changePassword}
            style={{ color: color.btn_dark }}
          >
            Promeni
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ChangePw;
