import api from "../../api";

export const createSubcategory = async ({
  subcategory_name,
  category_key,
}: {
  subcategory_name: string;
  category_key?: string;
}): Promise<{ id: number }> => {
  const response = await api.post(`/admin/sub-category`, {
    subcategory_name,
    category_key,
  });
  return response.data;
};

export const editSubcategory = async ({
  subcategory_name,
  subcategory_id,
}: {
  subcategory_name: string;
  subcategory_id: number;
}): Promise<{ id: number; msg: string }> => {
  const response = await api.patch(`/admin/sub-category/${subcategory_id}`, {
    subcategory_name,
  });
  return response.data;
};

export const deleteSubcategory = async (
  subcategory_id: number
): Promise<{ id: number; msg: string }> => {
  const response = await api.delete(`admin/sub-category/${subcategory_id}`);
  return response.data;
};
