import { useCategory } from "../../../Hooks/useCategories";
import { deleteCategory } from "../../../Services/Https/categories";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TitleValue from "../../TitleValue";
import color from "../../../Constants/colors";

const Category = () => {
  const { id } = useParams();
  const { data } = useCategory(id ?? "");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const renderDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Pažnja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description:">
            Da li ste sigurni da želite da obrišete kategoriju
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: color.btn_dark, color: color.white }}
            onClick={() => handleDelete()}
            autoFocus
            disabled={isDeleting}
          >
            Potvrdi
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: color.primary, color: color.btn_dark }}
            onClick={handleClose}
            disabled={isDeleting}
          >
            Odustani
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (!data) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (!id) {
    return <h1> Invalid application state </h1>;
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteCategory(id);
      enqueueSnackbar("Kategorija je uspesno obrisana", { variant: "success" });
      navigate("/admin/categories");
    } catch (error) {
      enqueueSnackbar(
        "Greška, ne možete obrisati kategoriju koja pripada proizvodima",
        { variant: "error" }
      );
    }
    setIsDeleting(false);
  };
  return (
    <>
      <div style={{ margin: "auto", maxWidth: "60em" }}>
        {renderDialog()}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={3}
        >
          <Grid item>
            <h1>{`Pregled Kategorije #${data.category.id}`}</h1>
          </Grid>
          <Grid item>
            <Tooltip title="Obriši kategoriju">
              <IconButton
                onClick={() => {
                  handleOpen();
                }}
              >
                <DeleteIcon sx={{ color: "#79590E" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Izmeni kategoriju">
              <IconButton
                onClick={() => {
                  navigate(`/admin/categories/${id}/edit`);
                }}
              >
                <EditIcon sx={{ color: "#79590E" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container>
          <TitleValue
            title="Ime Kategorije"
            value={data.category.category_name}
          />
          <h2>Potkategorije</h2>
          <Grid
            container
            direction="column"
            alignItems="flex-end"
            justifyContent="space-around"
          >
            {data.category.subcategories.map((subcategory, index) => {
              return (
                <Grid
                  item
                  key={subcategory.subcategory_id}
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "0.8rem",
                    margin: "0.5rem 0",
                    borderRadius: "5px",
                    minWidth: "400px",
                  }}
                >
                  {subcategory.subcategory_id}. {subcategory.subcategory_name}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Category;
