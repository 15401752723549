import { IAboutImages } from "../../Model/AboutImages";
import api from "../../api";

export const getAllAboutImages = async (): Promise<IAboutImages> => {
  const response = await api.get(`about-image`);
  return response.data;
};

export const deleteSingleAboutImage = async (
  id: string
): Promise<{ msg: string }> => {
  const response = await api.delete(`/admin/about-image/${id}`);
  return response.data;
};

export const addNewAboutImages = async (
  formData: FormData
): Promise<{ msg: string }> => {
  const response = await api.post(`/admin/about-image`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};
