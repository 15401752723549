import { Grid, Typography } from "@mui/material";
import colors from "../../../Constants/colors";

const Informations = () => {
  return (
    <Grid
      container
      justifyContent="space-around"
      direction="row"
      spacing={2}
      width="101vw"
      alignItems="center"
      sx={{
        backgroundColor: colors.primary,
        padding: "20px",
        marginTop: "20px",
        borderTop: "2px solid #79590E",
        textAlign: "center",
      }}
    >
      <Grid item>
        <Typography>Pun naziv pravnog subjekta: DAROTEKA DORO</Typography>
      </Grid>
      <Grid item>
        <Typography>Adresa: Laze Telečkog 1, Novi Sad</Typography>
      </Grid>
      <Grid item>
        <Typography>
          Delatnost i šifra delatnosti: 3212 - Proizvodnja nakita i srodnih
          predmeta;
        </Typography>
      </Grid>
      <Grid item>
        <Typography>Matični broj: 52404860</Typography>
      </Grid>
      <Grid item>
        <Typography>Poreski broj: 101705556</Typography>
      </Grid>
      <Grid item>
        <Typography>Kontakt telefon: 0605500750</Typography>
      </Grid>
      <Grid item>
        <Typography>Kontakt e-mail: silvershopdoro@gmail.com</Typography>
      </Grid>
    </Grid>
  );
};

export default Informations;
