import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCategories } from "../../../Hooks/useCategories";
import { useNavigate } from "react-router-dom";
import { useSearchContext } from "../../SearchContext/SearchContext";
import { useAllProducts } from "../../../Hooks/useProducts";
import { material } from "../../../Model/Product";
import { ISubcategory } from "../../../Model/Category";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SortIcon from "@mui/icons-material/Sort";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../../../Styles/ClientAllProducts.css";
import {
  Grid,
  CircularProgress,
  MenuItem,
  Menu,
  Checkbox,
  Button,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import ClientSingleProduct from "./ClientSingleProduct";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import color from "../../../Constants/colors";
import useDebounce from "../../../Hooks/Misc/useDebounce";
import useScreenSize from "../../../Hooks/Misc/useScreenSize";
type Order = "asc" | "desc" | "";
type OrderBy = "created_at" | "product_name" | "price" | "popular" | "";

const ClientAllProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { search, setSearch } = useSearchContext();
  const debouncedSearch = useDebounce(search, 500);
  const [title, setTitle] = useState("Svi proizvodi");
  const [subtitle, setSubtitle] = useState(
    "Unesite čaroliju u Vaš život sa našim nakitom"
  );
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<OrderBy>("popular");
  const [briliant, setBriliant] = useState<boolean | null>(null);
  const [material, setMaterial] = useState<material>(null);
  const [discountFlag, setDiscountFlag] = useState<boolean | null>(null);
  const [page, setPage] = useState(() => {
    const storedPage = sessionStorage.getItem("page");
    return storedPage ? parseInt(storedPage, 10) : 0;
  });
  const [subcategoryKeysArray, setSubcategoryKeysArray] = useState<number[]>(
    []
  );

  const [subcategories, setSubcategories] = useState<ISubcategory[]>([]);
  const [categoryKeysArray, setCategoryKeysArray] = useState<number[]>([]);

  // eslint-disable-next-line
  const [_scrollPosition, setScrollPosition] = useState(() => {
    const storedPosition = sessionStorage.getItem("scrollPosition");
    return storedPosition ? parseInt(storedPosition, 10) : 0;
  });

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    sessionStorage.setItem("scrollPosition", position.toString());
  };

  const phone = useScreenSize("340");

  useEffect(() => {
    // Add event listener to track scroll position
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (state) {
      if (state.category_id) {
        let categoryTemp = state.category_id;
        setCategoryKeysArray(categoryTemp);
      } else {
        setCategoryKeysArray([]);
      }

      if (state.subcategory_id) {
        let subcategoryTemp = state.subcategory_id;
        setSubcategoryKeysArray(subcategoryTemp);
      } else {
        setSubcategoryKeysArray([]);
      }

      if (state["material"]) {
        setMaterial(state["material"]);
      } else {
        setMaterial(null);
      }

      if (state.briliant !== null) {
        if (state.briliant === true) {
          setBriliant(true);
        } else {
          setBriliant(false);
        }
      } else {
        setBriliant(null);
      }

      setOrder((state.order as Order) ?? "desc");
      setOrderBy((state.orderBy as OrderBy) ?? "popular");
    } else {
      // Test
    }
  }, [state]);

  //If searching reset the page to 0
  useEffect(() => {
    const resetPageCount = () => {
      setPage(0);
    };
    window.addEventListener("pageReset", resetPageCount);
  }, []);

  // Getting categories and product data
  const { data: categories } = useCategories();
  const { data } = useAllProducts(
    null,
    null,
    null,
    order,
    orderBy,
    page + 1,
    20,
    discountFlag,
    briliant,
    debouncedSearch,
    material,
    null,
    categoryKeysArray.join(","),
    subcategoryKeysArray.join(",")
  );

  // Welcome message customization on every change in state
  useEffect(() => {
    if (briliant === true) {
      setTitle("Nakit sa brilijantima i dragim kamenjem");
      setSubtitle("Unesite čaroliju u Vaš život sa našim nakitom");
    } else if (material === "gold") {
      setTitle("Nakit od zlata");

      setSubtitle(
        "Dodajte sjaj Vašem izgledu elegantinim, jednostavnim ručno rađenim nakitom od zlata."
      );
    } else if (material === "silver") {
      setTitle("Nakit od srebra");

      setSubtitle(
        "Dodajte sjaj Vašem izgledu elegantinim, jednostavnim ručno rađenim nakitom od srebra."
      );
    } else {
      setTitle("Svi proizvodi");
      setSubtitle("Unesite čaroliju u Vaš život sa našim nakitom");
    }
  }, [material, state, briliant]);

  //Category dropdown
  const [anchorElCategory, setAnchorElCategory] = useState<null | HTMLElement>(
    null
  );
  const openCategory = Boolean(anchorElCategory);
  const handleClickCategory = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElCategory(event.currentTarget);
  };
  const handleCloseCategory = () => {
    setAnchorElCategory(null);
  };
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (isNaN(id)) {
      // value is not a valid number, handle the error as needed
      return;
    }
    let categoryKeysTemp: number[];
    const isChecked = categoryKeysArray.includes(id);
    categoryKeysTemp = isChecked
      ? categoryKeysArray.filter((categoryId) => categoryId !== id)
      : [...categoryKeysArray, id];
    setCategoryKeysArray(categoryKeysTemp);
    setPage(0);
    setSearch("");
    navigate("/products", {
      state: {
        material: material,
        briliant: briliant,
        category_id: categoryKeysTemp,
        subcategory_id: subcategoryKeysArray,
        order: order,
        orderBy: orderBy,
      },
    });
  };

  //Subcategory dropdown
  const [anchorElSubcategory, setanchorElSubcategory] =
    useState<null | HTMLElement>(null);
  const openSubcategory = Boolean(anchorElSubcategory);
  const handleClickSubcategory = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setanchorElSubcategory(event.currentTarget);
  };
  const handleCloseSubategory = () => {
    setanchorElSubcategory(null);
  };

  useEffect(() => {
    if (categories) {
      const filteredCategories = categories.categories.filter((category) =>
        categoryKeysArray.includes(category.id)
      );
      const subcategories = filteredCategories.flatMap(
        (category) => category.subcategories
      );

      setSubcategories(subcategories);
    }
  }, [categoryKeysArray, categories]);

  //Subcategory checkbox value change
  const handleSubcategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (isNaN(id)) {
      // value is not a valid number, handle the error as needed
      return;
    }
    let subcategoriesTemp: number[];
    const isChecked = subcategoryKeysArray.includes(id);
    subcategoriesTemp = isChecked
      ? subcategoryKeysArray.filter((subcategoryId) => subcategoryId !== id)
      : [...subcategoryKeysArray, id];
    setPage(0);
    setSearch("");
    navigate("/products", {
      state: {
        material: material,
        briliant: briliant,
        category_id: categoryKeysArray,
        subcategory_id: subcategoriesTemp,
        order: order,
        orderBy: orderBy,
      },
    });
  };

  //Briliant dropdown
  const [anchorElBriliant, setanchorElBriliant] = useState<null | HTMLElement>(
    null
  );
  const openBriliant = Boolean(anchorElBriliant);
  const handleClickBriliant = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElBriliant(event.currentTarget);
  };
  const handleCloseBriliant = () => {
    setanchorElBriliant(null);
  };

  //Briliant checkbox value change
  const handleBriliantChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    let briliantTemp: boolean | null = null;
    switch (id) {
      case 1:
        if (briliant === true) {
          briliantTemp = null;
        } else {
          briliantTemp = true;
        }
        break;
      case 2:
        if (briliant === false) {
          briliantTemp = null;
        } else {
          briliantTemp = false;
        }
        break;
      case 3:
        briliantTemp = null;
        break;
      default:
        break;
    }
    setPage(0);
    setBriliant(briliantTemp);
    setSearch("");

    navigate("/products", {
      state: {
        material: material,
        briliant: briliantTemp,
        category_id: categoryKeysArray,
        subcategory_id: subcategoryKeysArray,
        order: order,
        orderBy: orderBy,
      },
    });
  };

  //Sort dropdown
  const [anchorElSort, setanchorElSort] = useState<null | HTMLElement>(null);
  const openSort = Boolean(anchorElSort);
  const handleClickSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElSort(event.currentTarget);
  };
  const handleCloseSort = () => {
    setanchorElSort(null);
  };

  const handleSortChange = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    id: number
  ) => {
    let updatedOrderBy = "popular";
    let updatedOrder = "desc";

    switch (id) {
      case 1:
        updatedOrderBy = "created_at";
        updatedOrder = "asc";
        break;
      case 2:
        updatedOrderBy = "created_at";
        updatedOrder = "desc";
        break;
      case 3:
        updatedOrderBy = "price";
        updatedOrder = "asc";
        break;
      case 4:
        updatedOrderBy = "price";
        updatedOrder = "desc";
        break;
      case 5:
        updatedOrderBy = "popular";
        updatedOrder = "desc";
        break;
      default:
        break;
    }

    // Set the updatedOrderBy and updatedOrder values in state
    setOrderBy(updatedOrderBy as OrderBy);
    setOrder(updatedOrder as Order);

    navigate("/products", {
      state: {
        material: material,
        briliant: briliant,
        category_id: categoryKeysArray,
        subcategory_id: subcategoryKeysArray,
        order: updatedOrder,
        orderBy: updatedOrderBy,
      },
    });
  };

  //Material checkbox value change
  const [anchorElMaterials, setanchorElMaterials] =
    useState<null | HTMLElement>(null);
  const openMaterials = Boolean(anchorElMaterials);
  const handleClickMaterials = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElMaterials(event.currentTarget);
  };
  const handleCloseMaterials = () => {
    setanchorElMaterials(null);
  };
  const handleMaterialChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    let updatedMaterial: material = null;

    switch (id) {
      case 1:
        updatedMaterial = "gold";

        break;
      case 2:
        updatedMaterial = "silver";

        break;
      case 3:
        updatedMaterial = null;
        break;
      default:
        break;
    }

    // Set the updatedMaterial value in state
    setMaterial(updatedMaterial as material);
    setPage(0);
    setSearch("");

    // Set the updatedMaterial value in local storage
    navigate("/products", {
      state: {
        material: updatedMaterial,
        briliant: briliant,
        category_id: categoryKeysArray,
        subcategory_id: subcategoryKeysArray,
        order: order,
        orderBy: orderBy,
      },
    });
  };

  //Remove filters
  const removeFilters = () => {
    setOrder("desc");
    setOrderBy("popular");
    setDiscountFlag(null);
    setBriliant(null);
    setCategoryKeysArray([]);
    setSubcategoryKeysArray([]);
    setSubcategories([]);
    setSearch("");
    setMaterial(null);
    sessionStorage.setItem("page", (0).toString());
    setPage(0);

    // Clear values from state
    navigate("/products", {
      state: {
        material: null,
        briliant: null,
        category_id: null,
        subcategory_id: null,
        order: "desc",
        orderBy: "popular",
      },
    });
  };

  if (!data || !categories) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress sx={{ color: color.accent }} />
      </Grid>
    );
  }
  const category_filters = categories.categories;

  const handleProductClick = (id: number) => {
    navigate(`/products/${id}`);
  };

  const handleArrowUp = () => {
    if (page + 1 !== data.total_pages) {
      sessionStorage.setItem("page", (page + 1).toString());
      setPage((prevPage) => prevPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleArrowDown = () => {
    if (page + 1 !== 1) {
      sessionStorage.setItem("page", (page - 1).toString());
      setPage((prevPage) => prevPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        className="page-container"
      >
        <Grid item alignSelf="self-start" className="path-container">
          <Typography className="current-path">
            Početna → Svi proizvodi
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="title-products">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography className="subtitle-products">{subtitle}</Typography>
        </Grid>
        <Grid item className="products-container">
          <Grid container direction="column" alignItems="center">
            <Grid item className="filter-bar">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent={phone ? "center" : "space-between"}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item>
                      <Button
                        id="basic-button"
                        aria-controls={openCategory ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openCategory ? "true" : undefined}
                        onClick={handleClickCategory}
                        className="filter-button"
                        style={{ textTransform: "none" }}
                      >
                        <Typography className="filter-button-text">
                          Kategorija
                        </Typography>
                        <KeyboardArrowDownIcon className="arrow-down" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElCategory}
                        open={openCategory}
                        onClose={handleCloseCategory}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        classes={{ paper: "categories-menu-paper" }}
                      >
                        {category_filters.length ? (
                          category_filters.map((category, index) => (
                            <label
                              htmlFor={`category-checkbox-${index}`}
                              key={index}
                            >
                              <MenuItem key={category.id} value={category.id}>
                                <Checkbox
                                  className="menu-item-checkbox"
                                  id={`category-checkbox-${index}`}
                                  checked={categoryKeysArray.includes(
                                    category.id
                                  )}
                                  onChange={(event) => {
                                    event.persist();
                                    handleCategoryChange(event, category.id);
                                  }}
                                  style={{ color: color.accent }}
                                />
                                <Typography
                                  className="menu-item-text"
                                  style={{
                                    color: categoryKeysArray.includes(
                                      category.id
                                    )
                                      ? color.accent
                                      : color.btn_dark,
                                  }}
                                >
                                  {category.category_name}
                                </Typography>
                              </MenuItem>
                            </label>
                          ))
                        ) : (
                          <MenuItem style={{ color: color.btn_dark }}>
                            Nema kategorija za prikaz
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                    <Grid item>
                      <Button
                        id="basic-button"
                        aria-controls={
                          openSubcategory ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openSubcategory ? "true" : undefined}
                        onClick={handleClickSubcategory}
                        className="filter-button"
                        style={{ textTransform: "none" }}
                      >
                        <Typography className="filter-button-text">
                          Potkategorija
                        </Typography>
                        <KeyboardArrowDownIcon className="arrow-down" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElSubcategory}
                        open={openSubcategory}
                        onClose={handleCloseSubategory}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                          sx: {
                            maxHeight: "200px",
                            overflow: "auto",
                            "&::-webkit-scrollbar": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-track": {
                              boxShadow: "inset 0 0 3px rgba(0,0,0,0.3)",
                              borderRadius: "8px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#79590e",
                              borderRadius: "8px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              backgroundColor: "#cf9b2e",
                            },
                          },
                        }}
                      >
                        {subcategories.length ? (
                          subcategories.map((subcategory, index) => (
                            <label
                              htmlFor={`subcategory-checkbox-${index}`}
                              key={index}
                            >
                              <MenuItem
                                key={subcategory.subcategory_id ?? -1}
                                value={subcategory.subcategory_id ?? -1}
                              >
                                <Checkbox
                                  className="menu-item-checkbox"
                                  id={`subcategory-checkbox-${index}`}
                                  checked={subcategoryKeysArray.includes(
                                    subcategory.subcategory_id ?? -1
                                  )}
                                  onChange={(event) => {
                                    event.persist();
                                    handleSubcategoryChange(
                                      event,
                                      subcategory.subcategory_id ?? -1
                                    );
                                  }}
                                  style={{ color: color.accent }}
                                />
                                <Typography
                                  className="menu-item-text"
                                  style={{
                                    color: subcategoryKeysArray.includes(
                                      subcategory.subcategory_id ?? -1
                                    )
                                      ? color.accent
                                      : color.btn_dark,
                                  }}
                                >
                                  {subcategory.subcategory_name}
                                </Typography>
                              </MenuItem>
                            </label>
                          ))
                        ) : (
                          <MenuItem style={{ color: color.btn_dark }}>
                            <Typography className="menu-item-text">
                              Nema potkategorija za prikaz
                            </Typography>
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                    <Grid item>
                      <Button
                        id="basic-button"
                        aria-controls={openMaterials ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMaterials ? "true" : undefined}
                        onClick={handleClickMaterials}
                        className="filter-button"
                        style={{ textTransform: "none" }}
                      >
                        <Typography className="filter-button-text">
                          Materijal
                        </Typography>
                        <KeyboardArrowDownIcon className="arrow-down" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElMaterials}
                        open={openMaterials}
                        onClose={handleCloseMaterials}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <label htmlFor="gold-checkbox">
                          <MenuItem>
                            <Checkbox
                              className="menu-item-checkbox"
                              id="gold-checkbox"
                              checked={material === "gold"}
                              onChange={(event) => {
                                event.persist();
                                handleMaterialChange(event, 1);
                              }}
                              style={{ color: color.accent }}
                            />
                            <Typography
                              className="menu-item-text"
                              style={{
                                color:
                                  material === "gold"
                                    ? color.accent
                                    : color.btn_dark,
                              }}
                            >
                              Zlato
                            </Typography>
                          </MenuItem>
                        </label>
                        <label htmlFor="silver-checkbox">
                          <MenuItem>
                            <Checkbox
                              className="menu-item-checkbox"
                              id="silver-checkbox"
                              checked={material === "silver"}
                              onChange={(event) => {
                                event.persist();
                                handleMaterialChange(event, 2);
                              }}
                              style={{ color: color.accent }}
                            />
                            <Typography
                              className="menu-item-text"
                              style={{
                                color:
                                  material === "silver"
                                    ? color.accent
                                    : color.btn_dark,
                              }}
                            >
                              Srebro
                            </Typography>
                          </MenuItem>
                        </label>
                        <label htmlFor="all-material-checkbox">
                          <MenuItem>
                            <Checkbox
                              className="menu-item-checkbox"
                              id="all-material-checkbox"
                              checked={!material}
                              onChange={(event) => {
                                event.persist();
                                handleMaterialChange(event, 3);
                              }}
                              style={{ color: color.accent }}
                            />
                            <Typography
                              className="menu-item-text"
                              style={{
                                color: !material
                                  ? color.accent
                                  : color.btn_dark,
                              }}
                            >
                              Sve
                            </Typography>
                          </MenuItem>
                        </label>
                      </Menu>
                    </Grid>
                    <Grid item>
                      <Button
                        id="basic-button"
                        aria-controls={openBriliant ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openBriliant ? "true" : undefined}
                        onClick={handleClickBriliant}
                        className="filter-button"
                        style={{ textTransform: "none" }}
                      >
                        <Typography className="filter-button-text">
                          Brilijant
                        </Typography>
                        <KeyboardArrowDownIcon className="arrow-down" />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElBriliant}
                        open={openBriliant}
                        onClose={handleCloseBriliant}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <label htmlFor="briliant-checkbox">
                          <MenuItem>
                            <Checkbox
                              className="menu-item-checkbox"
                              id="briliant-checkbox"
                              checked={briliant === true}
                              onChange={(event) => {
                                event.persist();
                                handleBriliantChange(event, 1);
                              }}
                              style={{ color: color.accent }}
                            />
                            <Typography
                              className="menu-item-text"
                              style={{
                                color:
                                  briliant === true
                                    ? color.accent
                                    : color.btn_dark,
                              }}
                            >
                              Sa Brilijantom
                            </Typography>
                          </MenuItem>
                        </label>
                        <label htmlFor="without-briliant-checkbox">
                          <MenuItem>
                            <Checkbox
                              className="menu-item-checkbox"
                              id="without-briliant-checkbox"
                              checked={briliant === false}
                              onChange={(event) => {
                                event.persist();
                                handleBriliantChange(event, 2);
                              }}
                              style={{ color: color.accent }}
                            />
                            <Typography
                              className="menu-item-text"
                              style={{
                                color:
                                  briliant === false
                                    ? color.accent
                                    : color.btn_dark,
                              }}
                            >
                              Bez Brilijanta
                            </Typography>
                          </MenuItem>
                        </label>
                        <label htmlFor="all-briliant-checkbox">
                          <MenuItem>
                            <Checkbox
                              className="menu-item-checkbox"
                              id="all-briliant-checkbox"
                              checked={briliant === null}
                              onChange={(event) => {
                                event.persist();
                                handleBriliantChange(event, 3);
                              }}
                              style={{ color: color.accent }}
                            />
                            <Typography
                              className="menu-item-text"
                              style={{
                                color:
                                  briliant === null
                                    ? color.accent
                                    : color.btn_dark,
                              }}
                            >
                              Svi Proizvodi
                            </Typography>
                          </MenuItem>
                        </label>
                      </Menu>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Očisti filtere">
                        <IconButton
                          className="filter-button"
                          disableRipple
                          onClick={removeFilters}
                        >
                          <LayersClearIcon className="clear-icon" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    id="basic-button"
                    aria-controls={openSort ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSort ? "true" : undefined}
                    onClick={handleClickSort}
                    className="filter-button"
                    style={{ textTransform: "none" }}
                  >
                    {phone ? (
                      <SortIcon className="clear-icon" />
                    ) : (
                      <>
                        <Typography className="filter-button-text">
                          Sortiraj
                        </Typography>
                        <KeyboardArrowDownIcon className="arrow-down" />
                      </>
                    )}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElSort}
                    open={openSort}
                    onClose={handleCloseSort}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => {
                        event.persist();
                        handleSortChange(event, 1);
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            order === "asc" && orderBy === "created_at"
                              ? color.accent
                              : color.btn_dark,
                        }}
                      >
                        Po datumu uzlazno
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.persist();
                        handleSortChange(event, 2);
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            order === "desc" && orderBy === "created_at"
                              ? color.accent
                              : color.btn_dark,
                        }}
                      >
                        Po datumu silazno
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.persist();
                        handleSortChange(event, 3);
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            order === "asc" && orderBy === "price"
                              ? color.accent
                              : color.btn_dark,
                        }}
                      >
                        Po ceni uzlazno
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.persist();
                        handleSortChange(event, 4);
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            order === "desc" && orderBy === "price"
                              ? color.accent
                              : color.btn_dark,
                        }}
                      >
                        Po ceni silazno
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.persist();
                        handleSortChange(event, 5);
                      }}
                    >
                      <Typography
                        style={{
                          color:
                            order === "desc" && orderBy === "popular"
                              ? color.accent
                              : color.btn_dark,
                        }}
                      >
                        Prvo popularno
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="all-products-container">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {data ? (
              data.products.map((product) => (
                <ClientSingleProduct
                  key={product.id}
                  handleClick={() => handleProductClick(product.id)}
                  imageUrl={product.main_image_name}
                  previewUrl={product.preview_image_name}
                  name={product.product_name}
                  material={product.material}
                  price={product.price}
                  discount={product.discount_flag}
                  discountPrice={product.discount_price}
                />
              ))
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <CircularProgress sx={{ color: color.accent }} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item className="page-number-container">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <IconButton onClick={handleArrowDown}>
                <ArrowBackIosIcon
                  className="page-arrow"
                  style={{
                    opacity: page === 0 ? "0.3" : "1",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography className="page-number">{page + 1}</Typography>
            </Grid>

            {data.total_pages - (page + 1) === 0 ||
            data.total_pages === 1 ||
            data.total_pages === 0 ? null : (
              <>
                <Grid item>
                  <Typography className="page-number">...</Typography>
                </Grid>
                <Grid item>
                  <Typography className="page-number">
                    {data.total_pages}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item>
              <IconButton onClick={handleArrowUp}>
                <ArrowForwardIosIcon
                  className="page-arrow"
                  style={{
                    opacity:
                      data.total_pages === page + 1 || data.total_pages === 0
                        ? "0.3"
                        : "1",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientAllProducts;
